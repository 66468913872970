import { Button, FilePicker, GridContainer, GridItem, Icon, Typography, styled } from '@cofenster/web-components';
import { type FC, useCallback } from 'react';

import { useCaptureAssetCandidateFileContext } from '../../../../../context/captureAssetFile';
import { useI18n } from '../../../../../i18n';
import { BottomFixedContainer } from '../../../../controls';
import { WithGapContainer } from '../../../Container';

const ExplanationContainer = styled(GridContainer)(({ theme }) => ({
  minHeight: '40vh',
  borderRadius: theme.shape['borderRadius-l'],
  backgroundColor: theme.palette.brand.linen50,
  padding: theme.spacing(2),
}));

const getMobileOs = () => {
  const userAgent = navigator.userAgent || navigator.vendor;
  if (/android/i.test(userAgent)) {
    return 'android';
  }
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return 'ios';
  }
  return 'other';
};

export const CoCaptureScreenRecordingMobile: FC = () => {
  const { locale } = useI18n();
  const { onCaptureAssetReadyForReview } = useCaptureAssetCandidateFileContext();
  const os = getMobileOs();

  const onHowToRecordClicked = useCallback(() => {
    if (os === 'other') {
      return console.info(`No URL found for locale ‘${locale}’ and OS ‘${os}’.`);
    }

    const urls: Record<'EN' | 'DE', Record<'android' | 'ios', string>> = {
      EN: {
        android: 'https://support.google.com/android/answer/9075928?hl=en',
        ios: 'https://support.apple.com/guide/iphone/take-a-screenshot-or-screen-recording-iphc872c0115/ios',
      },
      DE: {
        android: 'https://support.google.com/android/answer/9075928?hl=de',
        ios: 'https://support.apple.com/de-de/guide/iphone/iphc872c0115/ios',
      },
    };

    window.open(urls[locale][os], '_blank');
  }, [locale, os]);

  const onFiles = (files: File[]) => {
    const file = files[0];
    if (!file) return;
    onCaptureAssetReadyForReview(
      {
        blob: file,
        url: URL.createObjectURL(file),
      },
      { uploadSource: 'mobile-library' }
    );
  };

  return (
    <WithGapContainer>
      <ExplanationContainer
        direction="column"
        alignItems="center"
        textAlign="center"
        justifyContent="center"
        spacing={0.5}
      >
        <GridItem marginBottom={2}>
          <Icon type="MonitorIcon" size="l" />
        </GridItem>
        <GridItem>
          <Typography variant="h4">i18n.ScenePage.InitialScreen.sharingNotSupported.title</Typography>
        </GridItem>
        <GridItem>
          <Typography variant="m">i18n.ScenePage.InitialScreen.sharingNotSupported.description</Typography>
        </GridItem>
      </ExplanationContainer>
      <BottomFixedContainer>
        <GridContainer spacing={1}>
          <GridItem xs={6}>
            <Button
              variant="secondary"
              onClick={onHowToRecordClicked}
              endIcon={<Icon size="s" type="ArrowSquareOutIcon" />}
              fullWidth
            >
              i18n.ScenePage.InitialScreen.sharingNotSupported.howToRecordButton
            </Button>
          </GridItem>
          <GridItem xs={6}>
            <FilePicker
              id="scene-video-upload-mobile"
              onFiles={onFiles}
              video
              fullWidth
              startIcon={<Icon size="s" type="UploadIcon" />}
            >
              i18n.ScenePage.InitialScreen.sharingNotSupported.uploadButton
            </FilePicker>
          </GridItem>
        </GridContainer>
      </BottomFixedContainer>
    </WithGapContainer>
  );
};
