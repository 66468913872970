import { paletteThemeOptions } from '../palette';

import './types';

export const mixinsThemeOptions = {
  focusRing: {
    outline: `2px solid ${paletteThemeOptions.brand.blue}`,
    outlineOffset: 2,
  },
};
