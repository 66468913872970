import { Popover as MuiPopover, type PopoverProps as MuiPopoverProps, type PopoverOrigin, styled } from '@mui/material';
import { type FC, useMemo } from 'react';

const StyledPopover = styled(MuiPopover)(({ theme }) => ({
  '.MuiPaper-root': {
    minWidth: theme.spacing(24),
    borderRadius: theme.shape['borderRadius-l'],
    boxShadow: '0px 0px 30px rgb(0 0 0 / 0.1)',
  },
}));

export type PopoverProps = Pick<
  MuiPopoverProps,
  'id' | 'open' | 'onClose' | 'anchorEl' | 'children' | 'disableScrollLock'
> & {
  anchorOriginX?: PopoverOrigin['horizontal'];
  anchorOriginY?: PopoverOrigin['vertical'];
  transformOriginX?: PopoverOrigin['horizontal'];
  transformOriginY?: PopoverOrigin['vertical'];
  offsetX?: number;
  offsetY?: number;
};

const offsetAnchorProxy = (element: Element, offsetX = 0, offsetY = 0) =>
  new Proxy(element, {
    get(target, prop: keyof Element) {
      if (prop === 'getBoundingClientRect') {
        return () => {
          const rect = target.getBoundingClientRect();
          return {
            x: rect.x + offsetX,
            y: rect.y + offsetY,
            width: rect.width,
            height: rect.height,
            top: rect.top + offsetY,
            left: rect.left + offsetX,
            bottom: rect.bottom + offsetY,
            right: rect.right + offsetX,
          };
        };
      }
      return target[prop];
    },
  });

export const Popover: FC<PopoverProps> = ({
  anchorOriginX = 'right',
  anchorOriginY = 'top',
  transformOriginX = 'left',
  transformOriginY = 'top',
  offsetX = 0,
  offsetY = 0,
  anchorEl,
  ...rest
}) => {
  const anchorOrigin = useMemo(
    () => ({ horizontal: anchorOriginX, vertical: anchorOriginY }),
    [anchorOriginX, anchorOriginY]
  );
  const transformOrigin = useMemo(
    () => ({ horizontal: transformOriginX, vertical: transformOriginY }),
    [transformOriginX, transformOriginY]
  );

  const getAnchorElement = useMemo(() => {
    if (anchorEl) {
      if (offsetX === 0 && offsetY === 0) {
        return anchorEl;
      }
      if (typeof anchorEl === 'function') {
        return () => {
          return offsetAnchorProxy(anchorEl() as Element, offsetX, offsetY);
        };
      }
      return () => {
        return offsetAnchorProxy(anchorEl as Element, offsetX, offsetY);
      };
    }
    return undefined;
  }, [anchorEl, offsetX, offsetY]);

  return (
    <StyledPopover
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      elevation={1}
      anchorEl={getAnchorElement}
      {...rest}
    />
  );
};
