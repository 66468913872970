import { styled } from '@mui/material';

import { BaseButton } from './BaseButton';

export const BlurredButton = styled(BaseButton)(({ theme }) => ({
  backgroundColor: 'rgb(10 36 44 / 0.3)',
  backdropFilter: 'blur(13px)',
  color: theme.palette.brand.white,

  '&:hover, &:focus': {
    backgroundColor: theme.palette.brand.carbon,
  },

  '&.Mui-disabled': {
    cursor: 'not-allowed',
    backgroundColor: 'rgb(10 36 44 / 0.3)',
    color: theme.palette.brand.grey700,
  },
}));
