import { styled } from '@mui/material';
import type { ComponentProps, FC } from 'react';

import { preventForwardProps } from '../../../utilities/preventForwardProps';

const Container = styled(
  'div',
  preventForwardProps(['color', 'size'])
)<{
  color: NonNullable<ProfileCircleProps['color']>;
  size: NonNullable<ProfileCircleProps['size']>;
}>(({ theme, color, size }) => {
  const sizePx = size === 'large' ? 32 : 24;
  return {
    ...theme.typography[size === 'large' ? 'h5' : 'h6'],
    display: 'inline-flex',
    flex: `0 0 ${sizePx}px`,
    width: sizePx,
    height: sizePx,
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.brand[color === 'light' ? 'carbon' : 'white'],
    backgroundColor: theme.palette.brand[color === 'light' ? 'white' : 'carbon'],
    borderRadius: sizePx / 2,
    textTransform: 'uppercase',
    userSelect: 'none',
  };
});

export type ProfileCircleProps = {
  children: string | string[];
  color: 'dark' | 'light';
  size: 'small' | 'large';
} & ComponentProps<'div'>;

export const ProfileCircle: FC<ProfileCircleProps> = ({ children, ...rest }) => {
  return (
    <Container {...rest} aria-hidden="true">
      {children[0] ?? ''}
    </Container>
  );
};
