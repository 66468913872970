import { styled } from '@mui/material';
import type { FC } from 'react';

import { Alert, Typography } from '@cofenster/web-components';
import {
  BACKGROUND_REPLACE_IMAGES_HOMEOFFICE,
  BACKGROUND_REPLACE_IMAGES_OFFICE,
  type BackgroundEffect,
  getBackgroundReplaceImageThumbnailUrl,
} from '../../../../../hooks/media/backgroundEffect';
import { useI18n } from '../../../../../services/i18n';
import { Icon } from '../../../../assets/icons/Icon';
import { SvgIcon } from '../../../../assets/icons/SvgIcon';
import { BlankButton } from '../../../../controls/Button/BlankButton';
import { IconButton } from '../../../../controls/Button/IconButton';
import { withPopover } from '../../../../feedback/Popover';
import { Tooltip } from '../../../../feedback/Tooltip';
import { Card } from '../../../../layout/Card';
import { Headline } from '../../../../typography/Headline';
import { VisuallyHidden } from '../../../../utilities/VisuallyHidden';

type EffectsButtonProps = {
  selectedEffect?: BackgroundEffect;
  onEffectSelect?: (effect: BackgroundEffect) => unknown;
  isInitializing?: boolean;
};

const EffectButton = styled(BlankButton)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  width: 80,
  height: 80,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',

  backgroundColor: theme.palette.brand.grey50,

  '&:focus-visible': theme.mixins.focusRing,

  '&:hover, &[aria-selected=true]': {
    outlineStyle: 'solid',
    outlineWidth: 2,
    outlineOffset: -2,
    outlineColor: theme.palette.brand.blue,
  },
}));

const Column = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

const Row = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

const StyledAlert = styled(Alert)(({ theme }) => ({
  // 4 thumbnails + 3 gaps
  width: 344,
  backgroundColor: theme.palette.brand.blue_alpha20,
  color: theme.palette.brand.blue,
  marginBottom: theme.spacing(1),
  // Alert is MuiPaper-root and when it's within popover it has a box shadow
  boxShadow: 'none !important',
  svg: {
    fill: theme.palette.brand.blue,
    alignSelf: 'center',
  },
}));

export const BackgroundEffectButton: FC<EffectsButtonProps> = ({ selectedEffect, onEffectSelect, isInitializing }) => {
  const { translate } = useI18n();

  if (!onEffectSelect) return null;

  const isAnyEffectSelected = selectedEffect !== 'NONE';

  const EffectsPopoverIconButton = withPopover(IconButton, {
    anchorOriginX: 'center',
    anchorOriginY: 'top',
    transformOriginX: 'center',
    transformOriginY: 'bottom',
    children: (
      <Card>
        <Column>
          <Headline variant="h4" component="h2">
            i18n.global.backgroundEffect.title
          </Headline>
          <Column>
            <StyledAlert icon={<Icon type="FlaskIcon" />}>
              <Headline variant="h6" component="h3">
                i18n.global.backgroundEffect.beta.title
              </Headline>
              <Typography component="p">i18n.global.backgroundEffect.beta.description</Typography>
            </StyledAlert>
            <Row>
              <Tooltip title="i18n.global.backgroundEffect.none">
                <EffectButton onClick={() => onEffectSelect('NONE')} aria-selected={'NONE' === selectedEffect}>
                  <Icon type="NoOptionIcon" background={{ color: 'transparent', size: 'l' }} />
                  <VisuallyHidden>i18n.global.backgroundEffect.none</VisuallyHidden>
                </EffectButton>
              </Tooltip>
              <Tooltip title="i18n.global.backgroundEffect.blur">
                <EffectButton onClick={() => onEffectSelect('BLUR')} aria-selected={'BLUR' === selectedEffect}>
                  <BlurBackgroundIcon />
                </EffectButton>
              </Tooltip>
            </Row>
            <Headline variant="h5" component="h3">
              i18n.global.backgroundEffect.image.office
            </Headline>
            <Row>
              {
                // number of images is limited to 4 until we have a better UI
                BACKGROUND_REPLACE_IMAGES_OFFICE.slice(0, 4).map((effect) => (
                  <EffectButton
                    key={effect}
                    onClick={() => onEffectSelect(effect)}
                    aria-selected={effect === selectedEffect}
                  >
                    <img
                      alt={translate('global.backgroundEffect.image.office')}
                      src={getBackgroundReplaceImageThumbnailUrl(effect)}
                    />
                  </EffectButton>
                ))
              }
            </Row>
            <Headline variant="h5" component="h3">
              i18n.global.backgroundEffect.image.homeoffice
            </Headline>
            <Row>
              {
                // number of images is limited to 4 until we have a better UI
                BACKGROUND_REPLACE_IMAGES_HOMEOFFICE.slice(0, 4).map((effect) => (
                  <EffectButton
                    key={effect}
                    onClick={() => onEffectSelect(effect)}
                    aria-selected={effect === selectedEffect}
                  >
                    <img
                      alt={translate('global.backgroundEffect.image.homeoffice')}
                      src={getBackgroundReplaceImageThumbnailUrl(effect)}
                    />
                  </EffectButton>
                ))
              }
            </Row>
          </Column>
        </Column>
      </Card>
    ),
  });

  return (
    <EffectsPopoverIconButton
      icon="UserBackgroundIcon"
      loading={isInitializing}
      label="i18n.global.backgroundEffect.title"
      iconColor={isAnyEffectSelected ? 'blue' : 'white'}
      backgroundColor={isAnyEffectSelected ? 'white' : 'blurred'}
      hoverColor={isAnyEffectSelected ? 'white' : undefined}
      hoverBackgroundColor={isAnyEffectSelected ? 'carbon' : undefined}
      aria-pressed={isAnyEffectSelected}
    />
  );
};

const BlurBackgroundIcon: FC = () => (
  <SvgIcon viewBox="0 0 80 80" size={80}>
    <circle cx="55" cy="55" r="2" fill="#2F454C" />
    <circle cx="55" cy="45" r="2" fill="#2F454C" />
    <circle cx="55" cy="35" r="2" fill="#2F454C" />
    <circle cx="55" cy="25" r="2" fill="#2F454C" />
    <circle cx="45" cy="25" r="2" fill="#2F454C" />
    <circle cx="45" cy="18" r="1" fill="#627378" />
    <circle cx="62" cy="45" r="1" fill="#627378" />
    <circle cx="18" cy="45" r="1" fill="#627378" />
    <circle cx="45" cy="62" r="1" fill="#627378" />
    <circle cx="45" cy="55" r="2" fill="#2F454C" />
    <circle cx="35" cy="25" r="2" fill="#2F454C" />
    <circle cx="35" cy="18" r="1" fill="#627378" />
    <circle cx="62" cy="35" r="1" fill="#627378" />
    <circle cx="18" cy="35" r="1" fill="#627378" />
    <circle cx="35" cy="62" r="1" fill="#627378" />
    <circle cx="35" cy="55" r="2" fill="#2F454C" />
    <circle cx="25" cy="25" r="2" fill="#2F454C" />
    <circle cx="25" cy="35" r="2" fill="#2F454C" />
    <circle cx="25" cy="45" r="2" fill="#2F454C" />
    <circle cx="25" cy="55" r="2" fill="#2F454C" />
    <path
      d="M49.6499 48.6246C49.5841 48.7387 49.4894 48.8334 49.3753 48.8992C49.2613 48.965 49.1319 48.9997 49.0002 48.9996H31.0002C30.8686 48.9995 30.7394 48.9647 30.6255 48.8989C30.5116 48.833 30.417 48.7383 30.3513 48.6243C30.2855 48.5103 30.2509 48.381 30.251 48.2494C30.251 48.1178 30.2857 47.9886 30.3515 47.8746C31.7793 45.4062 33.9796 43.6362 36.5474 42.7971C35.2772 42.041 34.2904 40.8888 33.7384 39.5176C33.1865 38.1463 33.0999 36.6317 33.492 35.2065C33.8841 33.7813 34.7333 32.5242 35.909 31.6282C37.0847 30.7323 38.522 30.2471 40.0002 30.2471C41.4784 30.2471 42.9157 30.7323 44.0914 31.6282C45.2672 32.5242 46.1163 33.7813 46.5084 35.2065C46.9005 36.6317 46.8139 38.1463 46.262 39.5176C45.71 40.8888 44.7232 42.041 43.453 42.7971C46.0208 43.6362 48.2211 45.4062 49.649 47.8746C49.7149 47.9886 49.7497 48.1178 49.7499 48.2495C49.7501 48.3811 49.7156 48.5105 49.6499 48.6246Z"
      fill="#0A242C"
    />
  </SvgIcon>
);
