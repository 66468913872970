import { useEffect, useState } from 'react';

const getViewportWidth = () => Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

export const useViewportWidth = (onResize?: (viewportWidth: number) => void) => {
  const [viewportWidth, setViewportWidth] = useState(getViewportWidth());

  useEffect(() => {
    const recordViewportWidth = () => {
      const width = getViewportWidth();
      setViewportWidth(width);
      onResize?.(width);
    };
    window.addEventListener('resize', recordViewportWidth);
    return () => window.removeEventListener('resize', recordViewportWidth);
  }, [onResize]);

  return viewportWidth;
};
