import { type HTMLProps, type PropsWithChildren, forwardRef } from 'react';

import { Translatable, type TranslatableProps } from '../../../services/i18n';
import { type WithClickTrackingProps, withClickTracking } from '../../../services/tracking';

export type BaseLinkProps = HTMLProps<HTMLAnchorElement> & TranslatableProps & WithClickTrackingProps;

export const BaseLink = withClickTracking(
  forwardRef<HTMLAnchorElement, PropsWithChildren<BaseLinkProps>>(function BaseLink({ children, ...rest }, ref) {
    return (
      <a {...rest} ref={ref}>
        <Translatable>{children}</Translatable>
      </a>
    );
  })
);
