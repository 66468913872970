import { useContext } from 'react';

import { DialogsContext } from './DialogsContext';

export const useDialogs = () => {
  const context = useContext(DialogsContext);

  if (!context) {
    throw new Error('DialogsProvider missing in tree');
  }

  return context;
};
