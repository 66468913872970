import { styled } from '@mui/material';
import type { CSSProperties, FC, PropsWithChildren } from 'react';

const AspectRatioContainer = styled('div')(() => ({
  position: 'relative',
  aspectRatio: 'var(--ratio)',
  width: 'var(--width)',
  height: 'var(--height)',
  overflow: 'hidden',
}));

type AspectRatio = {
  ratio: number;
};
type AspectRatioViaWidth = AspectRatio & {
  width: number | string | undefined;
  height?: never;
};
type AspectRatioViaHeight = AspectRatio & {
  width?: never;
  height: number | string | undefined;
};
type AspectRatioViaNone = AspectRatio & {
  width?: never;
  height?: never;
};

type AspectRatioBoxProps = AspectRatioViaWidth | AspectRatioViaHeight | AspectRatioViaNone;

const addPixelUnit = (value: string | number | undefined) => (typeof value === 'number' ? `${value}px` : value);

export const AspectRatioBox: FC<PropsWithChildren<AspectRatioBoxProps>> = ({
  ratio,
  width,
  height,
  children,
  ...rest
}) => (
  <AspectRatioContainer
    {...rest}
    style={
      {
        '--ratio': String(ratio),
        '--width': addPixelUnit(width),
        '--height': addPixelUnit(height),
      } as CSSProperties
    }
  >
    {children}
  </AspectRatioContainer>
);
