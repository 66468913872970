import { type ComponentType, type MouseEventHandler, useId } from 'react';

import { Popover, type PopoverProps } from './Popover';
import { PopoverProvider } from './PopoverProvider';

export type WithPopoverOptions = {
  stopPropagation?: boolean;
};

export const withPopover = <OriginalProps extends { onClick?: MouseEventHandler<HTMLElement> }>(
  Component: ComponentType<OriginalProps>,
  popoverProps: Omit<PopoverProps, 'open'>,
  options: WithPopoverOptions = {}
) => {
  const WrappedComponent = ({ onClick, ...rest }: OriginalProps) => {
    const id = useId();

    return (
      <PopoverProvider>
        {({ anchorEl, isOpen, closePopover, triggerPopover }) => {
          const handleClick: MouseEventHandler<HTMLElement> = (event) => {
            triggerPopover(event);
            onClick?.(event);
            if (options.stopPropagation) event.stopPropagation();
          };
          const onClose: MouseEventHandler<HTMLElement> = (event) => {
            closePopover();
            if (options.stopPropagation) event.stopPropagation();
          };
          return (
            <>
              <Popover anchorEl={anchorEl} onClose={onClose} open={isOpen} id={id} {...popoverProps} />
              <Component
                {...(rest as unknown as OriginalProps)}
                onClick={handleClick}
                aria-expanded={isOpen}
                aria-describedby={id}
                aria-controls={id}
              />
            </>
          );
        }}
      </PopoverProvider>
    );
  };
  return WrappedComponent;
};
