import { styled } from '@mui/material';

import { preventForwardProps } from '../../../utilities/preventForwardProps';

export const DialogHeader = styled(
  'div',
  preventForwardProps(['withHeaderBottomBorder'])
)<{ withHeaderBottomBorder?: boolean }>(({ theme, withHeaderBottomBorder }) => ({
  marginBottom: theme.spacing(2),
  padding: 0,
  gap: theme.spacing(1),

  flex: '0 0 auto',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  paddingBottom: withHeaderBottomBorder ? theme.spacing(2) : undefined,
  borderBottom: withHeaderBottomBorder ? `1px solid ${theme.palette.divider}` : 'none',
}));
