import type { FC } from 'react';

import { SvgIcon, type SvgIconProps } from '../SvgIcon';

export const AddTransitionIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3812 12.7935C12.1565 12.6205 11.8435 12.6205 11.6188 12.7935L6.44044 16.7797C5.96764 17.1437 6.22502 17.9 6.82169 17.9H17.1783C17.775 17.9 18.0324 17.1437 17.5595 16.7797L12.3812 12.7935ZM12 14.393L9.39279 16.4H14.6072L12 14.393Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6187 12.2065L6.44044 8.22025C5.96764 7.85629 6.22502 7.1 6.82169 7.1L17.1783 7.1C17.775 7.1 18.0324 7.85629 17.5595 8.22025L12.3812 12.2065C12.1565 12.3795 11.8435 12.3795 11.6187 12.2065ZM14.6072 8.6L12 10.607L9.39279 8.6H14.6072Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 3.5C4.34315 3.5 3 4.84315 3 6.5V18.5C3 20.1569 4.34315 21.5 6 21.5H18C19.6569 21.5 21 20.1569 21 18.5V6.5C21 4.84315 19.6569 3.5 18 3.5H6ZM18 5H6C5.17157 5 4.5 5.67157 4.5 6.5V18.5C4.5 19.3284 5.17157 20 6 20H18C18.8284 20 19.5 19.3284 19.5 18.5V6.5C19.5 5.67157 18.8284 5 18 5Z"
      fill="currentColor"
    />
  </SvgIcon>
);
