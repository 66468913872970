import { styled } from '@mui/material';

import type { TextVariants } from '../../../theming/theme';
import { Typography, type TypographyProps } from '../Typography';

export type TextProps = Omit<TypographyProps, 'variant' | 'variants' | 'component' | 'fontFamily'> & {
  variant?: TextVariants;
  component?: 'p' | 'div' | 'span' | 'b' | 'pre' | 'label' | 'time';
  htmlFor?: string;
  dateTime?: string;
};

export const Text = styled(Typography)<TextProps>(() => ({}));
