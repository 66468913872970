import { createContext } from 'react';

import type { VideoCaptureSource, VideoFit, VideoFormat } from '@cofenster/constants';

export type Upload = {
  progress: number;
  id: string;
  error?: string;
  file?: File;
};

export type UploadType = 'audio' | 'video' | 'image';

export type UploadContext = {
  startUpload: (
    type: UploadType,
    uploadId: string,
    file: File,
    options?: {
      videoFormat?: VideoFormat;
      videoFit?: VideoFit;
      videoCaptureSource?: VideoCaptureSource;
    }
  ) => Promise<string | undefined>;
  cancelAndClearUpload: (type: UploadType, uploadId: string) => void;
  getUpload: (type: UploadType, uploadId: string) => Upload | undefined;
};

export const UploadContext = createContext<UploadContext | undefined>(undefined);
