import {
  FormControl,
  InputLabel,
  type InputLabelProps,
  type MenuProps,
  Select as MuiSelect,
  type SelectProps as MuiSelectProps,
  styled,
} from '@mui/material';
import type { ComponentProps, FC } from 'react';
export type { SelectChangeEvent } from '@mui/material';

import { useI18n } from '../../../services/i18n';
import { preventForwardProps } from '../../../utilities/preventForwardProps';

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.brand.grey700,
  '&:not(.MuiInputLabel-shrink)': {
    lineHeight: 1,
  },
}));

const StyledSelect = styled(MuiSelect)<MuiSelectProps>(({ theme }) => ({
  minHeight: theme.spacing(6),
  '.MuiSelect-select': {
    padding: theme.spacing(1, 1.5),
  },
  legend: {
    fontSize: 12,
  },
  label: {
    color: theme.palette.brand.grey700,
  },
  'label, .MuiInputBase-input': {
    ...theme.typography.l,
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderWidth: 1,
  },
}));

export const StyledFormControl = styled(
  FormControl,
  preventForwardProps(['pb'])
)<{ pb?: number }>(({ theme, pb = 4 }) => ({
  paddingBottom: theme.spacing(pb),
}));

// The `variant` prop is marked as required in `SelectProps`, but we default to
// `outlined` in the `Select` so it is actually optional for us.
export type SelectProps = Omit<MuiSelectProps, 'variant'> &
  Partial<Pick<MuiSelectProps, 'variant'>> & {
    name: string;
    label: string;
    maxLength?: number;
    pb?: number;
    inputProps?: Partial<ComponentProps<'input'>>;
    labelProps?: Partial<InputLabelProps>;
  };

export const SelectMenuProps: Partial<MenuProps> = {
  PaperProps: {
    sx: {
      maxHeight: 'min(400px, 90vh)',
      boxShadow: '0px 0px 30px rgb(0 0 0 / 0.1)',
      borderRadius: 1,
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: -8,
    horizontal: 'center',
  },
  MenuListProps: {
    sx: {
      padding: 0,
    },
  },
};

export const Select: FC<SelectProps> = ({
  label,
  maxLength,
  variant = 'outlined',
  id,
  name,
  pb,
  inputProps,
  MenuProps,
  labelProps,
  ...rest
}) => {
  const { translatable } = useI18n();
  const translatedLabel = translatable(label);

  return (
    <StyledFormControl fullWidth pb={pb}>
      <StyledInputLabel {...labelProps} id={`${id ?? name}-select-label`}>
        {translatedLabel}
      </StyledInputLabel>
      <StyledSelect
        {...rest}
        id={id ?? name}
        name={name}
        labelId={`${id ?? name}-select-label`}
        label={translatedLabel}
        variant={variant}
        inputProps={{ maxLength, ...inputProps }}
        MenuProps={MenuProps ?? SelectMenuProps}
      />
    </StyledFormControl>
  );
};
