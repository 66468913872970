import { CssBaseline, GlobalStyles as MuiGlobalStyles, ThemeProvider as MuiThemeProvider } from '@mui/material';
import type { FC, PropsWithChildren } from 'react';

import { BeatriceRegular, BeatriceSemibold, WorkSansBold, WorkSansRegular } from './fonts';
import { theme } from './theme';

const GlobalStyles: FC = () => (
  <>
    <MuiGlobalStyles
      styles={{
        a: { textDecoration: 'none', color: 'inherit' },

        [[
          // When the Intercom app has not been interacted with
          '.intercom-lightweight-app:has(~ .MuiDrawer-root) .intercom-lightweight-app-launcher',
          '.MuiDrawer-root ~ .intercom-lightweight-app .intercom-lightweight-app-launcher',

          // Once the Intercom app has been interacted with
          '#intercom-container:has(~ .MuiDrawer-root) > .intercom-app > *',
          '.MuiDrawer-root ~ #intercom-container > .intercom-app > *',
        ].join(', ')]: {
          right: '510px !important',
        },

        // 1. Animate the sidebar in and out of the screen when navigating from
        //    a page with the sidebar to a page without it, or from a page
        //    without to a page with the sidebar.
        '@keyframes slide-out-from-left': { to: { transform: 'translateX(-100%)' } },
        '::view-transition-old(sidebar):only-child': {
          animation: 'slide-out-from-left 300ms ease-out both', // 1
        },
        '::view-transition-new(sidebar):only-child': {
          animation: 'slide-out-from-left 300ms ease-out both reverse', // 1
        },

        // 1. Configure the default fade-out/fade-in view transition. Note that
        //    this is only applied when navigating via a router link using the
        //    `withViewTransition` prop.
        '::view-transition-old(root)': { animationDuration: '500ms' }, // 1
        '::view-transition-new(root)': { animationDuration: '1000ms' }, // 1
      }}
    />
    <MuiGlobalStyles styles={{ '@font-face': WorkSansRegular }} />
    <MuiGlobalStyles styles={{ '@font-face': WorkSansBold }} />
    <MuiGlobalStyles styles={{ '@font-face': BeatriceRegular }} />
    <MuiGlobalStyles styles={{ '@font-face': BeatriceSemibold }} />
  </>
);

export const ThemeProvider: FC<PropsWithChildren<Record<string, unknown>>> = ({ children }) => (
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <GlobalStyles />
    {children}
  </MuiThemeProvider>
);
