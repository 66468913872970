const roundToPrecision = (value: number, precision: number) => {
  const factor = 10 ** precision;
  return Math.round((value + Number.EPSILON) * factor) / factor;
};

export const FILE_SIZE_UNITS = ['B', 'KB', 'MB', 'GB', 'TB'];

// Note that this helper is duplicated from the `aws` package (where it has unit
// tests) because we do not have a good way to share functions across frontend
// and backend
export function formatFileSize(bytes: number, precision = 2) {
  if (!+bytes) return '0 B';

  const k = 1024;
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${roundToPrecision(bytes / k ** i, precision)} ${FILE_SIZE_UNITS[i]}`;
}
