import { useEffect } from 'react';

export const useInterval = (callback: (() => unknown) | null, interval = 1000) => {
  useEffect(() => {
    if (callback && interval > 0) {
      const handle = setInterval(callback, interval);
      return () => {
        clearInterval(handle);
      };
    }
  }, [callback, interval]);
};
