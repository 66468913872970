import { styled } from '@mui/material';

import type { BrandColor } from '../../../../theming/theme';
import { preventForwardProps } from '../../../../utilities/preventForwardProps';

import { BaseButton } from './BaseButton';

export const TertiaryButton = styled(
  BaseButton,
  preventForwardProps(['textColor'])
)<{ textColor?: BrandColor }>(({ theme, textColor }) => ({
  color: textColor ? theme.palette.brand[textColor] : 'currentcolor',
  backgroundColor: 'transparent',

  '&:hover, &:focus': {
    backgroundColor: 'rgb(10 36 44 / 0.06)',
  },

  '&.Mui-disabled': {
    cursor: 'not-allowed',
    color: theme.palette.brand.grey400,
  },
}));
