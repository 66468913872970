import { useCallback, useMemo, useState } from 'react';

import { useTracking } from '../../../../../context/tracking';

// We’re enforing the use of environment camera because using the user camera
// by default, we encountered a couple of issues. Bug with user facing camera:
// If you open the camera, close if and reopen it, the preview will be zoomed
// in.
export const useFacingMode = () => {
  const tracking = useTracking();
  const [requestedFacingMode, setRequestedFacingMode] = useState<'user' | 'environment'>('environment');
  const [currentFacingMode, setCurrentFacingMode] = useState<null | 'user' | 'environment'>(null);
  const flipCamera = useCallback(
    () =>
      setRequestedFacingMode((prev) => {
        const nextMode = prev === 'environment' ? 'user' : 'environment';
        tracking.trackEvent({
          event: 'recordSettingUpdated',
          details: {
            settingName: 'cameraMode',
            settingType: nextMode,
          },
        });
        return nextMode;
      }),
    [tracking]
  );

  return useMemo(
    () => ({
      requestedFacingMode,
      currentFacingMode,
      flipCamera,
      setCurrentFacingMode,
    }),
    [requestedFacingMode, currentFacingMode, flipCamera]
  );
};
