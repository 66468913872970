import type { TargetLanguageCode } from 'deepl-node';

export const UNDERSCORED_SUPPORTED_TRANSCRIPTION_LANGUAGES = [
  'da_DK',
  'de_CH',
  'de_DE',
  'en_GB',
  'en_US',
  'es_ES',
  'fi_FI',
  'fr_FR',
  'he_IL',
  'hi_IN',
  'hu_HU',
  'it_IT',
  'ja_JP',
  'ko_KR',
  'nl_NL',
  'no_NO',
  'pl_PL',
  'pt_BR',
  'pt_PT',
  'ru_RU',
  'sv_SE',
  'th_TH',
  'tr_TR',
  'uk_UA',
  'zh_CN',
] as const satisfies `${string}_${string}`[];

export const HYPHENATED_SUPPORTED_TRANSCRIPTION_LANGUAGES = [
  'da-DK',
  'de-CH',
  'de-DE',
  'en-GB',
  'en-US',
  'es-ES',
  'fi-FI',
  'fr-FR',
  'he-IL',
  'hi-IN',
  'hu-HU',
  'it-IT',
  'ja-JP',
  'ko-KR',
  'nl-NL',
  'no-NO',
  'pl-PL',
  'pt-BR',
  'pt-PT',
  'ru-RU',
  'sv-SE',
  'th-TH',
  'tr-TR',
  'uk-UA',
  'zh-CN',
] as const satisfies `${string}-${string}`[];

export const LANGUAGES_GOOGLE = {
  bg_BG: { provider: 'google', languageCode: 'bg' },
  cs_CZ: { provider: 'google', languageCode: 'cs' },
  da_DK: { provider: 'google', languageCode: 'da' },
  de_CH: { provider: 'google', languageCode: 'de' },
  de_DE: { provider: 'google', languageCode: 'de' },
  el_GR: { provider: 'google', languageCode: 'el' },
  en_GB: { provider: 'google', languageCode: 'en' },
  en_US: { provider: 'google', languageCode: 'en' },
  es_ES: { provider: 'google', languageCode: 'es' },
  et_EE: { provider: 'google', languageCode: 'et' },
  fi_FI: { provider: 'google', languageCode: 'fi' },
  fr_FR: { provider: 'google', languageCode: 'fr' },
  he_IL: { provider: 'google', languageCode: 'he' },
  hi_IN: { provider: 'google', languageCode: 'hi' },
  hu_HU: { provider: 'google', languageCode: 'hu' },
  id_ID: { provider: 'google', languageCode: 'id' },
  it_IT: { provider: 'google', languageCode: 'it' },
  ja_JP: { provider: 'google', languageCode: 'ja' },
  ko_KR: { provider: 'google', languageCode: 'ko' },
  lt_LT: { provider: 'google', languageCode: 'lt' },
  lv_LV: { provider: 'google', languageCode: 'lv' },
  nl_NL: { provider: 'google', languageCode: 'nl' },
  no_NO: { provider: 'google', languageCode: 'no' /* google supports norwegian bokmal as no */ },
  pl_PL: { provider: 'google', languageCode: 'pl' },
  pt_BR: { provider: 'google', languageCode: 'pt' },
  pt_PT: { provider: 'google', languageCode: 'pt' },
  ro_RO: { provider: 'google', languageCode: 'ro' },
  ru_RU: { provider: 'google', languageCode: 'ru' },
  sv_SE: { provider: 'google', languageCode: 'sv' },
  sk_SK: { provider: 'google', languageCode: 'sk' },
  sl_SI: { provider: 'google', languageCode: 'sl' },
  sr_RS: { provider: 'google', languageCode: 'sr' },
  th_TH: { provider: 'google', languageCode: 'th' },
  tr_TR: { provider: 'google', languageCode: 'tr' },
  uk_UA: { provider: 'google', languageCode: 'uk' },
  vi_VN: { provider: 'google', languageCode: 'vi' },
  zh_CN: { provider: 'google', languageCode: 'zh' },
} as const;

export const LANGUAGES_DEEPL = {
  bg_BG: { provider: 'deepl', languageCode: 'bg' },
  cs_CZ: { provider: 'deepl', languageCode: 'cs' },
  da_DK: { provider: 'deepl', languageCode: 'da' },
  de_DE: { provider: 'deepl', languageCode: 'de' },
  de_CH: { provider: 'deepl', languageCode: 'de' },
  el_GR: { provider: 'deepl', languageCode: 'el' },
  en_GB: { provider: 'deepl', languageCode: 'en-GB' },
  en_US: { provider: 'deepl', languageCode: 'en-US' },
  es_ES: { provider: 'deepl', languageCode: 'es' },
  et_EE: { provider: 'deepl', languageCode: 'et' },
  fi_FI: { provider: 'deepl', languageCode: 'fi' },
  fr_FR: { provider: 'deepl', languageCode: 'fr' },
  hu_HU: { provider: 'deepl', languageCode: 'hu' },
  id_ID: { provider: 'deepl', languageCode: 'id' },
  it_IT: { provider: 'deepl', languageCode: 'it' },
  ja_JP: { provider: 'deepl', languageCode: 'ja' },
  ko_KR: { provider: 'deepl', languageCode: 'ko' },
  lt_LT: { provider: 'deepl', languageCode: 'lt' },
  lv_LV: { provider: 'deepl', languageCode: 'lv' },
  no_NO: { provider: 'deepl', languageCode: 'nb' /* deepl supports norwegian bokmal as nb */ },
  nl_NL: { provider: 'deepl', languageCode: 'nl' },
  pl_PL: { provider: 'deepl', languageCode: 'pl' },
  pt_BR: { provider: 'deepl', languageCode: 'pt-BR' },
  pt_PT: { provider: 'deepl', languageCode: 'pt-PT' },
  ro_RO: { provider: 'deepl', languageCode: 'ro' },
  ru_RU: { provider: 'deepl', languageCode: 'ru' },
  sv_SE: { provider: 'deepl', languageCode: 'sv' },
  sk_SK: { provider: 'deepl', languageCode: 'sk' },
  sl_SI: { provider: 'deepl', languageCode: 'sl' },
  tr_TR: { provider: 'deepl', languageCode: 'tr' },
  uk_UA: { provider: 'deepl', languageCode: 'uk' },
  zh_CN: { provider: 'deepl', languageCode: 'zh' },
} as const satisfies Record<`${string}_${string}`, { provider: 'deepl'; languageCode: TargetLanguageCode }>;

export const SRT_TRANSLATION_LANGUAGES = {
  ...LANGUAGES_GOOGLE,
  ...LANGUAGES_DEEPL,
} as const;

export const SUPPORTED_TRANSLATION_LANGUAGES = Object.keys(SRT_TRANSLATION_LANGUAGES);

export type UnderscoredTranscriptionLanguage = (typeof UNDERSCORED_SUPPORTED_TRANSCRIPTION_LANGUAGES)[number];
export type HyphenatedTranscriptionLanguage = (typeof HYPHENATED_SUPPORTED_TRANSCRIPTION_LANGUAGES)[number];
export type LanguageCodeDeepl = keyof typeof LANGUAGES_DEEPL;
export type LanguageCodeGoogle = keyof typeof LANGUAGES_GOOGLE;
export type LanguageCode = LanguageCodeDeepl | LanguageCodeGoogle;
