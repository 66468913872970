import { useMemo } from 'react';
import { type IntlShape, createIntl, createIntlCache } from 'react-intl';

import type { SupportedLocale, Translations } from './types';

export type Intl = IntlShape;

export const isSupportedLocale = <T extends Translations>(
  translations: T,
  locale?: string
): locale is SupportedLocale<T> => !!locale && Object.keys(translations).includes(locale);

export const useReactIntl = <T extends Translations>(
  translations: T,
  defaultLocale: SupportedLocale<T>,
  userLocale?: string
) => {
  const locale = isSupportedLocale(translations, userLocale) ? userLocale : defaultLocale;
  return useMemo(() => {
    const intlCache = createIntlCache();
    const intl = createIntl(
      {
        locale: isSupportedLocale(translations, userLocale) ? userLocale : defaultLocale,
        defaultLocale: defaultLocale,
        messages: translations[locale],
        onError: (error) => {
          if (error.code === 'MISSING_TRANSLATION') {
            return console.warn('Missing translation key:', error.descriptor?.id);
          }
          throw error;
        },
      },
      intlCache
    );
    return { intl, locale };
  }, [translations, userLocale, defaultLocale, locale]);
};
