import { styled } from '@mui/material';
import type { FC } from 'react';

import { useLocalizedWebsiteUrl } from '../../../hooks/utils/useLocalizedWebsiteUrl';
import { useTracking } from '../../../services/tracking/useTracking';
import { Icon } from '../../assets/icons/Icon';
import { Button } from '../../controls/Button';
import { TextLink } from '../../controls/TextLink';
import { GridContainer, GridItem } from '../../layout/Grid';
import { Text } from '../../typography/Text';

const Container = styled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  left: '50%',
  transform: 'translateX(-50%)',
  width: `calc(100% - ${theme.spacing(8)})`,
  padding: theme.spacing(2),
  color: theme.palette.brand.white,
  backgroundColor: theme.palette.brand.carbon,
  borderRadius: theme.shape['borderRadius-l'],
  boxShadow: theme.shadows[1],
  zIndex: theme.zIndex.top,
  [theme.breakpoints.up('sm')]: {
    width: '70%',
  },
  [theme.breakpoints.up('lg')]: {
    width: '50%',
  },
}));

export const CookieBanner: FC = () => {
  const url = useLocalizedWebsiteUrl('PRIVACY_POLICY');
  const { isDisabled, enable, disable } = useTracking();

  if (isDisabled !== undefined) return null;

  return (
    <Container data-testid="cookie-banner">
      <GridContainer
        alignItems={{ xs: 'center', md: 'center' }}
        justifyContent={{ xs: 'center', md: 'flex-start' }}
        flexWrap={{ xs: 'wrap', md: 'nowrap' }}
      >
        <GridItem display={{ xs: 'none', md: 'block' }}>
          <Icon type="CookieIcon" size="l" />
        </GridItem>
        <GridItem xs={12}>
          <Text>
            <Text
              i18nParams={{
                link: (chunks) => (
                  <TextLink underline href={url} target="_blank">
                    {chunks}
                  </TextLink>
                ),
              }}
            >
              i18n.global.cookieBanner.text
            </Text>
          </Text>
        </GridItem>
        <GridItem>
          <Button variant="tertiary" onClick={disable} data-testid="cookie-banner-decline-button">
            i18n.global.cookieBanner.decline
          </Button>
        </GridItem>
        <GridItem>
          <Button variant="secondary" onClick={enable} data-testid="cookie-banner-accept-button">
            i18n.global.cookieBanner.accept
          </Button>
        </GridItem>
      </GridContainer>
    </Container>
  );
};
