import { type Context, type PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';

import type { DialogComponent, Dialogs, DialogsContext, GenericDialogProps, OpenRecord } from './DialogsContext';

type DialogsProviderProps<D extends Dialogs> = PropsWithChildren<{
  DialogsContext: Context<DialogsContext<D>>;
  dialogs: Record<keyof D, DialogComponent>;
}>;

export function DialogsProvider<D extends Dialogs>({ children, dialogs, DialogsContext }: DialogsProviderProps<D>) {
  const [openDialogs, setOpenDialogs] = useState<OpenRecord<D>>({});

  const openDialog: DialogsContext<D>['openDialog'] = useCallback(
    (name, props) => setOpenDialogs((openDialogs) => ({ ...openDialogs, [name]: props }) as OpenRecord<D>),
    []
  );

  const closeDialog: DialogsContext<D>['closeDialog'] = useCallback(
    (name) =>
      setOpenDialogs((openDialogs) => {
        const nextOpenDialogs = { ...openDialogs };
        delete nextOpenDialogs[name];
        return nextOpenDialogs;
      }),
    []
  );

  const isOpen: DialogsContext<D>['isOpen'] = useCallback(
    (name) => (name ? name in openDialogs : Object.keys(openDialogs).length > 0),
    [openDialogs]
  );

  const context = useMemo(() => ({ openDialog, closeDialog, isOpen }), [openDialog, closeDialog, isOpen]);

  useEffect(() => {
    const closeDialogs = () => setOpenDialogs({});

    window.addEventListener('popstate', closeDialogs);

    return () => {
      window.removeEventListener('popstate', closeDialogs);
    };
  }, []);

  return (
    <DialogsContext.Provider value={context}>
      {children}

      {Object.entries(openDialogs).map(([name, props]) => {
        const Component = dialogs[name] as DialogComponent;
        const baseProps = props as GenericDialogProps<typeof Component>;
        const close = closeDialog.bind(null, name);

        return <Component key={name} {...baseProps} isOpen closeDialog={close} />;
      })}
    </DialogsContext.Provider>
  );
}
