import { type ComponentType, type FC, memo } from 'react';

import { LoadingSpinner, useUpload } from '@cofenster/web-components';

import { usePollingImageAsset } from '../../../api/hooks/imageAsset/usePollingImageAsset';
import { usePollingVideoAsset } from '../../../api/hooks/videoAsset/usePollingVideoAsset';
import { useCaptureAssetCandidateFileContext } from '../../../context/captureAssetFile';
import { useCapturePermissions } from '../../../hooks/usePermissions';
import { FormatAwareContentArea } from '../../layout';
import { CallToActionRequiredWithDropzone } from '../CallToActionRequiredWithDropzone';
import { useCaptureAssetLifecycleFlow } from '../CaptureAssetLifecycleFlow';
import { RetrieveFileCache } from '../RetrieveFileCache';
import { UploadingOrProcessingCaptureAsset } from '../UploadingOrProcessingCaptureAsset';
import type { AssetReadyComponentProps, AssetReviewComponentProps, CaptureComponentProps } from '../types';
import { useRetrieveFileCache } from './useRetrieveFileCache';

const usePollingAsset = (id: string | null | undefined, type: 'video' | 'image') => {
  const videoAsset = usePollingVideoAsset(type === 'video' ? id : undefined);
  const imageAsset = usePollingImageAsset(type === 'image' ? id : undefined);

  if (type === 'video') {
    if (!videoAsset) return;
    return {
      assetUrl: videoAsset.videoUrl ?? null,
      status: videoAsset.status,
      thumbnailUrl: videoAsset.thumbnailUrl,
    };
  }
  if (type === 'image') {
    if (!imageAsset) return;
    return {
      assetUrl: imageAsset.imageUrl ?? null,
      status: imageAsset.status,
    };
  }
};

export const CaptureAsset: FC<{
  assetIdForPolling: string | null | undefined;
  uploadId: string;
  showRecordViaApp: boolean;
  showFramingSuggestions?: boolean;
  showUploadRetakeMessage?: boolean;
  ReviewComponent: ComponentType<AssetReviewComponentProps>;
  AssetComponent: ComponentType<AssetReadyComponentProps>;
  CaptureComponent: ComponentType<CaptureComponentProps>;
}> = memo(
  ({
    ReviewComponent,
    AssetComponent,
    CaptureComponent,
    uploadId,
    showRecordViaApp,
    assetIdForPolling,
    showFramingSuggestions = true,
    showUploadRetakeMessage = true,
  }) => {
    const { captureType, requiredPermissions, assetType } = useCaptureAssetLifecycleFlow();
    const captureAsset = usePollingAsset(assetIdForPolling, assetType);

    const { getUpload } = useUpload();
    const upload = getUpload(assetType, uploadId);

    const { status, request } = useCapturePermissions(requiredPermissions);
    const { candidateFile } = useCaptureAssetCandidateFileContext();
    const { cachedFile, cacheLoading, onSelectCachedFile } = useRetrieveFileCache(uploadId);

    const isLoading = (typeof captureAsset === 'undefined' && assetIdForPolling) || cacheLoading;

    if (isLoading) {
      return (
        <FormatAwareContentArea>
          <LoadingSpinner />
        </FormatAwareContentArea>
      );
    }

    const hasProcessingAsset = captureAsset && !captureAsset?.assetUrl;
    if (hasProcessingAsset || upload) {
      return <UploadingOrProcessingCaptureAsset uploadId={uploadId} status={captureAsset?.status} type={captureType} />;
    }

    if (candidateFile) {
      return (
        <ReviewComponent
          assetBlob={candidateFile.blob}
          uploadId={uploadId}
          showUploadRetakeMessage={showUploadRetakeMessage}
        />
      );
    }

    if (cachedFile) {
      return (
        <RetrieveFileCache
          cachedFile={cachedFile}
          uploadId={uploadId}
          assetType={assetType}
          onAction={onSelectCachedFile}
        />
      );
    }

    if (captureAsset?.assetUrl) {
      return <AssetComponent url={captureAsset.assetUrl} />;
    }

    if (status === 'granted') {
      return (
        <CaptureComponent
          showRecordViaApp={showRecordViaApp}
          type={captureType}
          showFramingSuggestions={showFramingSuggestions}
        />
      );
    }

    return (
      <CallToActionRequiredWithDropzone
        type={captureType}
        permissionStatus={status}
        callToAction={status === 'prompt' ? request : undefined}
      />
    );
  }
);
CaptureAsset.displayName = 'SceneAsset';
