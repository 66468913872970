import { SvgIcon as MuiSvgIcon, type SvgIconProps as MuiSvgIconProps, styled } from '@mui/material';

import { preventForwardProps } from '../../../utilities/preventForwardProps';

import type { IconProps } from './types';

export type SvgIconProps = Omit<MuiSvgIconProps, 'color' & 'size'> & {
  color?: IconProps['color'];
  size?: IconProps['size'];
};

export const SvgIcon = styled(
  MuiSvgIcon,
  preventForwardProps(['size', 'weight', 'fill'])
)<SvgIconProps>(({ size: fontSize, color }) => ({ verticalAlign: 'sub', fontSize, color }));
