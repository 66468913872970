import { useCallback, useEffect } from 'react';

import { useI18n } from '../../services/i18n';

export const useWarnBeforeUnload = (dirty: boolean, translateKey = 'global.beforeUnload.content') => {
  const { translatable } = useI18n();
  const warnBeforeLeaving = useCallback(
    (event: BeforeUnloadEvent) => {
      if (dirty) {
        event.preventDefault();
        event.returnValue = translatable(translateKey);
      }
    },
    [dirty, translatable, translateKey]
  );

  useEffect(() => {
    // This event is registered using `onbeforeunload` instead of `addEventListener` since this is easier to stub in
    // Cypress this way
    window.onbeforeunload = warnBeforeLeaving;
    return () => {
      window.onbeforeunload = null;
    };
  }, [warnBeforeLeaving]);
};
