import { type FC, type PropsWithChildren, useCallback, useMemo, useState } from 'react';

import type { VideoCaptureSource, VideoFit, VideoFormat } from '@cofenster/constants';

import { useWarnBeforeUnload } from '../../hooks/dom/useWarnBeforeUnload';

import { type Upload, UploadContext, type UploadType } from './UploadContext';

type Uploads = Record<UploadType, Record<string, Upload>>;

type AbortToken = {
  controller: AbortController;
  id: string;
  error?: string;
};

type AbortTokens = Record<UploadType, Record<string, AbortToken>>;

type UploadProviderProps = {
  warnBeforeUnload?: boolean;
  clearUploadOnFinish?: boolean;
  useUpload: () => (
    type: UploadType,
    file: File,
    uploadId: string,
    onProgress: (id: string, progress: number) => unknown,
    abortSignal: AbortController['signal'],
    options?: {
      videoFormat?: VideoFormat;
      videoFit?: VideoFit;
      videoCaptureSource?: VideoCaptureSource;
    }
  ) => Promise<string | undefined>;
};

export const UploadProvider: FC<PropsWithChildren<UploadProviderProps>> = ({
  clearUploadOnFinish,
  useUpload,
  children,
  warnBeforeUnload,
}) => {
  const uploadFunction = useUpload();

  const [uploads, setUploads] = useState<Uploads>({
    video: {},
    image: {},
    audio: {},
  });

  const [abortTokens, setAbortTokens] = useState<AbortTokens>({
    video: {},
    image: {},
    audio: {},
  });

  const setAbortToken = useCallback((type: keyof Uploads, uploadId: string) => {
    const controller = new AbortController();

    setAbortTokens((abortTokens) => {
      return {
        ...abortTokens,
        [type]: {
          ...abortTokens[type],
          [uploadId]: {
            ...abortTokens[type][uploadId],
            controller: controller,
          },
        },
      };
    });
    return controller.signal;
  }, []);

  const setUploadProgress = useCallback((type: keyof Uploads, uploadId: string, progress: number) => {
    setUploads((uploads) => {
      return {
        ...uploads,
        [type]: {
          ...uploads[type],
          [uploadId]: {
            ...uploads[type][uploadId],
            progress,
          },
        },
      };
    });
  }, []);

  const startUploadProgress = useCallback((type: UploadType, uploadId: string, file: File) => {
    setUploads((uploads) => {
      return {
        ...uploads,
        [type]: {
          ...uploads[type],
          [uploadId]: {
            ...uploads[type][uploadId],
            progress: 0,
            file,
          },
        },
      };
    });
  }, []);

  const clearUpload = useCallback(
    (type: UploadType, uploadId: string) => {
      setUploads((uploads) => {
        const { [uploadId]: remove, ...rest } = uploads[type];
        return {
          ...uploads,
          [type]: rest,
        };
      });
      abortTokens[type][uploadId]?.controller.abort();
      setAbortToken(type, uploadId);
    },
    [abortTokens, setAbortToken]
  );

  const cancelUpload = useCallback(
    (type: UploadType, uploadId: string) => {
      const abortController = abortTokens[type][uploadId]?.controller;
      abortController?.abort();
      clearUpload(type, uploadId);
    },
    [abortTokens, clearUpload]
  );

  const startUpload = useCallback<UploadContext['startUpload']>(
    async (type, uploadId, file, options) => {
      const signal = setAbortToken(type, uploadId);
      const onProgress = (uploadId: string, progress: number) => {
        if (signal.aborted) return;
        setUploadProgress(type, uploadId, progress);
      };
      startUploadProgress(type, uploadId, file);

      const internalUpload = async () => {
        return uploadFunction(type, file, uploadId, onProgress, signal, options);
      };

      if (clearUploadOnFinish) {
        const assetId = await internalUpload();
        clearUpload(type, uploadId);
        return assetId;
      }
      return await internalUpload();
    },
    [setUploadProgress, setAbortToken, clearUpload, startUploadProgress, clearUploadOnFinish, uploadFunction]
  );

  const getUpload = useCallback<UploadContext['getUpload']>(
    (type: UploadType, uploadId: string) => {
      return uploads[type][uploadId];
    },
    [uploads]
  );

  const context = useMemo(
    () => ({
      startUpload,
      cancelAndClearUpload: cancelUpload,
      getUpload,
    }),
    [startUpload, cancelUpload, getUpload]
  );

  return (
    <UploadContext.Provider value={context}>
      {children}
      {warnBeforeUnload && <ActiveUploadsConfirmBlocker uploads={uploads} />}
    </UploadContext.Provider>
  );
};

const ActiveUploadsConfirmBlocker: FC<{ uploads: Uploads }> = ({ uploads }) => {
  const shouldBlock =
    Object.keys(uploads.audio).length > 0 ||
    Object.keys(uploads.image).length > 0 ||
    Object.keys(uploads.video).length > 0;

  useWarnBeforeUnload(shouldBlock);
  return null;
};
