import { type FC, type MouseEvent, type ReactNode, createContext, useCallback, useMemo, useState } from 'react';

export type PopoverContext = {
  isOpen: boolean;
  triggerPopover: (event: { currentTarget: MouseEvent['currentTarget'] }) => void;
  closePopover: VoidFunction;
  anchorEl: MouseEvent['currentTarget'] | undefined;
};

export const PopoverContext = createContext<PopoverContext | undefined>(undefined);

export type PopoverProviderProps = {
  children: (context: PopoverContext) => ReactNode;
};

export const PopoverProvider: FC<PopoverProviderProps> = ({ children }) => {
  const [anchorEl, setAnchorElement] = useState<Element | undefined>();

  const triggerPopover = useCallback((event: { currentTarget: MouseEvent['currentTarget'] }) => {
    setAnchorElement(event.currentTarget);
  }, []);

  const closePopover = useCallback(() => {
    setAnchorElement(undefined);
  }, []);

  const context: PopoverContext = useMemo(
    () => ({
      triggerPopover,
      closePopover,
      anchorEl,
      isOpen: !!anchorEl,
    }),
    [anchorEl, triggerPopover, closePopover]
  );

  return <PopoverContext.Provider value={context}>{children(context)}</PopoverContext.Provider>;
};
