import axios, { type AxiosProgressEvent, isCancel } from 'axios';

export type UploadFileOptions = {
  onProgress?: (progressData: { progress: number }) => void;
  signal?: AbortController['signal'];
  method?: 'PUT' | 'POST';
};

export const uploadFile = (
  url: string,
  file: File | FormData,
  { onProgress, signal, method = 'PUT' }: UploadFileOptions = {}
) => {
  const contentType = 'type' in file ? file.type : undefined;
  const headers = contentType ? { 'Content-Type': contentType } : undefined;

  const onUploadProgress = ({ loaded, total }: AxiosProgressEvent) => {
    onProgress?.({ progress: total ? Math.floor((loaded / total) * 100) / 100 : 0 });
  };

  const config = { headers, onUploadProgress, signal };

  if (method === 'PUT') {
    return axios.put(url, file, config);
  }
  if (method === 'POST') {
    return axios.post(url, file, config);
  }

  throw new Error(`Invalid method \`${method}\` for \`uploadFile\``);
};

export const isCancellationError = isCancel;
