import { type FC, type PropsWithChildren, useCallback, useMemo } from 'react';

import { type FeatureFlagName, FeatureFlagsContext } from './FeatureFlagsContext';

export const FeatureFlagsProvider: FC<PropsWithChildren<{ featureFlags: { name: string }[] }>> = ({
  children,
  featureFlags,
}) => {
  const flagNames = useMemo(() => featureFlags.map((flag) => flag.name), [featureFlags]);

  const hasFeature = useCallback(
    (...expectedFlagNames: FeatureFlagName[]) =>
      expectedFlagNames.every((expectedFlagName) => flagNames.includes(expectedFlagName)),
    [flagNames]
  );

  const context = useMemo(() => ({ hasFeature }), [hasFeature]);

  return <FeatureFlagsContext.Provider value={context}>{children}</FeatureFlagsContext.Provider>;
};
