import type { ComponentType, MouseEventHandler } from 'react';

import { type PopoverProps, type WithPopoverOptions, withPopover } from '../../feedback/Popover';

export const withPopoverMenu = <
  OriginalProps extends { onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement> },
>(
  Component: ComponentType<OriginalProps>,
  {
    anchorOriginX = 'right',
    anchorOriginY = 'bottom',
    transformOriginX = 'right',
    ...rest
  }: Omit<PopoverProps, 'open'>,
  options?: WithPopoverOptions
) => {
  const WrappedComponent = withPopover(
    Component,
    {
      ...rest,
      anchorOriginX,
      anchorOriginY,
      transformOriginX,
    },
    options
  );
  return WrappedComponent;
};
