import { type PropsWithChildren, type ReactNode, forwardRef } from 'react';

import { Translatable, type TranslatableProps } from '../../../services/i18n';
import { type WithClickTrackingProps, withClickTracking } from '../../../services/tracking';
import type { BrandColor } from '../../../theming/theme';
import { SpinningIcon } from '../../assets/icons/SpinningIcon';
import { VisuallyHidden } from '../../utilities/VisuallyHidden';

import type { BaseButtonProps } from './components/BaseButton';
import { BlurredButton } from './components/BlurredButton';
import { DestructiveButton } from './components/DestructiveButton';
import { PrimaryButton } from './components/PrimaryButton';
import { SecondaryButton } from './components/SecondaryButton';
import { TertiaryButton } from './components/TertiaryButton';

export type ButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'destructive' | 'blurred';
export type ButtonProps = Omit<BaseButtonProps, 'variant' | 'color'> & {
  variant?: ButtonVariant;
  loading?: boolean;
  textColor?: BrandColor;
} & WithClickTrackingProps &
  TranslatableProps;

const getLoadingProps = (children: ReactNode, props: ButtonProps) =>
  props.loading
    ? {
        // Define a loading spinner as a start icon unless it was explicitly
        // opted out from by passing `startIcon={null}`.
        startIcon: props.startIcon !== null ? <SpinningIcon speed="fast" size="s" type="LoadingIcon" /> : undefined,
        // Buttons should typically not be disabled. Disabled buttons are not
        // discoverable by assistive technologies as they are essentially
        // removed from the DOM/AOM. It is especially important not to disable
        // a button in loading state, otherwise the focus gets immediately lost
        // when using it. Still, we want to convey it’s not actionable.
        'aria-disabled': true,
        className: [props.className, 'Mui-disabled'].filter(Boolean).join(' '),
        // While we do prevent clicks on the disabled button via the
        // `Mui-disabled` class (`pointer-events: none`), it can still be
        // focused and interacted with via keyboard. This is why we unset the
        // click handler and href.
        onClick: undefined,
        href: undefined,
        children: (
          <>
            {children}{' '}
            <VisuallyHidden>
              (<Translatable>i18n.global.loading</Translatable>)
            </VisuallyHidden>
          </>
        ),
      }
    : { children };

export const Button = withClickTracking(
  forwardRef<HTMLButtonElement, PropsWithChildren<ButtonProps>>(function Button(
    { variant = 'primary', textColor, fullWidth = false, children, i18nParams, ...rest },
    ref
  ) {
    const i18nChildren = <Translatable i18nParams={i18nParams}>{children}</Translatable>;
    const { children: content, ...loadingProps } = getLoadingProps(i18nChildren, rest);
    const buttonProps = { fullWidth, ref, ...rest, ...loadingProps, loading: undefined };

    if (variant === 'primary')
      return (
        <PrimaryButton {...buttonProps} variant="contained" color="primary">
          {content}
        </PrimaryButton>
      );

    if (variant === 'secondary')
      return (
        <SecondaryButton {...buttonProps} variant="outlined" color="secondary">
          {content}
        </SecondaryButton>
      );

    if (variant === 'tertiary')
      return (
        <TertiaryButton {...buttonProps} variant="text" textColor={textColor}>
          {content}
        </TertiaryButton>
      );

    if (variant === 'destructive') return <DestructiveButton {...buttonProps}>{content}</DestructiveButton>;

    if (variant === 'blurred') {
      return <BlurredButton {...buttonProps}>{content}</BlurredButton>;
    }

    return null;
  })
);
