import { useEffect } from 'react';

import { brandColors } from '../../theming';

export const useVersionLog = (pkg: { description: string; version: string }) => {
  useEffect(() => {
    console.log(
      `%c${pkg.description}%cv${pkg.version}`,
      `color: ${brandColors.white}; background-color: ${brandColors.carbon}; padding: 0 10px`,
      `color: ${brandColors.blue}; background-color: ${brandColors.linen}; padding: 0 10px`
    );
  }, [pkg.description, pkg.version]);
};
