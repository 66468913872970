import { createContext } from 'react';

import type { SnackbarProps } from '../../components/feedback/Snackbar';

export type SnackbarsContext = {
  openSnackbar: (props: Partial<SnackbarProps>) => string;
  closeSnackbar: (id: string) => void;
};

export const SnackbarsContext = createContext<SnackbarsContext | undefined>(undefined);
