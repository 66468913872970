import { styled } from '@mui/material';
import type { FC } from 'react';

import { Spacing } from '../../layout/Spacing';
import { VisuallyHidden } from '../../utilities/VisuallyHidden';

const rotate = { to: { transform: 'rotate(1turn)' } };
const dash = {
  '0%': {
    strokeDasharray: '1, 150',
    strokeDashoffset: 0,
  },
  '50%': {
    strokeDasharray: '90, 150',
    strokeDashoffset: -35,
  },
  '100%': {
    strokeDasharray: '90, 150',
    strokeDashoffset: -124,
  },
};

const SVG = styled('svg')<{ size: number }>(({ size, theme }) => ({
  width: size,
  height: size,
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
  animation: 'rotate 2s linear infinite',
  '@keyframes rotate': rotate,

  circle: {
    stroke: theme.palette.brand.blue,
    strokeLinecap: 'round',
    animation: 'dash 1.5s ease-in-out infinite',
    '@keyframes dash': dash,
  },
}));

type Props = {
  label?: string;
  size?: number;
  spacing?: number;
  strokeWidth?: number;
};

export const LoadingSpinner: FC<Props> = ({
  label = 'i18n.global.loading',
  size = 50,
  spacing = 3,
  strokeWidth = Math.min(size / 10, 5),
}) => (
  <Spacing vertical={spacing}>
    <SVG size={size}>
      <circle cx={size / 2} cy={size / 2} r={(size / 5) * 2} fill="none" strokeWidth={strokeWidth} />
    </SVG>
    <VisuallyHidden>{label}</VisuallyHidden>
  </Spacing>
);
