import { styled } from '@mui/material';
import type { FC } from 'react';

import { useLocalizedWebsiteUrl } from '../../../hooks/utils/useLocalizedWebsiteUrl';
import { Translatable } from '../../../services/i18n';

const NavList = styled('ol')(() => ({
  listStyleType: 'none',
  margin: 0,
  padding: 0,
}));

const NavItem = styled('li')(({ theme }) => ({
  display: 'inline-block',
  padding: theme.spacing(0, 2, 0, 2),
}));

const Link = styled('a')(({ theme }) => ({
  ...theme.typography.m,
}));

export const LegalNavigation: FC = () => {
  const imprint = useLocalizedWebsiteUrl('IMPRINT');
  const terms = useLocalizedWebsiteUrl('TERMS_OF_USE');
  const privacy = useLocalizedWebsiteUrl('PRIVACY_POLICY');

  return (
    <NavList>
      <NavItem>
        <Link href={imprint}>
          <Translatable>i18n.global.imprint</Translatable>
        </Link>
      </NavItem>
      <NavItem>
        <Link href={terms}>
          <Translatable>i18n.global.termsAndConditions</Translatable>
        </Link>
      </NavItem>
      <NavItem>
        <Link href={privacy}>
          <Translatable>i18n.global.dataProtection</Translatable>
        </Link>
      </NavItem>
    </NavList>
  );
};
