import { type Dispatch, type SetStateAction, useEffect } from 'react';
import type { ContributionRequestListByActor } from '../../api/hooks/contributionRequestList/useContributionRequestListByActor';

type ContributionRequestList = ContributionRequestListByActor['contributionRequestList'];

export const useSyncTaskProgressWithBackend = (
  list: ContributionRequestList,
  setContributionIdsByTaskId: Dispatch<SetStateAction<Record<string, string[]>>>
) => {
  useEffect(() => {
    const backendTaskToContributionIds = list.requests.reduce<Record<string, string[]>>(
      (acc, next) => ({
        ...acc,
        [next.id]: next.contributions
          .map((contribution) => {
            if (contribution.__typename === 'ImageContribution') {
              return contribution.imageAsset.id;
            }
            if (contribution.__typename === 'VideoContribution') {
              return contribution.videoAsset.id;
            }
          })
          .filter((it) => it !== undefined),
      }),
      {}
    );

    setContributionIdsByTaskId((persistedContributionIdsByTaskId) => {
      const syncedContributionIdsByTaskId: Record<string, string[]> = {};

      Object.entries(persistedContributionIdsByTaskId).forEach(([taskId, persistedContributionIds]) => {
        syncedContributionIdsByTaskId[taskId] = persistedContributionIds.filter((contributionId) =>
          backendTaskToContributionIds[taskId]?.includes(contributionId)
        );
      });

      return syncedContributionIdsByTaskId;
    });

    setContributionIdsByTaskId((persistedContributionIdsByTaskId) =>
      Object.entries(persistedContributionIdsByTaskId).reduce<Record<string, string[]>>(
        (acc, [taskId, persistedContributionIds]) => ({
          ...acc,
          [taskId]: persistedContributionIds.filter((contributionId) =>
            backendTaskToContributionIds[taskId]?.includes(contributionId)
          ),
        }),
        {}
      )
    );
  }, [list, setContributionIdsByTaskId]);
};
