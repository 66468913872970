import { type FC, type PropsWithChildren, useCallback, useMemo, useState } from 'react';

import type { SnackbarProps } from '../../components/feedback/Snackbar';

import { ManagedSnackbar } from './ManagedSnackbar';
import { SnackbarsContext } from './SnackbarsContext';

export const SnackbarsProvider: FC<PropsWithChildren<Record<string, unknown>>> = ({ children }) => {
  const [snackbars, setSnackbars] = useState<Record<string, Partial<SnackbarProps>>>({});

  const openSnackbar: SnackbarsContext['openSnackbar'] = useCallback((props) => {
    const id = (+new Date()).toString();
    setSnackbars((snackbars) => ({ ...snackbars, [id]: props }));
    return id;
  }, []);

  const closeSnackbar: SnackbarsContext['closeSnackbar'] = useCallback((id) => {
    setSnackbars((snackbars) => {
      const { [id]: value, ...rest } = snackbars;
      return rest;
    });
  }, []);

  const context = useMemo(() => ({ openSnackbar, closeSnackbar }), [openSnackbar, closeSnackbar]);

  return (
    <SnackbarsContext.Provider value={context}>
      {children}

      {Object.keys(snackbars).map((id) => (
        <ManagedSnackbar key={id} id={id} {...snackbars[id]} />
      ))}
    </SnackbarsContext.Provider>
  );
};
