import { useContext } from 'react';

import { UploadContext } from './UploadContext';

export const useUpload = () => {
  const context = useContext(UploadContext);

  if (!context) {
    throw new Error('UploadContext missing in tree');
  }

  return context;
};
