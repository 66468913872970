import { styled } from '@mui/material';

import { BaseButton } from './BaseButton';

export const DestructiveButton = styled(BaseButton)(({ theme }) => ({
  backgroundColor: theme.palette.brand.negative,
  color: theme.palette.brand.white,

  '&:hover, &:focus': {
    backgroundColor: theme.palette.brand.negativeDark,
  },

  '&.Mui-disabled': {
    cursor: 'not-allowed',
    color: theme.palette.brand.grey700,
    backgroundColor: theme.palette.brand.grey50,
  },
}));
