export const GlobalEN = {
  'global.all': 'All',
  'global.back': 'Back',
  'global.backgroundEffect.beta.title': 'Effects are in Beta',
  'global.backgroundEffect.beta.description': 'For best results only turn this on if you are on a powerful computer.',
  'global.backgroundEffect.blur': 'Blur',
  'global.backgroundEffect.image.homeoffice': 'Cozy home',
  'global.backgroundEffect.image.office': 'Professional background',
  'global.backgroundEffect.none': 'No filter',
  'global.backgroundEffect.title': 'Effects',
  'global.beforeUnload.cancel': 'Stay here',
  'global.beforeUnload.confirm': 'Navigate anyway',
  'global.beforeUnload.content': 'You have unsaved changes. Are you sure you want to leave without saving? This will discard all your pending edits.',
  'global.beforeUnload.title': 'Unsaved changes',
  'global.camera': 'Camera',
  'global.camera.configure': 'Configure your camera',
  'global.camera.none': 'No camera',
  'global.camera.smileHint': 'Smile into the camera',
  'global.cancel': 'Cancel',
  'global.capture.start': 'Start capture',
  'global.characterCounter.remaining': '{remaining} von {max} Zeichen übrig',
  'global.close': 'Close',
  'global.cofensterLogo': 'cofenster logo',
  'global.colorPicker.button': 'Open the color-picker',
  'global.colorPicker.placeholder': 'Pick a color',
  'global.continue': 'Continue',
  'global.cookieBanner.accept': 'Okay',
  'global.cookieBanner.decline': 'Decline',
  'global.cookieBanner.text': 'Hi! We use cookies to improve our product. By continuing, we assume that you’re okay with that. See <link>our data privacy policy</link>.',
  'global.copy': 'Copy',
  'global.copyToClipboard': 'Copy to clipboard',
  'global.createdAt': 'Created at',
  'global.dataProtection': 'Data protection',
  'global.delete': 'Delete',
  'global.desktop.select': 'Select desktop',
  'global.dialog.UploadFailureDialog.button.delete': 'Delete file',
  'global.dialog.UploadFailureDialog.button.retry': 'Retry upload',
  'global.dialog.UploadFailureDialog.description': 'This could be related to connectivity issues or a problem on our end. Try to upload the contribution again or delete it to start a new recording if the problem persists.',
  'global.dialog.UploadFailureDialog.previewImageAlt': 'Preview captured image',
  'global.dialog.UploadFailureDialog.title': 'Upload failed',
  'global.download': 'Download',
  'global.drag.hint': 'Move this item (SPACE → UP/DOWN arrow keys → ESC)',
  'global.duration.decrease': 'Decrease duration',
  'global.duration.increase': 'Increase duration',
  'global.duration.label': 'Duration',
  'global.duration.label.total': 'Total duration',
  'global.error.form.pristine': 'The form has no pending change and cannot be submitted.',
  'global.error.form.submitting': 'The form is currently being submitted; patience.',
  'global.error.generic.unknown': 'An unexpected error has occurred',
  'global.error.generic.unknown.retry': 'Retry',
  'global.error.upload.file-invalid-type': 'This file type is not supported',
  'global.error.upload.file-too-large': 'This file is too large (>{maxSize})',
  'global.error.upload.file-too-small': 'This file is too small',
  'global.error.upload.too-many-files': 'There are too many files',
  'global.error.upload.upload-failed': 'The upload of this file has failed',
  'global.errorPage.contactLink': 'Contact us',
  'global.errorPage.content': 'Worry not! We have been notified of the problem. Please refresh the page and retry or contact us if the problem persists.',
  'global.errorPage.headline': 'An unexpected error happened',
  'global.flipCamera': 'Flip camera',
  'global.form.error.client': 'An client error occurred.',
  'global.form.error.network': 'A network error occurred.',
  'global.form.error.server': 'A server error occurred.',
  'global.form.error.unknown': 'An unknwon error occurred.',
  'global.framing.disable': 'Disable framing',
  'global.framing.enable': 'Enable framing',
  'global.framing.toggle': 'Toggle framing',
  'global.fullscreen': 'Fullscreen',
  'global.history.redo': 'Redo',
  'global.history.reset': 'Reset',
  'global.history.undo': 'Undo',
  'global.imprint': 'Imprint',
  'global.loading': 'Loading…',
  'global.loop': 'Loop',
  'global.microphone': 'Microphone',
  'global.microphone.configure': 'Configure your microphone',
  'global.microphone.none': 'No microphone',
  'global.more': 'More',
  'global.mute': 'Mute',
  'global.muted': 'Muted',
  'global.navigation.toggle': 'Toggle navigation',
  'global.next': 'Next',
  'global.noAudioTooltipText': 'Are you trying to talk? Click on the microphone icon to turn it on.',
  'global.okay': 'Okay',
  'global.password.toggle': 'Toggle password visibility',
  'global.paste': 'Paste',
  'global.pasteFromClipboard': 'Paste from clipboard',
  'global.pause': 'Pause',
  'global.pip.dialog.camera': 'Camera',
  'global.pip.dialog.camera.size.big': 'Big',
  'global.pip.dialog.camera.size.medium': 'Medium',
  'global.pip.dialog.camera.size.small': 'Small',
  'global.pip.dialog.close': 'Back to recording',
  'global.pip.dialog.no-camera': 'No camera',
  'global.pip.dialog.position': 'Position',
  'global.pip.dialog.position.bottom-left': 'Bottom left',
  'global.pip.dialog.position.bottom-right': 'Bottom right',
  'global.pip.dialog.position.top-left': 'Top left',
  'global.pip.dialog.position.top-right': 'Top right',
  'global.pip.dialog.size': 'Size',
  'global.pip.dialog.title': 'Record yourself',
  'global.play': 'Play',
  'global.popover.toggle': 'Open menu',
  'global.preview': 'Preview',
  'global.previous': 'Previous',
  'global.private': 'Private',
  'global.processingIndicator': 'Optimizing',
  'global.record': 'Record',
  'global.remotion.error': 'There was a problem loading the preview.',
  'global.renderingProgress.error': 'There may have been an issue with the export. Consider restarting it.',
  'global.renderingProgress.restart': 'Restart export',
  'global.renderingProgress.start': 'Export video',
  'global.renderingProgress.subtitle.0': 'Preparing to export your video…',
  'global.renderingProgress.subtitle.1': 'Retreiving your project theme…',
  'global.renderingProgress.subtitle.2': 'Fetching all your content…',
  'global.renderingProgress.subtitle.3': 'Stitching together your scenes…',
  'global.renderingProgress.subtitle.4': 'Improving audio quality…',
  'global.renderingProgress.subtitle.5': 'Balancing sound volumes…',
  'global.renderingProgress.subtitle.6': 'Adding brand styles and transitions…',
  'global.renderingProgress.subtitle.7': 'Optimizing file size and video quality…',
  'global.renderingProgress.subtitle.8': 'Wrapping things up…',
  'global.renderingProgress.title': 'Exporting your video',
  'global.resume': 'Resume',
  'global.retake': 'Retake',
  'global.save': 'Save',
  'global.saved': 'Changes have been saved',
  'global.search': 'Search',
  'global.search.clear': 'Clear search',
  'global.search.close': 'Close search',
  'global.search.field.info': 'Results will update as you type.',
  'global.search.open': 'Open search',
  'global.search.results.empty': 'No results found for “{search}”.',
  'global.search.results.header': '{count, plural, =0 {No results} one {# result} other {# results}} found for {search}',
  'global.search.results.header.loading': 'Searching for “{search}“',
  'global.search.term': 'Search {term}…',
  'global.seatsLeft.description': 'Get in touch if you want to upgrade your plan',
  'global.seatsLeft.title': '{seatsLeft} Seats left on your plan',
  'global.selectAudioDevice.permissionsDenied': 'Your current browser settings prevent the microphone access.',
  'global.shared.SharingPanel.copyEmbed': 'Copy embed code',
  'global.shared.SharingPanel.copyLink': 'Copy link',
  'global.shared.SharingPanel.email': 'Email',
  'global.shared.SharingPanel.email.body': 'Click the following link to watch: {url}',
  'global.shared.SharingPanel.email.subject': 'I want to share this cofenster video with you: {title}',
  'global.shared.SharingPanel.embed': 'Embed',
  'global.shared.SharingPanel.link': 'Link',
  'global.shared.SharingPanel.link.title': 'I want to share this cofenster video with you.',
  'global.shared.SharingPanel.openPage': 'Open page',
  'global.skipNavigation': 'Skip navigation',
  'global.systemAudioMutedTooltipText': 'Your mic is muted on the system level. Go to your <link>system settings</link> and turn it on.',
  'global.termsAndConditions': 'Terms & Conditions',
  'global.unmute': 'Unmute',
  'global.updatedAt': 'Updated at',
  'global.upload.hint': '{fileFormat} perfect in {dimensions}',
  'global.upload.image.state.error': 'Something went wrong, please try again',
  'global.upload.image.state.processing': 'Almost done, your image will be ready soon!',
  'global.upload.video.state.processing': 'Almost done, your video will be ready soon!',
  'global.user.role.admin': 'Admin',
  'global.user.role.member': 'Member',
  'global.user.status.active': 'Active',
  'global.user.status.deactivated': 'Deactivated',
  'global.user.status.deactivated.tooltip': 'Deactivated users can no longer access cofenster. Admins can reactivate them at any time',
  'global.user.status.pending': 'Pending',
  'global.user.status.pending.tooltip': 'They were invited on {invitedAt} but have not accepted their invite yet',
};
