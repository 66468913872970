import { styled } from '@mui/material';
import type { ComponentProps, FC, PropsWithChildren, SVGProps } from 'react';

import type { BrandColor } from '../../../theming/theme';
import { preventForwardProps } from '../../../utilities/preventForwardProps';

const Container = styled(
  'div',
  preventForwardProps(['size'])
)<{ size: number }>(({ size }) => ({
  width: size,
  height: size,
  display: 'grid',
  gridTemplateRows: '1fr',
  gridTemplateColumns: '1fr',
  alignItems: 'center',
  justifyItems: 'center',
  '& > *': { gridArea: '1 / 1 / 2 / 2' },
}));

const BrandCircle = styled(
  'circle',
  preventForwardProps(['stroke', 'fill'])
)<
  SVGProps<SVGCircleElement> & {
    fill?: BrandColor;
    stroke?: BrandColor;
  }
>(({ theme, stroke = 'transparent', fill = 'transparent' }) => ({
  stroke: theme.palette.brand[stroke],
  fill: theme.palette.brand[fill],
}));

const CircularSegment = styled(
  BrandCircle,
  preventForwardProps(['animate'])
)<ComponentProps<typeof BrandCircle> & { r?: number; value?: number; animate: boolean }>(
  ({ r = 1, value = 0, animate = false }) => {
    const circumference = r * 2 * Math.PI;
    const offset = circumference - value * circumference;
    return {
      strokeDasharray: `${circumference} ${circumference}`,
      strokeDashoffset: offset,
      transformOrigin: '50% 50%',
      transition: 'stroke-dashoffset 100ms',
      transform: !animate ? 'rotate(-90deg)' : undefined,
      animation: animate ? 'rotation 1s linear infinite' : undefined,
      '@keyframes rotation': {
        from: { transform: 'rotate(0turn)' },
        to: { transform: 'rotate(1turn)' },
      },
    };
  }
);

const INDEFINITE_PROGRESS_DEFAULT_VALUE = 0.7;

export type ProgressCircleProps = {
  size: number;
  color?: BrandColor;
  strokeWidth?: number;
  trackColor?: BrandColor;
  backgroundColor?: BrandColor;
  progress?: number | 'indefinite';
};
export const ProgressCircle: FC<PropsWithChildren<ProgressCircleProps>> = ({
  children,
  size,
  color = 'carbon',
  strokeWidth = 0.5,
  backgroundColor = 'transparent',
  trackColor = 'transparent',
  progress = 0,
}) => {
  const cx = size / 2;
  const cy = size / 2;
  const r = Math.max(0, size / 2 - strokeWidth);
  return (
    <Container size={size}>
      <svg viewBox={`0 0 ${size} ${size}`} width={size} height={size}>
        {(backgroundColor !== 'transparent' || trackColor !== 'transparent') && (
          <BrandCircle cx={cx} cy={cy} r={r} stroke={trackColor} strokeWidth={strokeWidth} fill={backgroundColor} />
        )}
        <CircularSegment
          cx={cx}
          cy={cy}
          r={r}
          stroke={color}
          strokeWidth={strokeWidth}
          value={typeof progress === 'number' ? progress : INDEFINITE_PROGRESS_DEFAULT_VALUE}
          animate={progress === 'indefinite'}
        />
      </svg>
      {children}
    </Container>
  );
};
