const BeatriceRegular2 = '/assets/fonts/beatrice/Beatrice-Regular.woff2';
const BeatriceSemibold2 = '/assets/fonts/beatrice/Beatrice-Semibold.woff2';
const WorkSansRegular2 = '/assets/fonts/work-sans/WorkSans-Regular.woff2';
const WorkSansBold2 = '/assets/fonts/work-sans/WorkSans-Bold.woff2';

export const WorkSansRegular = {
  fontFamily: 'WorkSans',
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  src: `url(${WorkSansRegular2}) format('woff2')`,
} as const;

export const WorkSansBold = {
  fontFamily: 'WorkSans',
  fontWeight: 'bold',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  src: `url(${WorkSansBold2}) format('woff2')`,
} as const;

export const BeatriceRegular = {
  fontFamily: 'Beatrice',
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  src: `url(${BeatriceRegular2}) format('woff2')`,
} as const;

export const BeatriceSemibold = {
  fontFamily: 'Beatrice',
  fontWeight: 'bold',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  src: `url(${BeatriceSemibold2}) format('woff2')`,
} as const;
