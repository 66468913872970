import { TextField as MuiTextField, type TextFieldProps as MuiTextFieldProps, styled } from '@mui/material';
import type { FC } from 'react';

import { useI18n } from '../../../services/i18n';

const StyledTextField = styled(MuiTextField)<TextFieldProps>(({ theme }) => ({
  paddingBottom: theme.spacing(3),
  input: {
    height: theme.spacing(2),
    padding: theme.spacing(2),
    transition: '150ms',
  },
  label: {
    fontSize: 16,
    color: theme.palette.brand.grey700,
  },
  legend: {
    fontSize: 12,
  },
  fieldset: {
    transition: '150ms',
  },
  'label, textarea, input': {
    '&:not(.MuiInputLabel-shrink)': {
      fontSize: 16,
      lineHeight: 1.2,
    },
  },
  '& label.Mui-focused': {
    color: theme.palette.brand.blue,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: theme.palette.brand.carbon,
  },
  '&.MuiInput-root': {
    '&:before': {
      borderBottomColor: theme.palette.brand.grey700,
      borderBottomWidth: 2,
    },
  },
  '& .MuiOutlinedInput-root.Mui-focused fieldset': {
    borderColor: theme.palette.brand.blue,
    borderWidth: 1,
  },
}));

export type TextFieldProps = Pick<
  MuiTextFieldProps,
  | 'name'
  | 'value'
  | 'type'
  | 'defaultValue'
  | 'id'
  | 'label'
  | 'placeholder'
  | 'helperText'
  | 'disabled'
  | 'error'
  | 'fullWidth'
  | 'autoFocus'
  | 'autoComplete'
  | 'autoCapitalize'
  | 'multiline'
  | 'rows'
  | 'minRows'
  | 'maxRows'
  | 'onChange'
  | 'onKeyUp'
  | 'onKeyDown'
  | 'onFocus'
  | 'onBlur'
  | 'inputRef'
  | 'variant'
  | 'inputMode'
  | 'InputProps'
  | 'InputLabelProps'
  | 'inputProps'
> & {
  maxLength?: number;
  min?: number;
  max?: number;
  step?: number;
};

export const TextField: FC<TextFieldProps> = ({
  label,
  helperText,
  placeholder,
  inputProps,
  maxLength,
  min,
  max,
  step,
  ...rest
}) => {
  const { translatable } = useI18n();

  return (
    <StyledTextField
      {...rest}
      label={translatable(label)}
      placeholder={translatable(placeholder)}
      helperText={translatable(helperText)}
      inputProps={{
        maxLength,
        min,
        max,
        step,
        ...inputProps,
      }}
    />
  );
};
