export const VIDEO_FORMATS = Object.seal({
  Horizontal: {
    width: 1920,
    height: 1080,
    aspectRatio: 1920 / 1080,
  },
  Vertical: {
    width: 1080,
    height: 1920,
    aspectRatio: 1080 / 1920,
  },
  Square: {
    width: 1080,
    height: 1080,
    aspectRatio: 1080 / 1080,
  },
  SocialMedia: {
    width: 1080,
    height: 1350,
    aspectRatio: 1080 / 1350,
  },
} as const);

export type VideoFormat = keyof typeof VIDEO_FORMATS;
export type VideoFit = 'Fit' | 'Crop';
export type VideoCaptureSource = 'NativeApp' | 'Screen' | 'Webcam';
