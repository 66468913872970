import { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import type { TrackDetails } from '../../services/tracking';

export type TrackEvent = (payload: unknown) => Promise<void>;
export type Enhancer = (context: Record<string, unknown>) => void;

const STAGE = process.env.STAGE as string;

export const useAutomaticTracking = (trackEvent: TrackEvent, enhancer?: Enhancer, dryRun = false) => {
  const params = useParams();
  const location = useLocation();
  const page = location.pathname + location.search;

  const context = useMemo(() => {
    const context: Record<string, unknown> = { page };

    if (typeof enhancer === 'function') {
      enhancer(context);
    }

    if (Object.keys(params).length > 0) {
      context.params = params;
    }

    return context;
  }, [params, page, enhancer]);

  const track = useCallback(
    (eventName: string, payload: TrackDetails) => {
      const event = {
        event: eventName,
        details: { context, payload },
      };

      if (STAGE !== 'production') console.info(event);
      if (!dryRun) trackEvent(event);
    },
    [context, trackEvent, dryRun]
  );

  useEffect(() => {
    const onClick = (event: MouseEvent) => {
      const actualTarget = event.target as HTMLElement;
      if (!actualTarget) return;

      const target = actualTarget.closest('a, button') as HTMLElement;
      if (!target) return;

      if (target.nodeName === 'A') {
        track('ui.link.click', {
          text: target.innerText,
          href: target.getAttribute('href'),
          target: target.getAttribute('target') ?? undefined,
        });
      }

      if (target.nodeName === 'BUTTON') {
        track('ui.button.click', {
          text: target.innerText,
          type: target.getAttribute('type') ?? (target.closest('form') ? 'submit' : 'button'),
          disabled: target.hasAttribute('disabled') || target.getAttribute('aria-disabled') === 'true',
        });
      }
    };

    const onMouseDown = (event: MouseEvent) => {
      const actualTarget = event.target as HTMLElement;
      if (!actualTarget) return;

      const target = actualTarget.closest('[role="combobox"], [role="option"]') as HTMLElement;
      if (!target) return;

      if (target.getAttribute('role') === 'combobox') {
        const input = target.nextSibling as HTMLInputElement;
        if (!input || input.nodeName !== 'INPUT') return;
        const label = target.parentNode?.previousSibling as HTMLLabelElement;
        if (!label || label.nodeName !== 'LABEL') return;

        track('ui.combobox.click', {
          id: target.id,
          name: input.getAttribute('name'),
          value: input.value,
          label: label.innerText,
        });
      }

      if (target.getAttribute('role') === 'option') {
        const parent = target.closest('[role="listbox"]');
        if (!parent) return;

        track('ui.combobox.pick', {
          id: parent.getAttribute('aria-labelledby')?.split('-')[0],
          value: target.getAttribute('data-value'),
          label: target.innerText,
        });
      }
    };

    const onChange = (event: Event) => {
      const actualTarget = event.target as HTMLElement;
      if (!actualTarget) return;

      const target = actualTarget.closest('input[type="file"]') as HTMLElement;
      if (!target) return;

      const label = target.closest('label, [role="presentation"][tabindex="0"]') as HTMLLabelElement;
      if (!label) return;

      track('ui.file.pick', {
        name: target.getAttribute('name'),
        id: target.id,
        label: label.innerText,
        accept: target.getAttribute('accept'),
      });
    };

    const onInput = (event: Event) => {
      const actualTarget = event.target as HTMLElement;
      if (!actualTarget) return;

      const target = actualTarget.closest('input[type="checkbox"]') as HTMLElement;
      if (!target) return;

      const label = target.closest('label') as HTMLLabelElement;
      if (!label) return;

      track('ui.checkbox.change', {
        name: target.getAttribute('name'),
        id: target.id,
        label: label.innerText,
      });
    };

    const onFocusIn = (event: Event) => {
      const actualTarget = event.target as HTMLElement;
      if (!actualTarget) return;

      const target = actualTarget.closest('input:not([type="checkbox"])') as HTMLElement;
      if (!target) return;

      const type = target.getAttribute('type');
      const label =
        target.getAttribute('aria-label') ??
        // For `FormTextField` — which are most of our text fields — the label
        // is the previous sibling of the parent node. The DOM looks like this:
        //  label + div > input
        (target.parentNode?.previousSibling as HTMLElement)?.innerText;

      track('ui.input.focus', {
        type,
        name: target.getAttribute('name'),
        id: target.id,
        label,
      });
    };

    document.addEventListener('mousedown', onMouseDown);
    document.addEventListener('click', onClick);
    document.addEventListener('input', onInput);
    document.addEventListener('change', onChange);
    document.addEventListener('focusin', onFocusIn);

    return () => {
      document.removeEventListener('mousedown', onMouseDown);
      document.removeEventListener('click', onClick);
      document.removeEventListener('input', onInput);
      document.removeEventListener('change', onChange);
      document.removeEventListener('focusin', onFocusIn);
    };
  }, [track]);
};
