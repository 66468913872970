import { MenuItem, type MenuItemProps, styled } from '@mui/material';
import type { FC } from 'react';

import { type I18nParams, useI18n } from '../../../services/i18n';

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  margin: theme.spacing(1),
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  '&.Mui-selected, &.Mui-selected:hover': {
    backgroundColor: theme.palette.brand.blue100,
  },
  '&:hover': {
    backgroundColor: theme.palette.brand.grey50,
  },
}));

export type SelectOptionProps = MenuItemProps & {
  i18nParams?: I18nParams;
};

export const SelectOption: FC<SelectOptionProps> = ({ children, i18nParams, ...rest }) => {
  const { translatable } = useI18n();

  return <StyledMenuItem {...rest}>{translatable(children, i18nParams)}</StyledMenuItem>;
};
