import { useFormikContext } from 'formik';
import { type FC, type MouseEventHandler, useCallback } from 'react';

import { useSnackbars } from '../../../contexts/snackbars/useSnackbars';
import { useViewportWidth } from '../../../hooks/dom/useViewportWidth';
import { Button, type ButtonProps } from '../../controls/Button';

type Props = ButtonProps & {
  autoDisable?: boolean;
};

export const FormSubmitButton: FC<Props> = ({ autoDisable = false, onClick, ...rest }) => {
  const viewportWidth = useViewportWidth();
  const { isSubmitting, dirty } = useFormikContext();
  const { openSnackbar } = useSnackbars();

  const isDisabled = isSubmitting || (autoDisable && !dirty);
  const className = [rest.className, isDisabled && 'Mui-disabled'].filter(Boolean).join(' ');

  // Forms should *always* have a submit button, and that submit button should
  // *never* be disabled. Disabled fields and buttons are not discoverable by
  // assistive technologies as they are essentially removed from the DOM/AOM.
  // While we do prevent clicks on the disabled button via the `Mui-disabled`
  // class (`pointer-events: none`), it can still be focused and interacted with
  // via keyboard. This click handler prevent the submission of the form if the
  // submit button is disabled, and display a snackbar to give feedback.
  // See: https://www.notion.so/cofenster/Forms-f0c7e1df381f4d7587439e2c60852bfb?pvs=4#900f75592367463289019904266f120f
  const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      if (!isDisabled) return onClick?.(event);

      event.preventDefault();

      // Make the distinction between explicitly interacting with the submit
      // button (focusing it and activating it) with simply pressing ENTER
      // anywhere else in the form, in which case we do not want to display
      // the snackbar.
      if (event.target === document.activeElement) {
        openSnackbar({
          children: isSubmitting ? 'i18n.global.error.form.submitting' : 'i18n.global.error.form.pristine',
        });
      }
    },
    [isSubmitting, isDisabled, openSnackbar, onClick]
  );

  return (
    <Button
      loading={isSubmitting}
      fullWidth={viewportWidth < 600}
      aria-disabled={isDisabled}
      className={className}
      type="submit"
      onClick={handleClick}
      data-testid="form-submit-button"
      {...rest}
    />
  );
};
