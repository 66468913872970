import { styled } from '@mui/material';

import { BaseButton } from './BaseButton';

export const PrimaryButton = styled(BaseButton)(({ theme }) => ({
  '&:hover, &:focus': {
    backgroundColor: theme.palette.primary.dark,
  },

  '&.Mui-disabled': {
    cursor: 'not-allowed',
    color: theme.palette.brand.grey700,
    backgroundColor: theme.palette.brand.grey50,
  },
}));
