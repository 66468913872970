// See: https://github.com/react-dropzone/react-dropzone/blob/c36ab5bd8b8fd74e2074290d80e3ecb93d26b014/src/utils/index.js#L292
function isMIMEType(value: string) {
  return ['audio/*', 'video/*', 'image/*', 'text/*'].includes(value) || /\w+\/[-+.\w]+/g.test(value);
}

// See: https://github.com/react-dropzone/react-dropzone/blob/c36ab5bd8b8fd74e2074290d80e3ecb93d26b014/src/utils/index.js#L306
function isExt(value: string) {
  return /^.*\.\w+$/.test(value);
}

// See: https://github.com/react-dropzone/react-dropzone/blob/c36ab5bd8b8fd74e2074290d80e3ecb93d26b014/src/utils/index.js#L243
function getAcceptAttr(type: keyof typeof MimeTypes) {
  return (
    Object.entries(MimeTypes[type])
      .reduce((acc, [mimeType, ext]) => acc.concat([mimeType, ...ext]), [] as string[])
      // Silently discard invalid entries as pickerOptionsFromAccept warns about these
      .filter((value) => isMIMEType(value as string) || isExt(value as string))
      .join(',')
  );
}

const VideoMimeTypes = {
  'video/x-m4v': ['.m4v'],
  'video/m4v': ['.m4v'],
  'video/mp4': ['.mp4', '.m4v'],
  'video/quicktime': ['.mov'],
  'video/webm': ['.webm'],
};

const AudioMimeTypes = {
  'audio/m4a': ['.m4a'],
  'audio/mpeg': ['.mp3'],
  'audio/ogg': ['.oga', '.ogg'],
  'audio/wav': ['.wav'],
};

const FontMimeTypes = {
  'font/ttf': ['.ttf'],
  'font/otf': ['.otf'],
  'font/woff': ['.woff'],
  'font/woff2': ['.woff2'],
};

const ImageMimeTypes = {
  'image/png': ['.png'],
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/svg+xml': ['.svg'],
  'image/webp': ['.webp'],
  'image/avif': ['.avif'],
};

const DocumentMimeTypes = {
  'application/pdf': ['.pdf'],
};

const SubtitleMimeTypes = {
  'text/srt': ['.srt'],
};

export const MimeTypes = {
  image: ImageMimeTypes,
  video: VideoMimeTypes,
  font: FontMimeTypes,
  audio: AudioMimeTypes,
  document: DocumentMimeTypes,
  subtitle: SubtitleMimeTypes,
};

export const AcceptTypes = {
  image: getAcceptAttr('image'),
  video: getAcceptAttr('video'),
  font: getAcceptAttr('font'),
  audio: getAcceptAttr('audio'),
};
