import { type ComponentType, type MouseEventHandler, forwardRef, useCallback } from 'react';

import type { EventTrack } from './useSegment';
import { useTracking } from './useTracking';

export type WithClickTrackingProps = {
  track?: EventTrack;
};

export const withClickTracking = <OriginalProps extends { onClick?: MouseEventHandler<HTMLElement> }>(
  Component: ComponentType<OriginalProps>
) => {
  const ComponentWithClickTracking = forwardRef<HTMLElement, OriginalProps & WithClickTrackingProps>(
    function ComponentWithClickTracking({ track, onClick, ...rest }, ref) {
      const tracking = useTracking();
      const trackedOnClick: MouseEventHandler<HTMLElement> = useCallback(
        (event) => {
          if (track) tracking.trackEvent(track);
          onClick?.(event);
        },
        [onClick, tracking, track]
      );

      return <Component {...(rest as unknown as OriginalProps)} onClick={trackedOnClick} ref={ref} />;
    }
  );

  return ComponentWithClickTracking;
};
