import { useCallback, useEffect } from 'react';

const STAGE = process.env.STAGE;
const FILE_CACHE_URL = `https://cache.cofenster.com/__${STAGE}/`;
const MAX_AGE = 5 * 24 * 60 * 60 * 1000; // 5 days
const EVENT_NAME = 'cofenster.cache';

type FileMetadata = Record<string, string | number | boolean | null | undefined>;
export type FileWithMetadata = File & { metadata?: FileMetadata };

export const useFileCache = (type: string) => {
  const urlHelper = useCallback((filename: string) => `${FILE_CACHE_URL}${type}/${filename}`, [type]);

  const getCachedFile = useCallback(
    async (filename: string): Promise<FileWithMetadata | null> => {
      const cache = await window.caches.open(type);

      const response = await cache.match(urlHelper(filename));
      if (!response) return null;

      const metadata = JSON.parse(
        response.headers.get('X-Metadata') ?? JSON.stringify({ source: 'cache', metadata: 'failed to find metadata' })
      ) as FileMetadata;

      return Object.assign(new File([await response.blob()], filename), {
        metadata,
      });
    },
    [type, urlHelper]
  );

  const setCachedFile = useCallback(
    async (filename: string, blob: Blob, metadata?: Record<string, string | number | boolean>) => {
      const cache = await window.caches.open(type);

      await cache.put(
        urlHelper(filename),
        new Response(blob.stream(), {
          headers: {
            'Content-Type': blob.type,
            'Content-Length': String(blob.size),
            'Last-Modified': new Date().toISOString(),
            'X-Metadata': JSON.stringify(metadata),
          },
        })
      );
      window.dispatchEvent(new Event(EVENT_NAME));
    },
    [type, urlHelper]
  );

  const renameCachedFile = useCallback(
    async (filename: string, newName: string, maxAge = MAX_AGE) => {
      const cache = await window.caches.open(type);
      const response = await cache.match(urlHelper(filename));
      if (response) {
        await cache.delete(urlHelper(filename));
        await cache.put(
          urlHelper(newName),
          new Response(response.body, {
            headers: {
              ...response.headers,
              'X-Max-Age': String(Date.now() + maxAge),
            },
          })
        );
        window.dispatchEvent(new Event(EVENT_NAME));
      }
    },
    [type, urlHelper]
  );

  const removeCachedFile = useCallback(
    async (filename: string) => {
      const cache = await window.caches.open(type);
      await cache.delete(urlHelper(filename));

      window.dispatchEvent(new Event(EVENT_NAME));
    },
    [type, urlHelper]
  );

  const clearCache = useCallback(async () => {
    const cache = await window.caches.open(type);

    const cacheKeys = await cache.keys();
    for (const item of cacheKeys) {
      const response = await cache.match(item.url);
      const maxAge = response?.headers.get('X-Max-Age');
      if (maxAge) {
        const expired = Date.now() > Number(maxAge);
        if (expired) {
          await cache.delete(item.url);
          window.dispatchEvent(new Event(EVENT_NAME));
        }
      }

      const lastModified = response?.headers.get('Last-Modified');
      if (lastModified) {
        const expired = new Date(lastModified) < new Date(Date.now() - MAX_AGE);
        if (expired) {
          await cache.delete(item.url);
          window.dispatchEvent(new Event(EVENT_NAME));
        }
      }
    }
  }, [type]);

  useEffect(() => {
    clearCache();
  }, [clearCache]);

  return { getCachedFile, setCachedFile, removeCachedFile, renameCachedFile, EVENT_NAME };
};
