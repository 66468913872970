import { useMemo } from 'react';

import {
  type ContributionRequestListByActorQueryResult,
  useContributionRequestListByActorQuery,
} from '../../generated';

export type ContributionRequestListByActor = NonNullable<
  NonNullable<ContributionRequestListByActorQueryResult['data']>['contributionRequestListByActor']
>;

export type FeatureFlag = ContributionRequestListByActor['featureFlags'][number];
export type ContributionRequestList = ContributionRequestListByActor['contributionRequestList'];

export const useContributionRequestListByActor = () => {
  const { loading, error, data, startPolling, stopPolling } = useContributionRequestListByActorQuery({
    fetchPolicy: 'cache-and-network',
    pollInterval: 10000,
    skipPollAttempt: () => document.hidden,
  });
  const contributionRequestListByActor = data?.contributionRequestListByActor || undefined;
  return useMemo(
    () => ({ contributionRequestListByActor, loading, error, startPolling, stopPolling }),
    [contributionRequestListByActor, loading, error, startPolling, stopPolling]
  );
};
