import { type FC, useCallback } from 'react';

import { Snackbar, type SnackbarProps } from '../../components/feedback/Snackbar';

import { useSnackbars } from './useSnackbars';

export const ManagedSnackbar: FC<{ id: string } & Partial<SnackbarProps>> = ({ id, ...rest }) => {
  const { closeSnackbar } = useSnackbars();
  const onClose = useCallback(() => closeSnackbar(id), [closeSnackbar, id]);

  return <Snackbar key={id} {...rest} open={true} onClose={onClose} />;
};
