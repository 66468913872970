import { styled } from '@mui/material';
import { type ComponentProps, type ElementType, type PropsWithChildren, forwardRef } from 'react';

import { type I18nParams, Translatable } from '../../../services/i18n';

// Ref: https://kittygiraudel.com/snippets/visually-hidden-component/
export const HiddenNode = styled('span')(() => ({
  border: '0 !important',
  clip: 'rect(1px, 1px, 1px, 1px) !important',
  height: '1px !important',
  overflow: 'hidden !important',
  paddingBottom: '0 !important',
  paddingLeft: '0 !important',
  paddingRight: '0 !important',
  paddingTop: '0 !important',
  position: 'absolute',
  whiteSpace: 'nowrap',
  width: '1px !important',
}));

export type VisuallyHiddenProps = ComponentProps<'span'> & {
  as?: ElementType & string;
  i18nParams?: I18nParams;
  htmlFor?: string;
};

export const VisuallyHidden = forwardRef<HTMLElement, PropsWithChildren<VisuallyHiddenProps>>(function VisuallyHidden(
  { children, as = 'span', i18nParams, ...rest },
  ref
) {
  return (
    <HiddenNode as={as} {...rest} ref={ref}>
      <Translatable i18nParams={i18nParams}>{children}</Translatable>
    </HiddenNode>
  );
});
