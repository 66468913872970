import { formatDistanceToNow } from 'date-fns';
import { de, enUS } from 'date-fns/locale';
import { useCallback } from 'react';

import { useI18n } from '../../services/i18n';

export const useFormatTimeDistance = () => {
  const { locale } = useI18n();

  return useCallback(
    (date: Date, priorityLocale?: typeof locale) =>
      formatDistanceToNow(date, {
        addSuffix: true,
        locale: (priorityLocale ?? locale) === 'DE' ? de : enUS,
      }),
    [locale]
  );
};
