import { createContext } from 'react';

import type { I18nParams, SupportedLocale, Translations } from './types';

export type I18nContext<T extends Translations> = {
  locale: SupportedLocale<T>;
  setLocale: (locale: SupportedLocale<T> | string) => void;
  translate: (key: string, values?: I18nParams) => string;
  translatable: (key: unknown | undefined, values?: I18nParams) => string | undefined;
  languageNames: Intl.DisplayNames;
};

const noop = () => '';

export const I18nContext = createContext<I18nContext<Translations>>({
  locale: 'EN',
  setLocale: noop,
  translate: noop,
  translatable: noop,
  languageNames: new Intl.DisplayNames(['EN'], { type: 'language' }),
});
