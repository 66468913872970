import type { FC } from 'react';

import { SvgIcon, type SvgIconProps } from '../SvgIcon';

export const AddTransitionsIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5414 13.3682L6.01038 9.88022C5.59668 9.56175 5.82189 8.9 6.34398 8.9L15.406 8.9C15.9281 8.9 16.1533 9.56175 15.7396 9.88022L11.2086 13.3682C11.0119 13.5196 10.738 13.5196 10.5414 13.3682ZM13.1563 10.2125L10.875 11.9687L8.59369 10.2125H13.1563Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2086 13.8818C11.012 13.7304 10.738 13.7304 10.5414 13.8818L6.01039 17.3698C5.59669 17.6882 5.82189 18.35 6.34398 18.35H15.406C15.9281 18.35 16.1533 17.6882 15.7396 17.3698L11.2086 13.8818ZM10.875 15.2813L8.59369 17.0375H13.1563L10.875 15.2813Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.66595 5.75C5.99898 4.45608 7.17355 3.5 8.57143 3.5H18C19.6569 3.5 21 4.84315 21 6.5V15.9286C21 17.3265 20.0439 18.501 18.75 18.8341V18.875C18.75 20.3247 17.5747 21.5 16.125 21.5H5.625C4.17525 21.5 3 20.3247 3 18.875V8.375C3 6.92525 4.17525 5.75 5.625 5.75H5.66595ZM8.57143 5H18C18.8284 5 19.5 5.67157 19.5 6.5V15.9286C19.5 16.4838 19.1984 16.9685 18.75 17.2279V8.375C18.75 6.92525 17.5747 5.75 16.125 5.75H7.27211C7.53146 5.30165 8.01622 5 8.57143 5ZM5.625 7.0625H16.125C16.8499 7.0625 17.4375 7.65013 17.4375 8.375V18.875C17.4375 19.5999 16.8499 20.1875 16.125 20.1875H5.625C4.90013 20.1875 4.3125 19.5999 4.3125 18.875V8.375C4.3125 7.65013 4.90013 7.0625 5.625 7.0625Z"
      fill="currentColor"
    />
  </SvgIcon>
);
