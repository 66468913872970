import type { FC } from 'react';

import { AspectRatioBox, Dialog, DialogContent, NativeVideoPlayer } from '@cofenster/web-components';

import type { VideoAsset } from '../../../api/hooks/videoAsset/usePollingVideoAsset';

export type PreviewBriefingVideoDialogProps = {
  isOpen: boolean;
  closeDialog: () => unknown;
  video: VideoAsset;
  setElement: (element: HTMLVideoElement | null) => unknown;
  onClose: VoidFunction;
};

export const PreviewBriefingVideoDialog: FC<PreviewBriefingVideoDialogProps> = ({
  isOpen,
  closeDialog,
  video,
  setElement,
  onClose,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        closeDialog();
        onClose();
      }}
      title="i18n.ContributionRequestListPage.PreviewBriefingVideoDialog.title"
    >
      <DialogContent>
        <AspectRatioBox ratio={1}>
          <NativeVideoPlayer ref={setElement} src={video?.videoUrl ?? undefined} actions={['FULLSCREEN']} autoPlay />
        </AspectRatioBox>
      </DialogContent>
    </Dialog>
  );
};
