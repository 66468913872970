import './types';

import { brandColors } from './brandColors';
import { toRgba } from './toRgba';

export { brandColors } from './brandColors';
export type { BrandColor } from './brandColors';

export const paletteThemeOptions = {
  toRgba,
  brand: brandColors,
  primary: {
    main: brandColors.blue,
    light: brandColors.blue400,
    dark: brandColors.blueDark,
    contrastText: brandColors.white,
  },
  secondary: {
    main: brandColors.carbon,
    light: brandColors.carbon,
    dark: brandColors.carbon,
    contrastText: brandColors.white,
  },
  grey: {
    50: '#dadada',
  },
};
