import { SvgIcon as MuiSvgIcon, type SvgIconProps as MuiSvgIconProps, styled } from '@mui/material';

import { preventForwardProps } from '../../../utilities/preventForwardProps';
import type { IconColor } from '../icons/Icon';

type StyledIconProps = Omit<SvgProps, 'color' | 'size'> & {
  iconSize: SvgProps['size'];
  iconColor: NonNullable<SvgProps['color']>;
};

const StyledSvg = styled(
  MuiSvgIcon,
  preventForwardProps(['iconSize', 'iconColor'])
)<StyledIconProps>(({ theme, iconSize, iconColor, onClick }) => ({
  cursor: onClick ? 'pointer' : undefined,
  fontSize: iconSize,
  width: 'auto',
  color: iconColor === 'inherit' || iconColor === 'currentColor' ? iconColor : theme.palette.brand[iconColor],
}));

export type SvgProps = Omit<
  MuiSvgIconProps,
  'color' | 'size' | 'viewBox' | 'fontSize' | 'sx' | 'htmlColor' | 'shapeRendering' | 'classes'
> & {
  viewBox: NonNullable<MuiSvgIconProps['viewBox']>;
  size: number;
  color?: IconColor;
};

export const Svg = ({ color = 'inherit', size, ...rest }: SvgProps) => {
  return <StyledSvg iconSize={size} iconColor={color} {...rest} />;
};
