import { useI18n } from '@cofenster/web-components';
import { useCallback } from 'react';

export const useGetErrorMessage = () => {
  const { translate } = useI18n();
  return useCallback(
    (error: unknown) => {
      if (typeof error === 'object' && error !== null) {
        if ('networkError' in error) return translate('global.form.error.network');
        if ('graphQLErrors' in error) return translate('global.form.error.server');
        if ('clientErrors' in error) return translate('global.form.error.client');
      }

      if (error instanceof Error) return error.message;

      return translate('global.form.error.unknown');
    },
    [translate]
  );
};
