import { Typography as MuiTypography, type TypographyProps as MuiTypographyProps, styled } from '@mui/material';
import { type ElementType, type PropsWithChildren, forwardRef } from 'react';

import { Translatable, type TranslatableProps } from '../../../services/i18n';
import type { BrandColor, TypographyVariants } from '../../../theming/theme';
import { preventForwardProps } from '../../../utilities/preventForwardProps';

export type TypographyColors = BrandColor | 'currentColor' | 'inherit';

const StyledTypography = styled(
  MuiTypography,
  preventForwardProps(['underline', 'tabularNums'])
)<Pick<TypographyProps, 'underline' | 'paragraph' | 'component' | 'tabularNums'>>(
  ({ theme, underline, paragraph, tabularNums }) => ({
    textDecoration: underline ? 'underline' : undefined,
    marginBottom: paragraph ? theme.spacing(1) : undefined,
    fontVariantNumeric: tabularNums ? 'tabular-nums' : undefined,
  })
);

export type TypographyProps = {
  variant?: TypographyVariants;
  color?: TypographyColors;
  underline?: boolean;
  tabularNums?: boolean;
  htmlFor?: string;
} & Pick<MuiTypographyProps, 'children' | 'ref' | 'className' | 'paragraph' | 'align' | 'id'> &
  Pick<MuiTypographyProps<ElementType, { component?: ElementType }>, 'component'> &
  TranslatableProps;

export const Typography = forwardRef<HTMLElement, PropsWithChildren<TypographyProps>>(function Typography(
  { component = 'span', variant = 'm', color = 'inherit', children, i18nParams, ...rest },
  ref
) {
  return (
    <StyledTypography
      ref={ref}
      component={component}
      variant={variant}
      color={color === 'inherit' ? color : `brand.${color}`}
      {...rest}
    >
      <Translatable i18nParams={i18nParams}>{children}</Translatable>
    </StyledTypography>
  );
});
