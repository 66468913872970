import { type FC, type ReactNode, useState, useTransition } from 'react';

import { Icon, type IconSize, ProgressCircle, SpinningIcon, useInterval } from '@cofenster/web-components';

const UndefinedProgress: FC<{ children: (progress: number) => ReactNode }> = ({ children }) => {
  const [progress, setProgress] = useState(0);
  const [_, startTransition] = useTransition();
  useInterval(() => startTransition(() => setProgress((p) => p + 0.1)), 1000);
  return <>{children(progress)}</>;
};

export const AssetOrUploadStatus: FC<{
  status?: 'Ready' | 'Waiting' | 'Processing' | 'Error';
  upload?: { progress: number; error?: string };
  iconSize?: IconSize;
  iconContainerSize?: IconSize;
}> = ({ status, upload, iconSize = 's', iconContainerSize = 'l' }) => {
  const progressCircleSize =
    typeof iconContainerSize === 'number'
      ? iconContainerSize
      : { xxl: 64, xl: 40, l: 32, m: 24, ms: 20, s: 16, xs: 12 }[iconContainerSize];

  if (upload) {
    if (upload.error) {
      return (
        <Icon
          type="CloudErrorIcon"
          color="negativeLight"
          size={iconSize}
          background={{ size: iconContainerSize, color: 'negativeDark' }}
        />
      );
    }

    return (
      <ProgressCircle
        progress={upload.progress}
        size={progressCircleSize}
        strokeWidth={2}
        color="blue"
        trackColor="linen"
      >
        <Icon type="CloudUploadIcon" color="carbon" size={iconSize} />
      </ProgressCircle>
    );
  }

  if (status) {
    switch (status) {
      case 'Ready':
        return null;
      case 'Waiting':
      case 'Processing':
        return (
          <UndefinedProgress>
            {(progress) => (
              <ProgressCircle
                progress={progress}
                size={progressCircleSize}
                strokeWidth={2}
                color="blue"
                trackColor="linen"
              >
                <SpinningIcon type="GearIcon" color="carbon" size={iconSize} />
              </ProgressCircle>
            )}
          </UndefinedProgress>
        );
      case 'Error':
        return (
          <Icon
            type="WarningCircleIcon"
            color="negativeLight"
            size={iconSize}
            background={{ size: iconContainerSize, color: 'negativeDark' }}
          />
        );
      default:
        return null;
    }
  }

  return null;
};
