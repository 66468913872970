import { Button, FilePicker, GridContainer, GridItem, Icon } from '@cofenster/web-components';
import { type FC, useCallback } from 'react';

import { useCaptureAssetCandidateFileContext } from '../../../../../../context/captureAssetFile';
import { BottomFixedContainer } from '../../../../../controls';

type ContributionRequestTitleAndInstructionsProps = {
  goToNextStep: () => void;
  fileType: 'image' | 'video';
};

export const ContributionRequestTitleAndInstructions: FC<ContributionRequestTitleAndInstructionsProps> = ({
  goToNextStep,
  fileType,
}) => {
  const { onCaptureAssetReadyForReview } = useCaptureAssetCandidateFileContext();

  const onFile = useCallback(
    (file: File) => {
      onCaptureAssetReadyForReview(
        {
          blob: file,
          url: URL.createObjectURL(file),
        },
        { uploadSource: 'mobile-library' }
      );
    },
    [onCaptureAssetReadyForReview]
  );

  return (
    <BottomFixedContainer>
      <GridContainer spacing={1}>
        <GridItem xs={6}>
          <FilePicker
            id="scene-asset-upload"
            onFiles={(files) => files[0] && onFile(files[0])}
            video={fileType === 'video'}
            image={fileType === 'image'}
            variant="secondary"
            data-testid="upload-button"
            fullWidth
            startIcon={<Icon size="s" type="UploadIcon" />}
          >
            {fileType === 'video'
              ? 'i18n.ScenePage.InitialScreen.uploadButtonVideo'
              : 'i18n.ScenePage.InitialScreen.uploadButtonImage'}
          </FilePicker>
        </GridItem>
        <GridItem xs={6}>
          <Button startIcon={<Icon type="RecordIcon" size="s" />} fullWidth onClick={goToNextStep}>
            i18n.global.record
          </Button>
        </GridItem>
      </GridContainer>
    </BottomFixedContainer>
  );
};
