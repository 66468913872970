import type { FC } from 'react';

import { styled } from '@cofenster/web-components';

type QrcodeProps = {
  alt?: string;
  src: string;
};

const StyledImg = styled('img')(() => ({
  width: '100%',
}));

export const Qrcode: FC<QrcodeProps> = ({ alt = 'QR Code', src }) => {
  return <StyledImg src={src} alt={alt} />;
};
