import './types';

export type { CSSObject } from '@mui/material/styles';

const textVariants = {
  xl: {
    fontFamily: 'WorkSans',
    fontSize: 18,
    lineHeight: 25 / 18,
  },
  l: {
    fontFamily: 'WorkSans',
    fontSize: 16,
    lineHeight: 24 / 16,
  },
  m: {
    fontFamily: 'WorkSans',
    fontSize: 14,
    lineHeight: 20 / 14,
  },
  s: {
    fontFamily: 'WorkSans',
    fontSize: 12,
    lineHeight: 18 / 12,
    letterSpacing: 0.1,
  },
  xs: {
    fontFamily: 'WorkSans',
    fontSize: 10,
    lineHeight: 18 / 10,
    letterSpacing: 0.1,
  },
} as const;

const headlineVariants = {
  h1: {
    fontFamily: 'Beatrice',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 40,
    lineHeight: 58 / 40,

    '@media (max-width: 600px)': {
      fontSize: 32,
    },
  },
  h2: {
    fontFamily: 'Beatrice',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 30,
    lineHeight: 42 / 30,

    '@media (max-width: 600px)': {
      fontSize: 24,
    },
  },
  h3: {
    fontFamily: 'Beatrice',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 24,
    lineHeight: 34 / 24,

    '@media (max-width: 600px)': {
      fontSize: 20,
    },
  },
  h4: {
    fontFamily: 'Beatrice',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 18,
    lineHeight: 26 / 18,

    '@media (max-width: 600px)': {
      fontSize: 16,
    },
  },
  h5: {
    fontFamily: 'Beatrice',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: 22 / 16,
  },
  h6: {
    fontFamily: 'Beatrice',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 20 / 14,
  },
} as const;

const specialVariants = {
  'button-m': {
    fontFamily: 'Beatrice',
    fontSize: 14,
    lineHeight: 16 / 14,
    textTransform: 'none',
  },
  navigation: {
    fontFamily: 'Beatrice',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 19 / 14,
  },
} as const;

const muiVariants = {
  body1: {
    fontFamily: 'WorkSans',
    fontSize: 13,
    lineHeight: 18 / 13,
  },
  body2: {
    fontFamily: 'WorkSans',
    fontSize: 13,
    lineHeight: 18 / 13,
  },
  subtitle1: undefined,
  subtitle2: undefined,
  caption: undefined,
  overline: undefined,
} as const;

export type TextVariants = keyof typeof textVariants;
export type HeadlineVariants = keyof typeof headlineVariants;
export type SpecialVariants = keyof typeof specialVariants;

export type TypographyVariants = TextVariants | HeadlineVariants | SpecialVariants;

export const typographyThemeOptions = {
  fontFamily: 'WorkSans, Arial',
  ...textVariants,
  ...headlineVariants,
  ...specialVariants,
  ...muiVariants,
};
