import { useI18n } from '../../services/i18n';

const URLS = {
  HOME: {
    EN: 'https://www.cofenster.com',
    DE: 'https://de.cofenster.com',
  },
  IMPRINT: {
    EN: 'https://www.cofenster.com/imprint',
    DE: 'https://de.cofenster.com/imprint',
  },
  TERMS_OF_USE: {
    EN: 'https://www.cofenster.com/terms-of-use',
    DE: 'https://de.cofenster.com/terms-of-use',
  },
  PRIVACY_POLICY: {
    EN: 'https://www.cofenster.com/privacy-policy',
    DE: 'https://de.cofenster.com/privacy-policy',
  },
  KNOWLEDGE_BASE: {
    EN: 'https://help.cofenster.com/en',
    DE: 'https://help.cofenster.com/de',
  },
  CONSENT_INFO: {
    EN: 'https://help.cofenster.com/en/articles/78212-collect-data-consent',
    DE: 'https://help.cofenster.com/de/articles/78212-einverstandniserklarung-einholen',
  },
  LEGACY_MUSIC_INFO: {
    EN: 'https://help.cofenster.com/en/articles/160784-update-to-our-music-library-all-you-need-to-know',
    DE: 'https://help.cofenster.com/de/articles/160784-update-zu-unserer-musikbibliothek-was-du-wissen-musst',
  },
  PROJECT_CREATION_INFO: {
    EN: 'https://help.cofenster.com/en/articles/27111-create-a-video-project',
    DE: 'https://help.cofenster.com/de/articles/27111-videoprojekt-anlegen',
  },
  WORKSPACES_INFO: {
    EN: 'https://help.cofenster.com/en/articles/27110-workspaces-projects-and-folders',
    DE: 'https://help.cofenster.com/de/articles/27110-arbeitsbereiche-projekte-ordner',
  },
  ROLES_INFO: {
    EN: 'https://help.cofenster.com/en/articles/84821-roles-permissions',
    DE: 'https://help.cofenster.com/de/articles/84821-rollen-berechtigungen',
  },
  VIDEO_ANALYTICS_INFO: {
    EN: 'https://help.cofenster.com/en/articles/103356-engagement-analytics',
    DE: 'https://help.cofenster.com/de/articles/103356-video-analytics',
  },
  SCENE_OVERLAYS_INFO: {
    EN: 'https://help.cofenster.com/en/articles/109921-media-overlay',
    DE: 'https://help.cofenster.com/de/articles/109921-videoebenen',
  },
  STOCK_LIBRARY_INFO: {
    EN: 'https://help.cofenster.com/en/articles/178686-stock-library',
    DE: 'https://help.cofenster.com/de/articles/178686-stock-bibliothek',
  },
};
type PageName = keyof typeof URLS;

export const useLocalizedWebsiteUrl = (pageName: PageName) => {
  const i18n = useI18n();
  const locale = i18n?.locale === 'DE' ? 'DE' : 'EN';

  return URLS[pageName][locale];
};
