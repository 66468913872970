import { type FC, type PropsWithChildren, createContext, useContext, useMemo } from 'react';

export type AdditionalTrackingProviderProps = {
  data?: Record<string, unknown>;
};

export const AdditionalTrackingContext = createContext<AdditionalTrackingProviderProps | undefined>(undefined);

export const useAdditionalTrackingContext = () => useContext(AdditionalTrackingContext);

export const AdditionalTrackingProvider: FC<PropsWithChildren<AdditionalTrackingProviderProps>> = ({
  children,
  data,
}) => {
  const higherContext = useAdditionalTrackingContext();
  const currentContext = useMemo(
    () => ({
      ...higherContext?.data,
      ...data,
    }),
    [higherContext?.data, data]
  );

  return <AdditionalTrackingContext.Provider value={currentContext}>{children}</AdditionalTrackingContext.Provider>;
};
