import { type FormikValues, useFormikContext } from 'formik';
import { useEffect } from 'react';

export const FormAutoSubmitter = () => {
  const { values, submitForm, dirty } = useFormikContext<FormikValues>();

  // biome-ignore lint/correctness/useExhaustiveDependencies: we do want to submit when values change
  useEffect(() => {
    if (dirty) submitForm();
  }, [values, submitForm, dirty]);

  return null;
};
