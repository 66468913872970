import { styled } from '@mui/material';
import { type CSSProperties, type ComponentProps, forwardRef, useEffect, useRef, useState } from 'react';

import { preventForwardProps } from '../../../utilities/preventForwardProps';

// 1. `z-index: 0` is needed in Safari to make delete/download icons visible
//    after playing the video.
// 2. `border-radius` is needed only for Safari; on other browsers the border-
//    radius is achieved by setting it on the parent.
// 3. Make sure that the video object fits fully within the viewport when
//    reviewing it in fullscreen.
export const StyledVideo = styled(
  'video',
  preventForwardProps(['objectFit'])
)<{ objectFit?: CSSProperties['objectFit'] }>(({ objectFit, theme }) => ({
  width: '100%',
  height: '100%',
  objectFit,
  zIndex: 0, // 1
  borderRadius: theme.shape['borderRadius-l'], // 2

  '&:fullscreen': {
    objectFit: 'contain', // 3
  },
}));

export type NativeVideoPlayerProps = Omit<
  ComponentProps<'video'>,
  'controlsList' | 'disableRemotePlayback' | 'disablePictureInPicture'
> & {
  objectFit?: CSSProperties['objectFit'];
  onPlayerReady?: (videoPlayer: HTMLVideoElement) => void;
  actions?: ('FULLSCREEN' | 'DOWNLOAD' | 'REMOTE_PLAYBACK' | 'PLAYBACK_RATE' | 'PICTURE_IN_PICTURE')[];
};

const useOnPlayerReadyCallback = (onPlayerReady: NativeVideoPlayerProps['onPlayerReady']) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    const video = videoRef.current;
    if (!video || typeof onPlayerReady !== 'function') return;

    const handle = () => onPlayerReady(video);

    video.addEventListener('loadeddata', handle, false);

    return () => {
      video.removeEventListener('loadeddata', handle, false);
    };
  }, [onPlayerReady]);

  return videoRef;
};

export const useIsVideoPlaying = (video: HTMLVideoElement | null) => {
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (!video) return;

    const handlePlay = () => setIsPlaying(true);
    const handlePause = () => setIsPlaying(false);

    video.addEventListener('play', handlePlay, false);
    video.addEventListener('pause', handlePause, false);

    return () => {
      video.removeEventListener('play', handlePlay, false);
      video.removeEventListener('pause', handlePause, false);
    };
  }, [video]);

  return isPlaying;
};

const actionsToControlsProps = (actions: NativeVideoPlayerProps['actions'] = []) => {
  const videoProps = {
    controlsList: '',
    disablePictureInPicture: false,
    disableRemotePlayback: true,
  };

  if (!actions.includes('DOWNLOAD')) {
    videoProps.controlsList += 'nodownload ';
  }

  if (!actions.includes('PLAYBACK_RATE')) {
    videoProps.controlsList += 'noplaybackrate ';
  }

  if (!actions.includes('FULLSCREEN')) {
    videoProps.controlsList += 'nofullscreen ';
  }

  if (!actions.includes('PLAYBACK_RATE')) {
    videoProps.controlsList += 'noremoteplayback ';
    videoProps.disableRemotePlayback = true;
  }

  if (!actions.includes('PICTURE_IN_PICTURE')) {
    videoProps.disablePictureInPicture = true;
  }

  return videoProps;
};

export const NativeVideoPlayer = forwardRef<HTMLVideoElement, NativeVideoPlayerProps>(function NativeVideoPlayer(
  { actions = [], onPlayerReady, ...rest },
  forwardedRef
) {
  const videoRef = useOnPlayerReadyCallback(onPlayerReady);
  const controlsProps = actionsToControlsProps(actions);

  return (
    <StyledVideo
      ref={(instance) => {
        videoRef.current = instance;

        if (typeof forwardedRef === 'function') {
          forwardedRef(instance);
        } else if (typeof forwardedRef === 'object' && forwardedRef !== null) {
          forwardedRef.current = instance;
        }
      }}
      controls
      {...controlsProps}
      {...rest}
    />
  );
});
