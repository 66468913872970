import { styled } from '@mui/material';

import { preventForwardProps } from '../../../utilities/preventForwardProps';

import { ICON_SIZES } from './constants';
import type { IconColor, IconSize } from './types';

export type CircleProps = {
  size: IconSize;
  color?: IconColor;
};

export const Circle = styled(
  'div',
  preventForwardProps(['size', 'color'])
)<CircleProps>(({ theme, size, color }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: typeof size === 'number' ? size : ICON_SIZES[size],
  width: typeof size === 'number' ? size : ICON_SIZES[size],
  backgroundColor: color === 'inherit' || color === 'currentColor' ? color : theme.palette.brand[color ?? 'linen'],
  borderRadius: '50%',
  flexShrink: 0,
}));
