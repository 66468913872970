import { type Params, generatePath as _generatePath } from 'react-router-dom';

// Since in react-router-dom there are no more optional parameters
// We just try to run the generatePath function
// If fails we return the string without parameters

export const generatePath = (path: string, params?: Params) => {
  try {
    return _generatePath(path, params);
  } catch {
    return path.split(':')[0] ?? '';
  }
};
