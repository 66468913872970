import { styled } from '@mui/material';
import type { ComponentProps } from 'react';

export type BlankButtonProps = ComponentProps<'button'>;

export const BlankButton = styled('button')(() => ({
  backgroundColor: 'transparent',
  border: 0,
  padding: 0,
  font: 'inherit',

  '&:not(:disabled)': { cursor: 'pointer' },
}));
