import type { FC } from 'react';

import { SvgIcon, type SvgIconProps } from '../SvgIcon';

export const VideoCameraWithLocker: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 40 40" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.58579 11.0858C7.96086 10.7107 8.46957 10.5 9 10.5H25C26.3261 10.5 27.5979 11.0268 28.5355 11.9645C29.4732 12.9021 30 14.1739 30 15.5V27.5C30 28.0304 29.7893 28.5391 29.4142 28.9142C29.0391 29.2893 28.5304 29.5 28 29.5H12C10.6739 29.5 9.40215 28.9732 8.46447 28.0355C7.52678 27.0979 7 25.8261 7 24.5V12.5C7 11.9696 7.21071 11.4609 7.58579 11.0858ZM25 12.5L9 12.5V24.5C9 25.2956 9.31607 26.0587 9.87868 26.6213C10.4413 27.1839 11.2044 27.5 12 27.5H28V15.5C28 14.7044 27.6839 13.9413 27.1213 13.3787C26.5587 12.8161 25.7956 12.5 25 12.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.5021 13.1351C36.8104 13.314 37.0002 13.6435 37.0002 14V26C37.0002 26.3565 36.8104 26.686 36.5021 26.8649C36.1938 27.0438 35.8135 27.0451 35.504 26.8682L28.504 22.8682C28.0245 22.5942 27.8579 21.9834 28.1319 21.5039C28.4059 21.0243 29.0168 20.8577 29.4963 21.1318L35.0002 24.2768V15.7232L29.4963 18.8682C29.0168 19.1423 28.4059 18.9757 28.1319 18.4961C27.8579 18.0166 28.0245 17.4058 28.504 17.1318L35.504 13.1318C35.8135 12.9549 36.1938 12.9562 36.5021 13.1351Z"
      fill="currentColor"
    />
    <circle cx="11.5" cy="25.5" r="8.5" fill="#EDE5E0" stroke="currentColor" strokeWidth="2" />
    <path
      d="M14.6818 24H8.31818C8.14245 24 8 24.1399 8 24.3125V28.6875C8 28.8601 8.14245 29 8.31818 29H14.6818C14.8575 29 15 28.8601 15 28.6875V24.3125C15 24.1399 14.8575 24 14.6818 24Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 24V22.5C10 22.1022 10.158 21.7206 10.4393 21.4393C10.7206 21.158 11.1022 21 11.5 21C11.8978 21 12.2794 21.158 12.5607 21.4393"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
