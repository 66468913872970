import { useEffect } from 'react';

import { type I18nParams, useI18n } from '../../services/i18n';

export const useTitle = (title?: string | null, params?: I18nParams) => {
  const { translatable } = useI18n();

  useEffect(() => {
    if (title) document.title = `${translatable(title, params)} — cofenster`;
    else document.title = 'cofenster';
  }, [title, params, translatable]);
};
