import type { FC } from 'react';

import { SvgIcon, type SvgIconProps } from '../SvgIcon';

export const FolderEmptyOpen: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 32 32" {...props}>
    <path
      d="M7.39061 24.1667L5.5 9.47051C5.5 9.25397 5.58729 9.0463 5.74267 8.89319C5.89804 8.74007 6.10878 8.65405 6.32852 8.65405H11.852C12.0312 8.65405 12.2057 8.71135 12.3491 8.81734L15.2213 10.9401C15.3647 11.0461 15.5391 11.1034 15.7184 11.1034H22.8989C23.1186 11.1034 23.3293 11.1894 23.4847 11.3426C23.6401 11.4957 23.7274 11.7033 23.7274 11.9199L25.618 14.3692"
      fill="#D3A530"
    />
    <path
      d="M7.38965 24.1666L10.6323 13.4796C10.6875 13.3167 10.7935 13.1749 10.9353 13.0744C11.0771 12.974 11.2474 12.9199 11.4221 12.9199H27.9839C28.1158 12.9199 28.2459 12.9507 28.3633 13.0098C28.4808 13.069 28.5822 13.1546 28.6594 13.2599C28.7365 13.3651 28.7871 13.4868 28.807 13.6151C28.8269 13.7433 28.8155 13.8743 28.7738 13.9974L25.7067 24.1666H7.38965Z"
      fill="#F4B922"
    />
  </SvgIcon>
);
