import { useTheme } from '@mui/material';
import { type FC, memo } from 'react';

import { Circle } from './Circle';
import { ICON_SIZES } from './constants';
import * as customIcons from './custom';
import * as icons from './phosphor';
import type { IconProps } from './types';

const STAGE = process.env.STAGE as string;

export * from './types';
export * from './constants';

export const Icon: FC<IconProps> = memo(({ type, color, size = 'm', weight = 'regular', background, ...rest }) => {
  const theme = useTheme();

  const Component = icons[type as keyof typeof icons] ?? customIcons[type as keyof typeof customIcons];

  if (!Component) {
    if (STAGE !== 'production') console.log('Missing icon component', type);
    return null;
  }

  const resolvedColor = color === 'inherit' || color === 'currentColor' || !color ? color : theme.palette.brand[color];
  const resolvedSize = typeof size === 'number' ? size : ICON_SIZES[size];

  if (!background) {
    return (
      <Component {...rest} color={resolvedColor} size={resolvedSize} weight={weight} data-testid={`icon-${type}`} />
    );
  }

  return (
    <Circle size={background.size} color={background.color}>
      <Component {...rest} color={resolvedColor} size={resolvedSize} weight={weight} data-testid={`icon-${type}`} />
    </Circle>
  );
});

Icon.displayName = 'Icon';
