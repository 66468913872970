import { type FC, type PropsWithChildren, useState } from 'react';
import { useSwipeable } from 'react-swipeable';

import {
  BlankButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  GridContainer,
  GridItem,
  Text,
  Typography,
  VisuallyHidden,
  styled,
} from '@cofenster/web-components';

const ImageWrapper = styled('div')(({ theme }) => ({
  '@media (max-height: 650px)': {
    display: 'none',
  },

  marginTop: theme.spacing(2),

  '> img': {
    display: 'block',
    width: '100%',
  },
}));

const SlideButton = styled(BlankButton)(({ theme }) => ({
  '@media (max-height: 650px)': {
    display: 'none',
  },

  '&[aria-current="true"]': {
    color: theme.palette.brand.blue,
  },

  '&:hover, &:focus': {
    color: theme.palette.brand.blue,
    outline: 0,
  },
}));

export type FilmingTipsDialogProps = {
  isOpen: boolean;
  closeDialog: () => unknown;
};

export const FilmingTipsDialog: FC<PropsWithChildren<FilmingTipsDialogProps>> = ({ isOpen, closeDialog }) => {
  const [carouselPosition, setCarouselPosition] = useState(0);
  const carousel = [
    {
      imgSrc: '/assets/images/image-position.jpg',
      headline: 'i18n.FilmingTipsDialog.headline.position',
      description: 'i18n.FilmingTipsDialog.description.position',
    },
    {
      imgSrc: '/assets/images/image-noise.jpg',
      headline: 'i18n.FilmingTipsDialog.headline.backgroundNoise',
      description: 'i18n.FilmingTipsDialog.description.backgroundNoise',
    },
    {
      imgSrc: '/assets/images/image-micro.jpg',
      headline: 'i18n.FilmingTipsDialog.headline.microphone',
      description: 'i18n.FilmingTipsDialog.description.microphone',
    },
  ];
  const onClickPrev = () => {
    if (carouselPosition !== 0) setCarouselPosition(carouselPosition - 1);
  };

  const onClickNext = () => {
    if (carouselPosition !== carousel.length - 1) setCarouselPosition(carouselPosition + 1);
    else closeDialog();
  };

  const handlers = useSwipeable({
    onSwipedLeft: onClickNext,
    onSwipedRight: onClickPrev,
  });

  const item = carousel[carouselPosition];
  if (!item) return null;

  return (
    <Dialog open={isOpen} onClose={closeDialog} title={item.headline}>
      <DialogContent>
        <Text variant="l" component="p">
          {item.description}
        </Text>
        <ImageWrapper {...handlers}>
          <img src={item.imgSrc ?? ''} alt="" />
        </ImageWrapper>
        <GridContainer justifyContent="center">
          <GridItem display="flex" flexDirection="row">
            {carousel.map((item, index) => (
              <SlideButton
                key={item.headline}
                onClick={() => setCarouselPosition(index)}
                aria-current={index === carouselPosition}
                type="button"
              >
                <Typography variant="h1" component="span">
                  <span aria-hidden="true">&bull;</span>
                  <VisuallyHidden i18nParams={{ index }}>{item.headline}</VisuallyHidden>
                </Typography>
              </SlideButton>
            ))}
          </GridItem>
        </GridContainer>
      </DialogContent>

      <DialogActions>
        <GridContainer>
          <GridItem xs={6}>
            <Button variant="tertiary" fullWidth onClick={onClickPrev} disabled={carouselPosition === 0}>
              i18n.global.previous
            </Button>
          </GridItem>
          <GridItem xs={6}>
            <Button
              variant="primary"
              fullWidth
              onClick={carouselPosition === carousel.length - 1 ? closeDialog : onClickNext}
            >
              {carouselPosition === carousel.length - 1 ? 'i18n.FilmingTipsDialogProps.gotIt' : 'i18n.global.next'}
            </Button>
          </GridItem>
        </GridContainer>
      </DialogActions>
    </Dialog>
  );
};
