import { createTheme } from '@mui/material';

import { breakpointsThemeOptions } from './breakpoints';
import { layersThemeOptions } from './layers';
import { mixinsThemeOptions } from './mixins';
import { paletteThemeOptions } from './palette';
import { shadowThemeOptions } from './shadows';
import { shapeThemeOptions } from './shape';
import { typographyThemeOptions } from './typography';

export { brandColors } from './palette';
export type { BrandColor } from './palette';
export type { TypographyVariants, TextVariants, HeadlineVariants, SpecialVariants, CSSObject } from './typography';

export { GlobalStyles } from '@mui/material';

export type Theme = typeof theme;

export const theme = createTheme({
  mixins: mixinsThemeOptions,
  typography: typographyThemeOptions,
  palette: paletteThemeOptions,
  shape: shapeThemeOptions,
  shadows: shadowThemeOptions,
  breakpoints: breakpointsThemeOptions,
  zIndex: layersThemeOptions,
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});
