import { styled } from '@mui/material';

import { preventForwardProps } from '../../../utilities/preventForwardProps';

import { Icon } from './Icon';

export type SpinningIconProps = {
  speed?: 'fast' | 'slow' | 'normal';
};

export const SpinningIcon = styled(
  Icon,
  preventForwardProps(['speed'])
)<SpinningIconProps>(({ speed = 'normal' }) => ({
  animation: `rotate var(--animation-duration, ${
    speed === 'fast' ? '1' : speed === 'slow' ? '8' : '5'
  }s) linear infinite`,
  '@keyframes rotate': { to: { transform: 'rotate(1turn)' } },
}));
