import { useContext } from 'react';

import { SnackbarsContext } from './SnackbarsContext';

export const useSnackbars = () => {
  const context = useContext(SnackbarsContext);

  if (!context) {
    throw new Error('`SnackbarsProvider` missing in tree.');
  }

  return context;
};
