import './types';

export const breakpointsThemeOptions = {
  values: {
    xs: 300,
    sm: 600,
    md: 996,
    lg: 1200,
    xl: 1536,
  },
};
