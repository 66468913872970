import { useCallback, useMemo } from 'react';

import { useCachedFile } from '@cofenster/web-components';

import { type CaptureAssetMetadata, useCaptureAssetCandidateFileContext } from '../../../context/captureAssetFile';
import { useTracking } from '../../../context/tracking';
import { useCaptureAssetLifecycleFlow } from '../CaptureAssetLifecycleFlow';

export const useRetrieveFileCache = (uploadId: string) => {
  const tracking = useTracking();
  const { assetType } = useCaptureAssetLifecycleFlow();
  const { onCaptureAssetReadyForReview } = useCaptureAssetCandidateFileContext();

  const { file: cachedFile, loading: cacheLoading, metadata: cacheFileMetadata } = useCachedFile(assetType, uploadId);

  const onSelectCachedFile = useCallback(() => {
    if (!cachedFile) return;
    const metadata = cacheFileMetadata as CaptureAssetMetadata;

    tracking.trackEvent({
      event: 'captureFileRetrievedFromCache',
      details: {
        assetType,
        uploadId,
        metadata,
      },
    });

    onCaptureAssetReadyForReview(
      {
        blob: cachedFile,
        url: URL.createObjectURL(cachedFile),
      },
      metadata
        ? {
            ...metadata,
            fromCache: true,
          }
        : {
            uploadSource: 'cached-unknown-source',
            fromCache: true,
          }
    );
  }, [cachedFile, cacheFileMetadata, onCaptureAssetReadyForReview, assetType, uploadId, tracking]);

  return useMemo(
    () => ({
      cacheLoading,
      cachedFile,
      onSelectCachedFile,
    }),
    [cacheLoading, cachedFile, onSelectCachedFile]
  );
};
