import { Tooltip as MUITooltip, type TooltipProps as MUITooltipProps, styled, tooltipClasses } from '@mui/material';
import type { CSSProperties, FC, PropsWithChildren } from 'react';

import { type I18nParams, Translatable } from '../../../services/i18n';
import { preventForwardProps } from '../../../utilities/preventForwardProps';

export type TooltipProps = Omit<MUITooltipProps, 'children'> & {
  withShadow?: boolean;
  variant?: 'dark' | 'light';
  i18nParams?: I18nParams;
} & Pick<CSSProperties, 'maxWidth'>;

const WrappedTooltip: FC<MUITooltipProps> = ({ className, ...props }) => (
  <MUITooltip {...props} classes={{ popper: className }} />
);

const StyledTooltip = styled(
  WrappedTooltip,
  preventForwardProps(['variant', 'withShadow', 'maxWidth'])
)<TooltipProps>(({ theme, variant = 'dark', maxWidth = 200, withShadow, placement }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: variant === 'dark' ? theme.palette.brand.carbon : theme.palette.brand.white,
  },

  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: variant === 'dark' ? theme.palette.brand.carbon : theme.palette.brand.white,
    color: variant === 'light' ? theme.palette.brand.carbon : theme.palette.brand.white,
    boxShadow: withShadow ? '0px 0px 30px rgb(0 0 0 / 0.1)' : undefined,
    marginBottom: placement === 'top' ? theme.spacing(1) : undefined,
    maxWidth,
    textAlign: 'center',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
  },
}));

export const Tooltip: FC<PropsWithChildren<TooltipProps>> = ({
  className,
  children,
  title,
  i18nParams,
  placement = 'top',
  ...props
}) =>
  title ? (
    <StyledTooltip
      {...props}
      title={<Translatable i18nParams={i18nParams}>{title}</Translatable>}
      placement={placement}
      arrow
    >
      {/* MUI Tooltip expects `ReactElement` content instead of `ReactNode`,
       * which typically implies wrapping the content in a React Fragment.
       * However, this causes issues with the prop-/ref-drilling from the MUI
       * tooltip.
       * @ts-expect-error */}
      {children}
    </StyledTooltip>
  ) : (
    children
  );
