import { useField } from 'formik';
import type { ClipboardEventHandler, FC } from 'react';

import { TextField, type TextFieldProps } from '../../controls/TextField';

export type FormTextFieldProps = Omit<TextFieldProps, 'error' | 'helperText'> & {
  name: string;
  id: string;
  label: string;
  'data-testid'?: string;
  onPaste?: ClipboardEventHandler;
};

export const FormTextField: FC<FormTextFieldProps> = ({
  name,
  fullWidth = true,
  variant = 'outlined',
  'data-testid': dataTestId,
  ...rest
}) => {
  const [field, meta] = useField(name);
  const hasError = meta.touched && !!meta.error;
  return (
    <TextField
      {...field}
      {...rest}
      variant={variant}
      fullWidth={fullWidth}
      error={hasError}
      helperText={hasError ? meta.error : undefined}
      inputProps={{ ...rest.inputProps, 'data-testid': dataTestId }}
    />
  );
};
