import './types';

export const layersThemeOptions = {
  top: 500,
  overlay: 100,
  above: 10,
  base: 1,
  below: -1,
};

export type Layers = typeof layersThemeOptions;
