import { FilePicker, GridItem, Icon, Typography, useNativeCaptureSupported } from '@cofenster/web-components';
import type { FC } from 'react';

import { FilmingTipsMessage } from '../../../components/controls';
import { useCaptureAssetCandidateFileContext } from '../../../context/captureAssetFile';
import { useContributionRequestListDetails } from '../../../context/contributionRequestListDetails';
import { RecordViaAppMessage } from '../CaptureVideo/CaptureRecording/RecordViaAppMessage';
import { InfoBanner } from '../InfoBanner';

export const CaptureActions: FC<{ showRecordViaApp: boolean; type: 'video' | 'image' }> = ({
  showRecordViaApp,
  type,
}) => {
  const nativeCaptureSupported = useNativeCaptureSupported();
  const { onCaptureAssetReadyForReview } = useCaptureAssetCandidateFileContext();
  const contributionsRequestDetails = useContributionRequestListDetails();
  const showFilmingTips = !contributionsRequestDetails;

  const onFiles = (files: File[]) => {
    const file = files?.[0];
    if (!file) return;
    onCaptureAssetReadyForReview(
      {
        blob: file,
        url: URL.createObjectURL(file),
      },
      { uploadSource: 'desktop-library' }
    );
  };

  if (nativeCaptureSupported || !type) return null;

  const title = {
    image: 'i18n.Recording.ImageCapture.Upload',
    video: 'i18n.Recording.VideoCapture.Upload',
  }[type];

  return (
    <>
      <GridItem xs={showRecordViaApp ? 6 : 12}>
        <InfoBanner>
          {(Button) => (
            <FilePicker
              id={`scene-${type}-upload`}
              data-testid="upload-button"
              onFiles={onFiles}
              video={type === 'video'}
              image={type === 'image'}
              ButtonComponent={Button}
              variant="tertiary"
              startIcon={<Icon type="UploadIcon" color="blue" />}
            >
              <Typography variant="l">{title}</Typography>
            </FilePicker>
          )}
        </InfoBanner>
      </GridItem>

      {showRecordViaApp && (
        <GridItem xs={6} pl={'16px'}>
          <RecordViaAppMessage />
        </GridItem>
      )}

      {showFilmingTips && (
        <GridItem xs={12}>
          <FilmingTipsMessage />
        </GridItem>
      )}
    </>
  );
};
