import type { FC } from 'react';

import { Button, Dialog, DialogActions, DialogContent, GridContainer, GridItem, Text } from '@cofenster/web-components';

export type DeleteRecordingDialogProps = {
  isOpen: boolean;
  onAction: () => unknown;
  closeDialog: () => unknown;
};

export const DeleteRecordingDialog: FC<DeleteRecordingDialogProps> = ({ isOpen, closeDialog, onAction }) => {
  const handleAction = () => {
    closeDialog();
    onAction();
  };
  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      data-testid="delete-recording-dialog"
      title="i18n.DeleteRecordingDialog.headline"
    >
      <DialogContent>
        <Text variant="l" color="grey600" component="p">
          i18n.DeleteRecordingDialog.description
        </Text>
      </DialogContent>

      <DialogActions>
        <GridContainer spacing={1}>
          <GridItem xs={12} sm={6}>
            <Button variant="secondary" fullWidth onClick={closeDialog} data-testid="cancel-button">
              i18n.global.cancel
            </Button>
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Button variant="destructive" fullWidth onClick={handleAction} data-testid="delete-button">
              i18n.DeleteRecordingDialog.button
            </Button>
          </GridItem>
        </GridContainer>
      </DialogActions>
    </Dialog>
  );
};
