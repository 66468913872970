import { useEffect, useState } from 'react';

type Orientation = 'portrait' | 'landscape';

const initialOrientation = () => {
  try {
    // Newer Screen Orientation API which is not supported everywhere
    // See: https://developer.mozilla.org/en-US/docs/Web/API/Screen/orientation
    return window.screen.orientation.type.split('-')[0] as Orientation;
  } catch {
    // Older deprecated Orientation API which is still supported somewhere
    // See: https://developer.mozilla.org/en-US/docs/Web/API/Window/orientation
    return window.orientation === 0 || window.orientation === 180 ? 'portrait' : 'landscape';
  }
};

export const useOrientation = () => {
  const [orientation, setOrientation] = useState<Orientation>(initialOrientation);

  useEffect(() => {
    const matcher = ({ matches }: { matches: boolean }) => setOrientation(matches ? 'portrait' : 'landscape');
    const media = window.matchMedia('(orientation: portrait)');

    media.addEventListener('change', matcher);

    return () => {
      media.removeEventListener('change', matcher);
    };
  }, []);

  return orientation;
};
