import { Alert as MUIAlert, type AlertProps as MuiAlertProps } from '@mui/material';
import type { FC, PropsWithChildren } from 'react';

import type { I18nParams } from '../../../services/i18n';
import { Text } from '../../typography/Text';

export type AlertProps = MuiAlertProps & {
  i18nParams?: I18nParams;
};

export const Alert: FC<PropsWithChildren<AlertProps>> = ({ children, i18nParams, icon = false, ...rest }) => {
  return (
    <MUIAlert icon={icon} {...rest}>
      <Text i18nParams={i18nParams}>{children}</Text>
    </MUIAlert>
  );
};
