import type { FC } from 'react';

import { SvgIcon, type SvgIconProps } from '../SvgIcon';

export const FlipAsset: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.19 4.525a.75.75 0 0 1 .56.725v13.5a.75.75 0 0 1-.75.75H.75a.75.75 0 0 1-.653-1.119l8.25-13.5a.75.75 0 0 1 .844-.356ZM2.036 18H8.25V8.101l-6.215 9.9ZM14.81 4.525a.75.75 0 0 0-.56.725v13.5c0 .414.336.75.75.75h7.875a.75.75 0 0 0 .653-1.119l-7.875-13.5a.75.75 0 0 0-.844-.356ZM21.59 18h-5.84V8.101l5.84 9.9ZM12 2.25a.75.75 0 0 1 .75.75v18a.75.75 0 0 1-1.5 0V3a.75.75 0 0 1 .75-.75Z"
      clipRule="evenodd"
    />
  </SvgIcon>
);
