import type { FC, PropsWithChildren } from 'react';

import { useI18n } from './useI18n';

import type { I18nParams } from './types';

export type TranslatableProps = {
  i18nParams?: I18nParams;
};

export const Translatable: FC<PropsWithChildren<TranslatableProps>> = ({ children, i18nParams }) => {
  const { translatable } = useI18n();

  return <>{translatable(children, i18nParams)}</>;
};
