import type { HeadlineVariants } from '../../../theming/theme';
import { Typography, type TypographyProps } from '../../typography/Typography';

export type HeadlineProps = Omit<TypographyProps, 'variants' | 'component' | 'paragraph' | 'fontFamily' | 'bold'> & {
  variant: HeadlineVariants;
  component?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'figcaption' | 'legend';
};

export const Headline = ({ variant, component, ...rest }: HeadlineProps) => (
  <Typography component={component ?? variant} variant={variant} {...rest} />
);
