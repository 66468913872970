import type { SceneAssetLifecycleTranslationsEN } from './EN';

export const SceneAssetLifecycleTranslationsDE: typeof SceneAssetLifecycleTranslationsEN = {
  'DeleteRecordingDialog.button': 'Aufnahme löschen',
  'DeleteRecordingDialog.description': 'Deine Aufnahme wird permanent gelöscht.',
  'DeleteRecordingDialog.headline': 'Aufnahme löschen?',
  'FilmingTipsDialog.description.backgroundNoise': 'Achte darauf, dass die Tonqualität deiner Aufnahme nicht gestört wird',
  'FilmingTipsDialog.description.microphone': 'Das ist unsere Empfehlung für gleichbleibende Tonqualität!',
  'FilmingTipsDialog.description.position': 'Achte darauf, dass sich dein Gesicht im oberen Drittel des Bildes befindet, etwa 1 Meter entfernt',
  'FilmingTipsDialog.headline.backgroundNoise': 'Vermeide Hintergrundgeräusche',
  'FilmingTipsDialog.headline.microphone': 'Benutze ein externes Mikrofon',
  'FilmingTipsDialog.headline.position': 'Gute Position und Abstand',
  'FilmingTipsDialogProps.gotIt': 'Verstanden',
  'FilmingTipsLink.learnMoreButton': 'Mehr erfahren',
  'FilmingTipsLink.text': 'Gut aussehen vor der Kamera',
  'FilmingTipsLink.textSecondary': 'Drei schnelle Tipps, um eine qualitativ hochwertige Aufnahme zu erstellen',
  'recordingWithNoMicrophoneConnected.content': 'Wir konnten für dein Gerät kein Mikrofon finden. Überprüfe deine Systemeinstellungen, um sicherzustellen, dass dein Mikrofon richtig angeschlossen ist. Möglicherweise musst du diese Seite nach dem Anschließen neu laden.',
  'recordingWithNoMicrophoneConnected.title': 'Kein Mikrofon',
  'recordingWithoutMicrophone.confirm': 'Trotzdem fortfahren',
  'recordingWithoutMicrophone.content': 'Du bist dabei, dein Video ohne Ton aufzunehmen. Du kannst mit der Aufnahme fortfahren oder abbrechen und ein Mikrofon auswählen.',
  'recordingWithoutMicrophone.title': 'Kein Mikrofon ausgewählt',
  'Recording.Capture.recordAnotherTake': 'Noch eine Aufnahme',
  'Recording.ImageCapture.CloseInstructions': 'Anweisungen schließen',
  'Recording.ImageCapture.Dropzone.DragAndDrop.Title': 'Ziehe dein Bild per Drag & Drop hierher',
  'Recording.ImageCapture.NoPermission.Subtitle': 'Wir müssen auf deine Kamera zugreifen, um ein Bild aufzunehmen',
  'Recording.ImageCapture.NoPermission.Title': 'Kamera aktivieren',
  'Recording.ImageCapture.Upload': 'Ein Bild hochladen',
  'Recording.ScreenCapture.Subtitle': 'Du kannst jetzt die Aufnahme starten und hierher zurückkehren wenn du fertig bist',
  'Recording.ScreenCapture.Title': '{desktop, select, monitor {Dein Bildschirm wird geteilt} window {Dein Programmfenster wird geteilt} browser {Browser-Fenster wird geteilt} other {Wird geteilt} }.',
  'Recording.UI.ChooseImage': 'Wähle ein Bild',
  'Recording.UI.NoCamera': 'Keine Kamera',
  'Recording.UI.RequestPermission': 'Um Erlaubnis bitten',
  'Recording.UI.TakeSnapshot': 'Schnappschuss machen',
  'Recording.VideoCapture.RecordViaMobile': 'per Handy aufnehmen',
  'Recording.VideoCapture.Upload': 'Ein Video hochladen',
  'ScenePage.CameraPermissionError.text': 'Deine aktuellen Browser-Einstellungen verhindern den Zugriff auf deine Kamera.',
  'ScenePage.emptyTitle': 'Kein Titel',
  'ScenePage.GoBackButton.label': 'Geh zurück',
  'ScenePage.InitialScreen.camera.description.callToAction.button': 'Um Erlaubnis bitten',
  'ScenePage.InitialScreen.camera.description.callToAction.description': 'Klicke auf das <link>Schloss Symbol in deiner Browserleiste</link> und gib uns die Erlaubnis deine Kamera und dein Mikrofon zu verwenden, damit du dein Video aufnehmen kannst.',
  'ScenePage.InitialScreen.camera.description.callToAction.title': 'Wir brauchen Zugriff auf deine Kamera und dein Mikrofon, damit du dieses Video aufnehmen kannst',
  'ScenePage.InitialScreen.camera.title': 'Aktiviere Kamera und Mikrofon',
  'ScenePage.InitialScreen.image.description.callToAction.button': 'Um Erlaubnis bitten',
  'ScenePage.InitialScreen.screenRecording.description.callToAction.button': 'Um Erlaubnis bitten',
  'ScenePage.InitialScreen.screenRecording.description.callToAction.description': 'Klicke auf das <link>Schloss Symbol in deiner Browserleiste</link> und gib uns die Erlaubnis dein Mikrofon zu verwenden, damit du deinen Bildschirm aufnehmen kannst.',
  'ScenePage.InitialScreen.screenRecording.description.callToAction.title': 'Wir benötigen Zugriff auf deinen Bildschirm und dein Mikrofon, damit du dieses Video aufnehmen kannst',
  'ScenePage.InitialScreen.screenRecording.title': 'Bildschirmfreigabe aktivieren',
  'ScenePage.InitialScreen.screenRecordingStart.callToAction.title': 'Bildschirm freigeben',
  'ScenePage.InitialScreen.screenRecordingStart.description': 'Wähle den Bildschirm aus, den du freigeben möchtest, um die Aufnahme zu starten',
  'ScenePage.InitialScreen.screenRecordingStart.title': 'Starte die Bildschirmfreigabe',
  'ScenePage.InitialScreen.sharingNotSupported.description': 'Wechsel zu einem nicht mobilen Gerät oder verwende die integrierte Bildschirmaufzeichnung deines Telefons und lade das Ergebnis hoch',
  'ScenePage.InitialScreen.sharingNotSupported.howToRecordButton': 'So nimmst du auf',
  'ScenePage.InitialScreen.sharingNotSupported.title': 'Bildschirmfreigabe wird nicht unterstützt',
  'ScenePage.InitialScreen.sharingNotSupported.uploadButton': 'Hochladen',
  'ScenePage.InitialScreen.uploadButton': 'Hochladen',
  'ScenePage.InitialScreen.uploadButtonImage': 'Bild hochladen',
  'ScenePage.InitialScreen.uploadButtonVideo': 'Video hochladen',
  'ScenePage.InstructionsScreen.mobile.howToLookGoodOnCameraButton': 'Gut aussehen vor der Kamera',
  'ScenePage.InstructionsScreen.mobile.startRecordingButton.resume': 'Aufnahme fortsetzen',
  'ScenePage.InstructionsScreen.mobile.startRecordingButton.start': 'Aufnahme starten',
  'ScenePage.moreOptions.delete': 'Löschen',
  'ScenePage.moreOptions.edit': 'Bearbeiten',
  'ScenePage.moreOptions.label': 'Weitere Aktionen',
  'ScenePage.NoTitleAndInstructionsTitle': 'Keine Anleitung für diese Szene',
  'ScenePage.openInApp': 'In der App öffnen',
  'ScenePage.RecordingBarMobile.flipCamera': 'Kamera wechseln',
  'ScenePage.RecordingBarMobile.pause': 'Pause',
  'ScenePage.RecordingBarMobile.resume': 'Fortsetzen',
  'ScenePage.RecordingBarMobile.start': 'Start',
  'ScenePage.ReviewRecording.cancelButton': 'Erneut versuchen',
  'ScenePage.ReviewRecording.headline': 'Prüfen und fortfahren',
  'ScenePage.ReviewRecording.saveButton': 'Bestätigen',
  'ScenePage.ScreenRecorder.filename': 'Deine cofenster Bildschirmaufnahme',
  'ScenePage.UploadingScreen.cancelUploadOrProcessing.image': 'Abbrechen',
  'ScenePage.UploadingScreen.cancelUploadOrProcessing.screen': 'Abbrechen',
  'ScenePage.UploadingScreen.cancelUploadOrProcessing.video': 'Abbrechen',
  'ScenePage.UploadingScreen.headline.optimizing.image': 'Dein Bild wird optimiert',
  'ScenePage.UploadingScreen.headline.optimizing.screen': 'Bildschirmaufnahme wird optimiert',
  'ScenePage.UploadingScreen.headline.optimizing.video': 'Video wird optimiert',
  'ScenePage.UploadingScreen.headline.uploading.image': 'Bild wird hochgeladen',
  'ScenePage.UploadingScreen.headline.uploading.screen': 'Bildschirmaufnahme wird hochgeladen',
  'ScenePage.UploadingScreen.headline.uploading.video': 'Video wird hochgeladen',
  'ScenePage.UploadsDisabled.headline': 'Du kannst nicht zu dieser Szene beitragen',
  'ScenePage.UploadsDisabled.subline': 'Wende dich an den Ersteller des Videoprojekts, wenn Du glaubst, dass dies ein Fehler ist',
  'ScenePage.WebcamImageCapture.filename': 'Dein cofenster Schnappschuss',
  'ScenePage.WebcamRecorder.filename': 'Dein cofenster video',
  'ScenePage.WebcamRecorder.headline.initial': 'Klicke auf die rote Schaltfläche, um die Aufnahme zu starten',
  'ScenePage.WebcamRecorder.headline.recording': 'Du nimmst gerade auf',
  'ScenePage.WebcamRecorder.orientationMismatchRecordingStoppedWarning': 'Wir haben deine Aufzeichnung aufgrund der falschen Geräteausrichtung gestoppt. Du kannst sie fortsetzen.',
  'UploadRetakeVideoMessage.description': 'Dein Editor schneidet und bearbeitet diese Aufnahme für das endgültige Video.',
  'UploadRetakeVideoMessage.title': 'Noch nicht perfekt? Kein Problem!',
};
