import { type ComponentPropsWithoutRef, type ComponentType, createContext } from 'react';

// biome-ignore lint/suspicious/noExplicitAny: no idea, there might be a better way
export type DialogComponent = ComponentType<any>;
export type GenericDialogProps<Dialog extends ComponentType> = Omit<
  ComponentPropsWithoutRef<Dialog>,
  'isOpen' | 'closeDialog'
>;
export type Dialogs = Record<string, DialogComponent>;
export type OpenRecord<D extends Dialogs, DialogName extends keyof D = keyof D> = Partial<
  Record<keyof D, GenericDialogProps<D[DialogName]>>
>;

export type DialogsContext<D extends Dialogs> = {
  openDialog: <DialogName extends keyof D>(
    dialogName: DialogName,
    dialogProps: GenericDialogProps<D[DialogName]>
  ) => void;
  closeDialog: <DialogName extends keyof D>(dialogName: DialogName) => void;
  isOpen: <DialogName extends keyof D>(dialogName?: DialogName) => boolean;
};

export function createDialogsContext<D extends Dialogs>() {
  return createContext<DialogsContext<D>>({
    openDialog: () => '',
    closeDialog: () => undefined,
    isOpen: () => false,
  });
}
