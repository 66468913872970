import { Box, type BoxProps, type Breakpoint, type Theme } from '@mui/material';
import type { CSSProperties, ComponentPropsWithoutRef, FC, PropsWithChildren } from 'react';

type Responsive = ComponentPropsWithoutRef<'div'> & {
  component?: BoxProps['component'];
  display?: CSSProperties['display'];
};
type ResponsiveWithUp = Responsive & { up: Breakpoint; down?: never };
type ResponsiveWithDown = Responsive & { up?: never; down: Breakpoint };

export type ResponsiveProps = ResponsiveWithUp | ResponsiveWithDown;

const computeDisplayProperty =
  (up?: Breakpoint, down?: Breakpoint, display?: Responsive['display']) => (theme: Theme) => {
    const [xs] = theme.breakpoints.keys;
    if (!xs) return undefined;
    if (up) return { [xs]: 'none', [up]: display };
    if (down) return { [xs]: display, [down]: 'none' };
    return undefined;
  };

export const Responsive: FC<PropsWithChildren<ResponsiveProps>> = ({
  children,
  display = 'block',
  up,
  down,
  component,
  ...rest
}) => (
  <Box {...rest} component={component} sx={{ display: computeDisplayProperty(up, down, display) }}>
    {children}
  </Box>
);
