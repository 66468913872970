import { styled } from '@mui/material';
import {
  type ComponentClass,
  type ComponentProps,
  type ComponentType,
  type FC,
  type JSX,
  type MouseEventHandler,
  type PropsWithChildren,
  useCallback,
} from 'react';

import { Translatable } from '../../../services/i18n';
import type { BrandColor } from '../../../theming/theme';
import { preventForwardProps } from '../../../utilities/preventForwardProps';
import { Icon, type IconType } from '../../assets/icons/Icon';
import { BlankButton } from '../../controls/Button/BlankButton';
import { usePopover } from '../../feedback/Popover';

const Container = styled(
  BlankButton,
  preventForwardProps(['disabled', 'color'])
)<Pick<PopoverMenuItemProps, 'disabled' | 'color'>>(({ theme, color = 'carbon', disabled }) => ({
  display: 'flex',
  minWidth: 160,
  maxWidth: 280,
  minHeight: 40,
  width: `calc(100% - ${theme.spacing(4)})`,
  padding: theme.spacing(0.5, 1),
  border: '1px solid transparent',
  borderRadius: theme.spacing(0.5),
  gap: theme.spacing(1),
  alignItems: 'center',
  ...theme.typography.l,
  color: theme.palette.toRgba(theme.palette.brand[color], disabled ? 0.5 : 1),
  pointerEvents: disabled ? 'none' : undefined,
  textAlign: 'start',

  margin: theme.spacing(1, 2),
  '&:first-child': { marginTop: theme.spacing(2) },
  '&:last-child': { marginBottom: theme.spacing(2) },

  '&:hover, &:focus': {
    backgroundColor: theme.palette.brand.grey50,
    outline: 0,
  },

  '&:active': {
    borderColor: theme.palette.brand.grey200,
  },

  '&[aria-current="true"]': {
    backgroundColor: theme.palette.brand.grey100,
  },
}));

export type PopoverMenuItemProps = ComponentProps<'button'> & {
  icon?: IconType;
  color?: BrandColor;
  closePopupOnClick?: boolean;
  // biome-ignore lint/suspicious/noExplicitAny: no idea, there might be a better way
  component?: ComponentClass<any> | ComponentType<any> | keyof JSX.IntrinsicElements;
  href?: string;
  to?: string;
  target?: '_blank';
  download?: boolean;
  params?: unknown;
};

// 1. Ensure the icon doesn’t shrink if there is enough content near it to fill
//    the entire width of the parent
const StyledIcon = styled(Icon)(() => ({
  flexShrink: 0, // 1
}));

export const PopoverMenuItem: FC<PropsWithChildren<PopoverMenuItemProps>> = ({
  onClick,
  icon,
  children,
  closePopupOnClick = true,
  component,
  ...rest
}) => {
  const { closePopover } = usePopover();

  const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      if (onClick) onClick(event);
      if (closePopupOnClick) closePopover();
    },
    [onClick, closePopupOnClick, closePopover]
  );

  const withClickHandler = onClick ?? closePopupOnClick;
  const Component = component
    ? Container.withComponent(component as Parameters<(typeof Container)['withComponent']>[0])
    : Container;

  return (
    <Component {...rest} onClick={withClickHandler ? handleClick : undefined}>
      {icon && <StyledIcon size="m" type={icon} />}
      <Translatable>{children}</Translatable>
    </Component>
  );
};
