import { styled } from '@mui/material';

export const HiddenInput = styled('input')(({ theme }) => ({
  appearance: 'none',
  position: 'absolute',
  inset: 0,
  opacity: 0,
  cursor: 'pointer',

  '&:checked + *': {
    color: theme.palette.brand.blue,
    backgroundColor: theme.palette.brand.blue50,
  },

  '&:focus-visible + *': theme.mixins.focusRing,
  '&:not(:disabled):hover + *': { borderColor: theme.palette.brand.grey600 },
  '&:checked:hover + *': { borderColor: theme.palette.brand.blue },

  '&:disabled': { cursor: 'not-allowed' },
  '&:disabled + *': { color: theme.palette.brand.grey600 },
}));
