import type { FC, PropsWithChildren } from 'react';

import { DialogsProvider as SharedDialogsProvider } from '@cofenster/web-components';

import * as dialogs from '../../components/dialog';
import { DialogsContext } from './DialogsContext';

export const DialogsProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <SharedDialogsProvider<typeof dialogs> dialogs={dialogs} DialogsContext={DialogsContext}>
      {children}
    </SharedDialogsProvider>
  );
};
