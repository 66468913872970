import type { FC } from 'react';

import { GridContainer, GridItem, Text, styled } from '@cofenster/web-components';

const StyledImg = styled('img')(({ theme }) => ({
  height: 36,
  borderRadius: theme.shape.borderRadius,
}));

export const AppStoreCard: FC = () => {
  return (
    <GridContainer spacing={1} justifyContent="center">
      <GridItem xs={12} textAlign="center">
        <Text>i18n.AppStoreCard.availableFor</Text>
      </GridItem>
      <GridItem xs={6} textAlign={{ xs: 'right' }}>
        <a href="https://play.google.com/store/apps/details?id=com.cofenster.app">
          <StyledImg src="/assets/images/googleAppStore.png" alt="Google App Store" />
        </a>
      </GridItem>
      <GridItem xs={6} textAlign={{ xs: 'left' }}>
        <a href="https://apps.apple.com/app/cofenster/id1484331266">
          <StyledImg src="/assets/images/appleAppStore.png" alt="Apple App Store" />
        </a>
      </GridItem>
    </GridContainer>
  );
};
