import { styled } from '@mui/material';
import type { CSSProperties, ComponentPropsWithoutRef, FC, PropsWithChildren } from 'react';

import { Spacing, type SpacingDirectionalProps } from '../Spacing';

export type CardProps = ComponentPropsWithoutRef<'div'> & {
  spacing?: SpacingDirectionalProps;
  direction?: 'row' | 'column';
};

const Container = styled(Spacing)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'var(--flex-direction)' as CSSProperties['flexDirection'],
  flex: '1 1 100%',
  borderRadius: theme.shape['borderRadius-l'],
  backgroundColor: theme.palette.brand.white,
}));

export const Card: FC<PropsWithChildren<CardProps>> = ({
  children,
  direction = 'column',
  spacing = { all: { xs: 3, sm: 4 } },
  ...rest
}) => (
  <Container {...rest} {...spacing} style={{ '--flex-direction': direction } as CSSProperties} property="padding">
    {children}
  </Container>
);
