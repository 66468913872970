import { useCallback } from 'react';

import { type SelectedMediaDevice, useMediaDevice } from '@cofenster/web-components';

import { useTracking } from '../context/tracking';

export const useMediaDeviceWithTracking = (
  type: 'audioinput' | 'videoinput',
  defaultPick: 'first' | 'none' = 'first'
) => {
  const tracking = useTracking();

  const [availableDevices, selectedDevice, selectMediaDevice] = useMediaDevice(type, defaultPick);

  return [
    availableDevices,
    selectedDevice,
    useCallback(
      (device: SelectedMediaDevice) => {
        selectMediaDevice(device);
        tracking.trackEvent({
          event: 'recordSettingUpdated',
          details: {
            settingType: type === 'audioinput' ? 'microphone' : 'camera',
            ...(device
              ? {
                  settingName: device.label,
                  deviceId: device.deviceId,
                  deviceGroupId: device.groupId,
                  deviceKind: device.kind,
                }
              : { settingName: 'nodevice' }),
          },
        });
      },
      [tracking, type, selectMediaDevice]
    ),
  ] as const;
};
