import { type ComponentProps, type FC, type ReactNode, useState } from 'react';

import { Button } from '../../controls/Button';
import { Dialog, type DialogProps } from '../../feedback/Dialog/Dialog';
import { DialogActions } from '../../feedback/Dialog/DialogActions';
import { DialogContent } from '../../feedback/Dialog/DialogContent';
import { Text } from '../../typography/Text';

/**
 * @see useConfirmDialog
 */
export const ConfirmDialog: FC<{
  isOpen: boolean;
  closeDialog: VoidFunction;
  onCancel?: () => unknown;
  onConfirm: () => unknown;
  title: DialogProps['title'];
  titleI18nParams?: DialogProps['titleI18nParams'];
  content?: ReactNode;
  cancel?: ReactNode;
  confirm?: ReactNode;
  variant?: ComponentProps<typeof Button>['variant'];
}> & { DefaultContent: typeof DefaultContent } = ({
  isOpen,
  closeDialog,
  title,
  titleI18nParams,
  content,
  cancel = 'i18n.global.cancel',
  confirm = 'i18n.global.continue',
  variant = 'destructive',
  onCancel,
  onConfirm,
}) => {
  const [confirming, setConfirming] = useState(false);
  const [cancelling, setCancelling] = useState(false);

  const onCancelClick = async () => {
    try {
      setCancelling(true);
      await onCancel?.();
    } finally {
      setCancelling(false);
      closeDialog();
    }
  };

  const onConfirmClick = async () => {
    setConfirming(true);
    try {
      await onConfirm();
    } finally {
      setConfirming(false);
      closeDialog();
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onCancelClick}
      data-testid="confirm-dialog"
      title={title}
      titleI18nParams={titleI18nParams}
    >
      {content && (
        <DialogContent>
          {typeof content === 'string' ? <DefaultContent>{content}</DefaultContent> : content}
        </DialogContent>
      )}

      <DialogActions>
        <Button
          variant="tertiary"
          fullWidth
          onClick={onCancelClick}
          data-testid="confirm-dialog-left-action"
          loading={cancelling}
          disabled={confirming}
        >
          {cancel}
        </Button>
        <Button
          variant={variant}
          fullWidth
          onClick={onConfirmClick}
          data-testid="confirm-dialog-right-action"
          loading={confirming}
          disabled={cancelling}
        >
          {confirm}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DefaultContent: FC<ComponentProps<typeof Text>> = ({ variant = 'l', color = 'grey600', ...rest }) => (
  <Text variant={variant} color={color} {...rest} />
);

ConfirmDialog.DefaultContent = DefaultContent;
