import { type FC, useContext } from 'react';

import type { IconProps } from '../../assets/icons/Icon';
import { IconButton, type IconButtonProps } from '../../controls/Button/IconButton';
import { PopoverContext } from '../../feedback/Popover';

export const PopoverMenuTriggerIcon: FC<Omit<IconButtonProps, 'icon' | 'label'> & { icon?: IconProps['type'] }> = (
  props
) => {
  const context = useContext(PopoverContext);

  return (
    <IconButton
      label="i18n.global.popover.toggle"
      aria-pressed={context?.isOpen}
      // Make sure the trigger icon is *always* on top of the content so it remains actionable
      style={{ ...props.style, zIndex: 10 }}
      // The tooltip feels a little unnecessary for popover menu buttons
      tooltip={null}
      {...props}
      icon={props.icon ?? 'ThreeDotsIcon'}
    />
  );
};
