import { styled } from '@mui/material';
import type { FC, PropsWithChildren } from 'react';

import { LogoWide } from '../../assets/logos/LogoWide';
import { Button } from '../../controls/Button/Button';
import { GridContainer, GridItem } from '../../layout/Grid';
import { LegalNavigation } from '../../navigation/LegalNavigation';
import { Headline, Text } from '../../typography';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.brand.linen,
  minHeight: '100%',
  padding: theme.spacing(2, 0),
}));

const Header = styled('header')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  marginBottom: theme.spacing(3),
}));

const Main = styled('main')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  padding: theme.spacing(0, 4),
  marginBottom: theme.spacing(4),
}));

const Footer = styled('footer')(({ theme }) => ({
  padding: theme.spacing(0, 2),
}));

const Content = styled('div')(({ theme }) => ({
  borderRadius: theme.shape['borderRadius-l'],
  maxWidth: 600,

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(10),
  },
}));

const Picture = styled('img')(({ theme }) => ({
  display: 'block',
  height: 648,
  borderRadius: theme.spacing(3),
}));

const PageHeadline = styled(Headline)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  lineHeight: 1.2,
}));

const PageSubline = styled(Text)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

export const ErrorPage: FC<PropsWithChildren> = () => {
  return (
    <Container>
      <Header>
        <LogoWide size={82} />
      </Header>
      <Main>
        <GridContainer justifyContent="center" alignItems="center" spacing={4} flex="1">
          <GridItem display={{ xs: 'none', md: 'none', lg: 'flex' }} component="picture">
            <source srcSet="/assets/images/error-page.avif" type="image/avif" />
            <source srcSet="/assets/images/error-page.webp" type="image/webp" />
            <Picture src="/assets/images/error-page.png" alt="" />
          </GridItem>
          <GridItem>
            <Content>
              <PageHeadline variant="h1">i18n.global.errorPage.headline</PageHeadline>
              <PageSubline variant="xl" component="p">
                i18n.global.errorPage.content
              </PageSubline>
              <Button href="mailto:success@cofenster.com">i18n.global.errorPage.contactLink</Button>
            </Content>
          </GridItem>
        </GridContainer>
      </Main>
      <Footer>
        <LegalNavigation />
      </Footer>
    </Container>
  );
};
