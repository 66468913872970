import { styled } from '@mui/material';
import { type FC, Suspense, lazy } from 'react';
import type { Components } from 'react-markdown';
export type { Components as ReactMarkdownComponents } from 'react-markdown';

const ReactMarkdown = lazy(() => import('react-markdown'));

export const MarkdownComponents = {
  P: styled('p')(({ theme }) => ({ margin: theme.spacing(1, 0) })),
  Ul: styled('ul')(({ theme }) => ({ paddingLeft: theme.spacing(4) })),
  Ol: styled('ol')(({ theme }) => ({ paddingLeft: theme.spacing(4) })),
  Li: styled('li')(({ theme }) => ({ margin: theme.spacing(1, 0) })),
};

export const Markdown: FC<{ children?: string; components?: Components }> = ({ children, components }) => {
  if (!children) return null;

  return (
    <Suspense fallback={children}>
      <ReactMarkdown
        components={{
          br: () => null,
          hr: () => null,
          img: () => null,
          a: 'span',
          h1: MarkdownComponents.P as Components['h1'],
          h2: MarkdownComponents.P as Components['h2'],
          h3: MarkdownComponents.P as Components['h3'],
          h4: MarkdownComponents.P as Components['h4'],
          h5: MarkdownComponents.P as Components['h5'],
          h6: MarkdownComponents.P as Components['h6'],
          blockquote: MarkdownComponents.P as Components['blockquote'],
          li: MarkdownComponents.Li as Components['li'],
          ul: MarkdownComponents.Ul as Components['ul'],
          ol: MarkdownComponents.Ol as Components['ol'],
          p: MarkdownComponents.P as Components['p'],
          ...components,
        }}
      >
        {children}
      </ReactMarkdown>
    </Suspense>
  );
};
