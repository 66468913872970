import { useCallback, useMemo, useState } from 'react';

type SetTrue = VoidFunction;
type SetFalse = VoidFunction;
type SetState = (state: boolean) => void;
type Toggle = VoidFunction;

type UseBooleanState = (initial: boolean) => Readonly<[boolean, SetTrue, SetFalse, SetState, Toggle]>;

export const useBooleanState: UseBooleanState = (initial) => {
  const [state, setState] = useState(initial);
  const setTrue = useCallback(() => setState(true), []);
  const setFalse = useCallback(() => setState(false), []);
  const toggleState = useCallback(() => setState((currentState) => !currentState), []);

  return useMemo(
    () => [state, setTrue, setFalse, setState, toggleState] as const,
    [state, setTrue, setFalse, toggleState]
  );
};
