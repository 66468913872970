import { styled } from '@mui/material';

import { BaseButton } from './BaseButton';

// 1. Override the MUI styles setting the border width to 1px.
export const SecondaryButton = styled(BaseButton)(({ theme }) => ({
  color: 'currentcolor',

  '&, &:hover, &.Mui-disabled': {
    borderColor: 'currentcolor',
    borderWidth: 2, // 1
  },

  '&:hover, &:focus': {
    backgroundColor: 'rgb(10 36 44 / 0.06)',
  },

  '&.Mui-disabled': {
    cursor: 'not-allowed',
    color: theme.palette.brand.grey700,
    backgroundColor: 'transparent',
  },
}));
