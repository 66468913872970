import { useCallback } from 'react';

import { useFileUpload } from '@cofenster/web-components';

import { useAssetSizeLazyQuery } from '../../generated';
import { useInitImageUpload } from './useInitImageUpload';

export const useUploadImageAsset = () => {
  const initImageUpload = useInitImageUpload();
  const uploadFile = useFileUpload();

  const [getAssetSize] = useAssetSizeLazyQuery({ fetchPolicy: 'no-cache' });

  return useCallback(
    async (
      file: File,
      uploadId: string,
      onProgress: (id: string, progress: number) => unknown,
      signal: AbortController['signal']
    ) => {
      const result = await initImageUpload();

      if (!result.data?.initImageUpload) throw new Error('Failed to init image upload');

      const { uploadUrl, imageAssetId } = result.data.initImageUpload;

      const uploadResult = await uploadFile(uploadUrl, file, uploadId, onProgress, signal);

      if (!uploadResult) {
        // Upload failed or was aborted
        return undefined;
      }

      const assetSizeResult = await getAssetSize({ variables: { id: imageAssetId } });
      const assetSize = assetSizeResult.data?.assetSize;

      if (!assetSize || assetSize.startsWith('0')) {
        throw new Error(
          'Upload Failed: The file cannot be uploaded because it is empty (0 bytes). Ensure the file contains data and try again.'
        );
      }

      return imageAssetId;
    },
    [initImageUpload, uploadFile, getAssetSize]
  );
};
