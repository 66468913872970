import { Box, type BoxProps } from '@mui/material';
import type { FC, PropsWithChildren } from 'react';

// See: https://stackoverflow.com/a/49725198
type RequireAtLealinen<T> = {
  [K in keyof T]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<keyof T, K>>>;
}[keyof T];

// Taken directly from @mui/system
type ResponsiveStyleValue<T> = T | (T | null)[] | { [key: string]: T | null };

export type SpacingDirectionalProps = RequireAtLealinen<{
  all?: ResponsiveStyleValue<number>;
  vertical?: ResponsiveStyleValue<number>;
  horizontal?: ResponsiveStyleValue<number>;
  top?: ResponsiveStyleValue<number>;
  right?: ResponsiveStyleValue<number>;
  bottom?: ResponsiveStyleValue<number>;
  left?: ResponsiveStyleValue<number>;
}>;

export type SpacingProps = BoxProps & SpacingDirectionalProps & { property?: 'margin' | 'padding' };

export const Spacing: FC<PropsWithChildren<SpacingProps>> = ({
  children,
  property = 'margin',
  all,
  vertical,
  horizontal,
  top,
  right,
  bottom,
  left,
  ...rest
}) => (
  <Box
    {...rest}
    sx={{
      [`${property}Top`]: top ?? vertical ?? all,
      [`${property}Right`]: right ?? horizontal ?? all,
      [`${property}Bottom`]: bottom ?? vertical ?? all,
      [`${property}Left`]: left ?? horizontal ?? all,
    }}
  >
    {children}
  </Box>
);
