import { styled } from '@mui/material';

import type { BrandColor, TextVariants } from '../../../theming/theme';
import { preventForwardProps } from '../../../utilities/preventForwardProps';
import { BaseLink, type BaseLinkProps } from '../../controls/BaseLink';

export type TextLinkProps = Omit<BaseLinkProps, 'as'> & {
  variant?: TextVariants;
  color?: BrandColor;
  hoverColor?: BrandColor;
  underline?: boolean;
};

export const TextLink = styled(
  BaseLink,
  preventForwardProps(['variant', 'color', 'hoverColor', 'underline'])
)<TextLinkProps>(({ theme, variant = 'm', hoverColor = 'blue', color, underline }) => ({
  ...theme.typography[variant],
  color: color ? theme.palette.brand[color] : 'inherit',
  textDecoration: underline ? 'underline' : undefined,
  borderRadius: 4,

  '&:hover': {
    color: theme.palette.brand[hoverColor],
    textDecoration: 'underline',
  },

  '&:focus-visible': theme.mixins.focusRing,
}));
