import { styled } from '@mui/material';
import type { FC, PropsWithChildren } from 'react';

import { useCopyToClipboard } from '../../../hooks/utils/useCopyToClipboard';
import type { BrandColor } from '../../../theming/theme';
import { preventForwardProps } from '../../../utilities/preventForwardProps';
import { Icon } from '../../assets/icons/Icon';
import { IconButton } from '../../controls/Button/IconButton';
import { Headline } from '../../typography/Headline';

const Container = styled(
  'div',
  preventForwardProps(['backgroundColor'])
)<{ backgroundColor: BrandColor }>(({ theme, backgroundColor }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.brand[backgroundColor],
  borderRadius: theme.shape['borderRadius-l'],
  cursor: 'pointer',
  transition: 'all 0.150s ease-in-out',
  '&:hover': {
    color: theme.palette.brand.blue,
  },
}));

const IconButtonContainer = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

const IconContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  marginLeft: theme.spacing(1),
}));

type CopyDataToClipBoardProps = {
  headline: string;
  clipboardText: string;
  backgroundColor?: BrandColor;
};

export const CopyDataToClipBoard: FC<PropsWithChildren<CopyDataToClipBoardProps>> = ({
  children,
  clipboardText,
  headline,
  backgroundColor = 'white',
}) => {
  const { copyToClipboard, justCopiedToClipboard } = useCopyToClipboard();
  const copyCodeToClipboard = () => copyToClipboard(clipboardText);

  return (
    <Container onClick={copyCodeToClipboard} backgroundColor={backgroundColor}>
      {justCopiedToClipboard === clipboardText ? (
        <>
          <Headline variant="h5" component="p">
            {headline}
          </Headline>
          <IconContainer>
            <Icon type="CheckCircleIcon" weight="fill" size="s" color="positive" />
          </IconContainer>
        </>
      ) : (
        <>
          <Headline variant="h5" component="p">
            {children}
          </Headline>
          <IconButtonContainer>
            <IconButton
              icon="CopyIcon"
              iconSize="s"
              hoverColor="blue"
              onClick={copyCodeToClipboard}
              label="i18n.global.copyToClipboard"
            />
          </IconButtonContainer>
        </>
      )}
    </Container>
  );
};
