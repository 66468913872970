import { type FC, type PropsWithChildren, createContext } from 'react';

import { NOOP_TRACKING, type SegmentTracking, useSegment } from './useSegment';

export type TrackingProviderProps = {
  segmentWriteKey: string;
  initiallyDisabled?: boolean;
};

export const TrackingContext = createContext<SegmentTracking>(NOOP_TRACKING);

export const TrackingProvider: FC<PropsWithChildren<TrackingProviderProps>> = ({
  children,
  segmentWriteKey,
  initiallyDisabled,
}) => {
  const context = useSegment(segmentWriteKey, initiallyDisabled);

  return <TrackingContext.Provider value={context}>{children}</TrackingContext.Provider>;
};
