import { styled } from '@mui/material';
import type { ComponentProps } from 'react';

import type { BrandColor } from '../../../theming/theme';
import { preventForwardProps } from '../../../utilities/preventForwardProps';

export type BoxProps = ComponentProps<'div'> & {
  backgroundColor?: BrandColor;
  gap?: number;
  fullHeight?: boolean;
  padding?: number;
};

export const Box = styled(
  'div',
  preventForwardProps(['backgroundColor', 'gap', 'fullHeight', 'padding'])
)<BoxProps>(({ theme, backgroundColor = 'linen50', gap, fullHeight, padding }) => ({
  display: 'flex',
  position: 'relative',
  overflow: 'hidden',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: fullHeight ? '100%' : undefined,
  flex: fullHeight ? '1 1 100%' : undefined,
  backgroundColor: theme.palette.brand[backgroundColor],
  borderRadius: theme.shape['borderRadius-l'],
  gap: gap ? theme.spacing(gap) : undefined,
  padding: padding !== undefined ? theme.spacing(padding) : undefined,
}));
