export const SceneAssetLifecycleTranslationsEN = {
  'DeleteRecordingDialog.button': 'Delete recording',
  'DeleteRecordingDialog.description': 'Are you sure you want to delete your recording? This will delete the footage you just recorded.',
  'DeleteRecordingDialog.headline': 'Delete this recording?',
  'FilmingTipsDialog.description.backgroundNoise': 'Make sure that the sound quality of your recording is not disturbed',
  'FilmingTipsDialog.description.microphone': 'This is our recommendation for consistent sound quality!',
  'FilmingTipsDialog.description.position': 'Ensure your face is in the top third of the frame, around 1 meter away',
  'FilmingTipsDialog.headline.backgroundNoise': 'Avoid background noise',
  'FilmingTipsDialog.headline.microphone': 'Use an external microphone',
  'FilmingTipsDialog.headline.position': 'Good position and distance',
  'FilmingTipsDialogProps.gotIt': 'Got it',
  'FilmingTipsLink.learnMoreButton': 'Learn more',
  'FilmingTipsLink.text': 'How to look good on camera',
  'FilmingTipsLink.textSecondary': 'Three quick tips to create a high quality recording',
  'recordingWithNoMicrophoneConnected.content': 'We could not detect a microphone for your device. Check your system settings to verify your microphone is connected properly. You may have to reload this page after you connect it.',
  'recordingWithNoMicrophoneConnected.title': 'No microphone',
  'recordingWithoutMicrophone.confirm': 'Proceed anyway',
  'recordingWithoutMicrophone.content': 'You are about to record your video without sound. If you mean to do this you can proceed recording. Otherwise please select a microphone.',
  'recordingWithoutMicrophone.title': 'Microphone muted',
  'Recording.Capture.recordAnotherTake': 'Record another take',
  'Recording.ImageCapture.CloseInstructions': 'Close instructions',
  'Recording.ImageCapture.Dropzone.DragAndDrop.Title': 'Drag and drop your image here',
  'Recording.ImageCapture.NoPermission.Subtitle': 'We need to access your camera to record a picture.',
  'Recording.ImageCapture.NoPermission.Title': 'Enable camera',
  'Recording.ImageCapture.Upload': 'Upload an image',
  'Recording.ScreenCapture.Subtitle': 'You can now start the recording and come back here when you are done',
  'Recording.ScreenCapture.Title': 'Sharing {desktop, select, monitor {Desktop} window {Application} browser {Browser Tab} other {} }.',
  'Recording.UI.ChooseImage': 'Choose an image',
  'Recording.UI.NoCamera': 'No camera',
  'Recording.UI.RequestPermission': 'Request permission',
  'Recording.UI.TakeSnapshot': 'Take snapshot',
  'Recording.VideoCapture.RecordViaMobile': 'Record via mobile',
  'Recording.VideoCapture.Upload': 'Upload a video',
  'ScenePage.CameraPermissionError.text': 'Your current browser settings prevent the camera access.',
  'ScenePage.emptyTitle': 'No title',
  'ScenePage.GoBackButton.label': 'Go back',
  'ScenePage.InitialScreen.camera.description.callToAction.button': 'Allow access',
  'ScenePage.InitialScreen.camera.description.callToAction.description': 'Click the <link>lock icon in your browser bar</link> and give us permission to use your camera and microphone so you can record a video.',
  'ScenePage.InitialScreen.camera.description.callToAction.title': 'We need access to your camera and microphone so you can record this video',
  'ScenePage.InitialScreen.camera.title': 'Enable camera and microphone',
  'ScenePage.InitialScreen.image.description.callToAction.button': 'Allow access',
  'ScenePage.InitialScreen.screenRecording.description.callToAction.button': 'Enable screen sharing',
  'ScenePage.InitialScreen.screenRecording.description.callToAction.description': 'Click the <link>lock icon in your browser bar</link> and give us permission to use your microphone so you can record a screen.',
  'ScenePage.InitialScreen.screenRecording.description.callToAction.title': 'We need access to your screen and microphone so you can record this video',
  'ScenePage.InitialScreen.screenRecording.title': 'Enable screen sharing',
  'ScenePage.InitialScreen.screenRecordingStart.callToAction.title': 'Share screen',
  'ScenePage.InitialScreen.screenRecordingStart.description': 'Select the screen you want to share to start recording',
  'ScenePage.InitialScreen.screenRecordingStart.title': 'Start screen sharing',
  'ScenePage.InitialScreen.sharingNotSupported.description': 'Switch to a non-mobile device or use your phones built-in screen recording and upload the result',
  'ScenePage.InitialScreen.sharingNotSupported.howToRecordButton': 'How to record',
  'ScenePage.InitialScreen.sharingNotSupported.title': 'Screen sharing not supported',
  'ScenePage.InitialScreen.sharingNotSupported.uploadButton': 'Upload',
  'ScenePage.InitialScreen.uploadButton': 'Upload',
  'ScenePage.InitialScreen.uploadButtonImage': 'Upload image',
  'ScenePage.InitialScreen.uploadButtonVideo': 'Upload video',
  'ScenePage.InstructionsScreen.mobile.howToLookGoodOnCameraButton': 'How to look good on camera',
  'ScenePage.InstructionsScreen.mobile.startRecordingButton.resume': 'Resume recording',
  'ScenePage.InstructionsScreen.mobile.startRecordingButton.start': 'Start recording',
  'ScenePage.moreOptions.delete': 'Delete',
  'ScenePage.moreOptions.edit': 'Edit',
  'ScenePage.moreOptions.label': 'More actions',
  'ScenePage.NoTitleAndInstructionsTitle': 'No instructions for this scene',
  'ScenePage.openInApp': 'Open in app',
  'ScenePage.RecordingBarMobile.flipCamera': 'Flip camera',
  'ScenePage.RecordingBarMobile.pause': 'Pause recording',
  'ScenePage.RecordingBarMobile.resume': 'Resume recording',
  'ScenePage.RecordingBarMobile.start': 'Start recording',
  'ScenePage.ReviewRecording.cancelButton': 'Try again',
  'ScenePage.ReviewRecording.headline': 'Review and proceed',
  'ScenePage.ReviewRecording.saveButton': 'Confirm',
  'ScenePage.ScreenRecorder.filename': 'Your cofenster screen recording',
  'ScenePage.UploadingScreen.cancelUploadOrProcessing.image': 'Cancel',
  'ScenePage.UploadingScreen.cancelUploadOrProcessing.screen': 'Cancel',
  'ScenePage.UploadingScreen.cancelUploadOrProcessing.video': 'Cancel',
  'ScenePage.UploadingScreen.headline.optimizing.image': 'Optimizing your image',
  'ScenePage.UploadingScreen.headline.optimizing.screen': 'Optimizing your screen recording',
  'ScenePage.UploadingScreen.headline.optimizing.video': 'Optimizing your video',
  'ScenePage.UploadingScreen.headline.uploading.image': 'Uploading your image',
  'ScenePage.UploadingScreen.headline.uploading.screen': 'Uploading your screen recording',
  'ScenePage.UploadingScreen.headline.uploading.video': 'Uploading your video',
  'ScenePage.UploadsDisabled.headline': 'You can not contribute to this scene',
  'ScenePage.UploadsDisabled.subline': 'Get in touch with the video creator if you think this is a mistake',
  'ScenePage.WebcamImageCapture.filename': 'Your cofenster photo',
  'ScenePage.WebcamRecorder.filename': 'Your cofenster video',
  'ScenePage.WebcamRecorder.headline.initial': 'Click on the red button to start recording',
  'ScenePage.WebcamRecorder.headline.recording': 'You’re recording',
  'ScenePage.WebcamRecorder.orientationMismatchRecordingStoppedWarning': 'We stopped your recording due to the wrong device orientation. You can resume it.',
  'UploadRetakeVideoMessage.description': 'Your editor will trim and edit this recording for the final video.',
  'UploadRetakeVideoMessage.title': 'Not perfect yet? Don’t worry!',
};
