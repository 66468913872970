import { useCallback } from 'react';

import { useTrackEventMutation } from '../../generated';

export const useEventTracking = () => {
  // We willingly ignore the results of the mutation as it does nothing more
  // than tracking, and the backend doesn’t return any particular response or
  // error. This way, we instruct Apollo not to update the returned data, which
  // prevents unnecessary rerenders.
  const [trackEvent] = useTrackEventMutation({ ignoreResults: true });

  return useCallback(
    async (payload: unknown) => {
      try {
        await trackEvent({ variables: { payload } });
      } catch (error) {
        console.error(error);
      }
    },
    [trackEvent]
  );
};
