import type { FC, PropsWithChildren } from 'react';

import {
  CopyDataToClipBoard,
  Dialog,
  DialogContent,
  GridContainer,
  GridItem,
  Responsive,
  Typography,
} from '@cofenster/web-components';

import { AppStoreCard, Qrcode } from '../../../components/display';

export type OpenInAppDialogProps = {
  isOpen: boolean;
  closeDialog: () => unknown;
  code: string;
  qrcode: string;
};

export const OpenInAppDialog: FC<PropsWithChildren<OpenInAppDialogProps>> = ({ isOpen, code, qrcode, closeDialog }) => {
  return (
    <Dialog open={isOpen} onClose={closeDialog} title="i18n.OpenInAppDialog.headline.withQrCode">
      <DialogContent>
        <GridContainer mb={2}>
          <GridItem textAlign="center" xs={12}>
            <Responsive up="md">
              <Typography paragraph>i18n.OpenInAppDialog.description.withQrCode</Typography>
            </Responsive>
            <Responsive down="md">
              <Typography variant="h4" component="p" paragraph>
                i18n.OpenInAppDialog.headline.withoutQrCode
              </Typography>
              <Typography paragraph>i18n.OpenInAppDialog.description.withoutQrCode</Typography>
            </Responsive>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} md={8} margin="auto">
            <GridContainer justifyContent="center">
              <GridItem xs={12}>
                <Responsive up="md">
                  <Qrcode src={qrcode} />
                </Responsive>
              </GridItem>
              <GridItem xs={12} mb={{ xs: 4, md: 0 }}>
                <CopyDataToClipBoard
                  headline="i18n.CopyDataToClipBoard.codeCopied"
                  clipboardText={code}
                  backgroundColor="grey50"
                >
                  {code.replace(/(\w{3})/g, '$1 ')}
                </CopyDataToClipBoard>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem>
            <AppStoreCard />
          </GridItem>
        </GridContainer>
      </DialogContent>
    </Dialog>
  );
};
