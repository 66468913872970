import type { FC, PropsWithChildren } from 'react';

import { VIDEO_FORMATS, type VideoFormat } from '@cofenster/constants';

import { GridContainer, type GridContainerProps, GridItem } from '../../layout/Grid';
import { AspectRatioBox } from '../AspectRatioBox';

export type ContentAreaProps = GridContainerProps & { videoFormat: VideoFormat };

export const ContentArea: FC<PropsWithChildren<ContentAreaProps>> = ({
  children,
  videoFormat,
  width,
  ...containerProps
}) => {
  const { aspectRatio } = VIDEO_FORMATS[videoFormat];

  return (
    <GridContainer justifyContent="center" {...containerProps} minHeight={250}>
      <GridItem xs={12} display="flex" justifyContent="center">
        {aspectRatio > 1 ? (
          <AspectRatioBox ratio={aspectRatio} width="100%">
            {children}
          </AspectRatioBox>
        ) : aspectRatio < 1 ? (
          <AspectRatioBox ratio={aspectRatio} height="max(55vh, 500px)">
            {children}
          </AspectRatioBox>
        ) : (
          <AspectRatioBox ratio={aspectRatio} width="500px">
            {children}
          </AspectRatioBox>
        )}
      </GridItem>
    </GridContainer>
  );
};
