import { type Dispatch, type SetStateAction, useEffect, useRef, useState } from 'react';

type Response<T> = [T, Dispatch<SetStateAction<T>>];

export const usePersistedState = <T>(key: string, initialState: T, session = false): Response<T> => {
  const storage = useRef(session ? window.sessionStorage : window.localStorage);
  const [state, setState] = useState(() => {
    const storageValue = storage.current.getItem(key);
    if (storageValue === null) {
      return initialState;
    }
    return JSON.parse(storageValue);
  });

  useEffect(() => {
    state === undefined || state === null
      ? storage.current.removeItem(key)
      : storage.current.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
};
