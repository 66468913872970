import { split } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { ApolloClient, from } from '@apollo/client/core';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { type ErrorResponse, onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { getMainDefinition } from '@apollo/client/utilities';

export type ApiClient = ReturnType<typeof createApiClient>;

export type ApiClientOptions = {
  url: string;
  authBearer?: string | undefined;
  onError?: (error: ErrorResponse) => void;
};

export const createApiClient = ({ url, authBearer, onError: onErrorHandler }: ApiClientOptions) => {
  if (!url) throw new Error('Cannot instantiate an ApolloClient without an absolute URL');

  const errorLink = onError((error) => {
    const { graphQLErrors, networkError } = error;
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) => console.error('[GraphQL Error]', message, locations, path));
    if (networkError) console.log('[Network error]', networkError);
    onErrorHandler?.(error);
  });

  const authLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      ...(authBearer ? { authorization: `Bearer ${authBearer}` } : undefined),
    },
  }));

  const retryLink = new RetryLink({
    delay: { initial: 500 },
    attempts: {
      max: 5,
      retryIf: (error, _operation) => {
        const skipOnErrors = ['401', '403', '404', '429', '400'];
        return !skipOnErrors.some((code) => error.message.includes(code));
      },
    },
  });
  const retryQueriesLink = split((operation) => {
    const definition = getMainDefinition(operation.query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'query';
  }, retryLink);

  const httpLink = new BatchHttpLink({
    uri: url,
    credentials: 'include',
    fetchOptions: { credentials: 'include' },
  });

  return new ApolloClient({
    link: from([errorLink, authLink, retryQueriesLink, httpLink]),
    cache: new InMemoryCache({
      typePolicies: {
        // RenderDescription can be generated with or without subtitles. There are
        // places where we want to use multiple RenderJobs (that use RenderDescription
        // as a field) - one for the main video and one for each subtitled video.
        // RenderDescription shares the same id no matter if there are subtitles or
        // not, but the content is different, so it shouldn't be treated as the same
        // value. Because subtitlesLanguage isn't stored on the RenderDescription, a
        // composite key (id + subtitlesLanguage) can't be used. As a result,
        // normalization is disabled for the RenderDescription type.
        RenderDescription: {
          keyFields: false,
        },
      },
    }),
  });
};
