import type { FC } from 'react';

import { SvgIcon, type SvgIconProps } from '../SvgIcon';

export const AddSceneIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 32 32" {...props}>
    <path
      d="M24 7H8C6.89551 7 6 7.89545 6 9V25C6 26.1046 6.89551 27 8 27H17C17.5527 27 18 27.4477 18 28C18 28.5523 17.5527 29 17 29H8C5.79102 29 4 27.2092 4 25V9C4 6.79083 5.79102 5 8 5H24C26.209 5 28 6.79083 28 9V18C28 18.5523 27.5527 19 27 19C26.4473 19 26 18.5523 26 18V9C26 7.89545 25.1045 7 24 7Z"
      fill="#0A242C"
    />
    <path
      d="M22 20C22 19.4477 22.4473 19 23 19C23.5527 19 24 19.4477 24 20V23H27C27.5527 23 28 23.4477 28 24C28 24.5523 27.5527 25 27 25H24V28C24 28.5523 23.5527 29 23 29C22.4473 29 22 28.5523 22 28V25H19C18.4473 25 18 24.5523 18 24C18 23.4477 18.4473 23 19 23H22V20Z"
      fill="#0A242C"
    />
  </SvgIcon>
);
