import { AnalyticsBrowser } from '@segment/analytics-next';
import { useMemo } from 'react';

import { usePersistedState } from '../../hooks/state/usePersistedState';

type RoleName = 'Admin' | 'Contributor' | 'Editor' | 'Publisher' | 'Translator';

export type TrackDetails = Record<string, unknown>;

export type UserTrack = {
  userId: string;
  details?: TrackDetails;
  userRole: RoleName;
};

export type EventTrack = {
  event: string;
  details?: TrackDetails;
  userContextRole?: RoleName;
  accountId?: string;
};

export type GroupTrack = {
  groupId: string;
  details?: TrackDetails;
};

export type PageTrack = {
  category: string;
  details?: TrackDetails;
};

export type SegmentTracking = {
  identifyUser: (track: UserTrack) => unknown;
  trackGroup: (track: GroupTrack) => unknown;
  trackEvent: (track: EventTrack) => unknown;
  trackPage: (track: PageTrack) => unknown;
  isDisabled: boolean | undefined;
  disable: () => unknown;
  enable: () => unknown;
  clear: () => unknown;
};

export const NOOP_TRACKING = {
  identifyUser: () => null,
  trackGroup: () => null,
  trackEvent: () => null,
  trackPage: () => null,
  isDisabled: false,
  enable: () => null,
  disable: () => null,
  clear: () => null,
};

type FinsweetCookie = {
  id: string;
  consents: {
    analytics: boolean;
    essential: boolean;
    marketing: boolean;
    personalization: boolean;
    uncategorized: boolean;
  };
};

const parseCookieValue = (value: string): FinsweetCookie | null => {
  const decodedValue = decodeURIComponent(value);

  try {
    return JSON.parse(decodedValue);
  } catch {
    try {
      // For some reason, the cookie value appears to be encoded *twice* (as in
      // it needs 2 `decodeURIComponent` passes to get the value out).
      return JSON.parse(decodeURIComponent(decodedValue));
    } catch {
      return null;
    }
  }
};

// The marketing website uses “Finsweet Cookie Consent” to manage tracking
// consent. This tool defines a cookie called `fs-cc` which we can read (if set)
// to know whether the user has already visited our website and granted or
// declined to tracking.
// See: https://finsweet.com/cookie-consent
const useCookieConsentFromWebsite = () => {
  const cookieName = 'fs-cc';
  const cookie = document.cookie.split(';').find((cookie) => cookie.trim().startsWith(cookieName));

  // The `fs-cc` cookie does not exist, so we do not know whether we can track
  // or not.
  if (!cookie) {
    return undefined;
  }

  const cookieValue = cookie.split('=')[1] ?? '';
  const decodedCookieValue = parseCookieValue(cookieValue);

  // The `fs-cc` cookie exists but is in a format we do not recognize, so we do
  // not know whether we can track or not.
  if (!decodedCookieValue) {
    return undefined;
  }

  // The `fs-cc` cookie exists and we can read the recorded consent to know
  // whether we can track or not.
  // The null coalescing operator is here to avoid the following error:
  // https://cofenster.sentry.io/issues/5359163776/?alert_rule_id=14614539&alert_type=issue&environment=production&notification_uuid=9859e469-ed72-4b04-982b-571b0b38b88d&project=6158757&referrer=slack
  return decodedCookieValue.consents?.analytics ?? decodedCookieValue.consents?.marketing ?? false;
};

export const useSegment = (writeKey: string, initiallyDisabled: boolean | undefined): SegmentTracking => {
  const hasAcceptedWebsiteCookies = useCookieConsentFromWebsite();
  const [isDisabled, setIsDisabled] = usePersistedState<boolean | undefined>(
    // Note that this is no longer a CoCapture-only cookie; it is also used in the
    // CoManager but the name cannot be changed without causing everyone to have to
    // confirm their preferences again.
    'actor-cookies-disabled',
    hasAcceptedWebsiteCookies ?? initiallyDisabled
  );

  return useMemo(() => {
    if (!writeKey) {
      console.error('No write key provided for tracking');

      return NOOP_TRACKING;
    }

    const analytics = AnalyticsBrowser.load({ writeKey }, { disableClientPersistence: isDisabled !== false });

    const identifyUser = ({ userId, details }: UserTrack) => analytics.identify(userId, details);
    const trackGroup = ({ groupId, details }: GroupTrack) => analytics.group(groupId, details);
    const trackEvent = ({ event, details, userContextRole, accountId }: EventTrack) =>
      analytics.track(event, {
        ...details,
        userContextRole,
        accountId: accountId ?? details?.accountId,
      });
    const trackPage = ({ category, details }: PageTrack) => analytics.page(category, details);

    return {
      isDisabled,
      identifyUser,
      trackGroup,
      trackEvent,
      trackPage,
      enable: () => setIsDisabled(false),
      disable: () => setIsDisabled(true),
      clear: () => setIsDisabled(undefined),
    };
  }, [writeKey, isDisabled, setIsDisabled]);
};
