import { MimeTypes, VIDEO_FORMATS, type VideoFormat } from '@cofenster/constants';

import { type I18nParams, useI18n } from '../../services/i18n';

export function getUploadHint(
  translate: (key: string, values?: I18nParams) => string,
  type: keyof typeof MimeTypes,
  videoFormat?: VideoFormat | null
) {
  const fileFormat = Array.from(new Set(Object.values(MimeTypes[type]).flat())).join(', ');

  if (!videoFormat) {
    return `(${fileFormat})`;
  }

  const { width, height } = VIDEO_FORMATS[videoFormat];
  const dimensions = `${width}×${height}px`;
  const hintText = translate('global.upload.hint', { fileFormat, dimensions });

  return `(${hintText})`;
}

export const useUploadHint = (
  type: Parameters<typeof getUploadHint>[1],
  videoFormat?: Parameters<typeof getUploadHint>[2]
) => {
  const { translate } = useI18n();

  return getUploadHint(translate, type, videoFormat);
};
