import { useCallback, useMemo, useState } from 'react';

import { useTimeout } from './useTimeout';

export const useCopyToClipboard = (clearAfter = 1000) => {
  const [justCopiedToClipboard, setJustCopiedToClipboard] = useState<string | null>(null);

  const copyToClipboard = useCallback(async (text: string) => {
    await navigator.clipboard.writeText(text);
    setJustCopiedToClipboard(text);
  }, []);

  useTimeout(
    useMemo(() => (justCopiedToClipboard ? () => setJustCopiedToClipboard(null) : null), [justCopiedToClipboard]),
    clearAfter
  );

  return { copyToClipboard, justCopiedToClipboard };
};
