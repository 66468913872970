import { useCallback } from 'react';
import type { SelectedMediaDevice } from '../../../../../web-components/src/hooks/media/useMediaDevice';
import { useConfirmDialog } from '../../../hooks/useConfirmDialog';

export const useStartRecordingWithNoMicInfo = (
  availableMicrophones: SelectedMediaDevice[] | undefined,
  selectedMicrophone: SelectedMediaDevice,
  startRecording: VoidFunction | undefined
) => {
  const isNoDeviceConnected = !availableMicrophones?.length;
  const confirmRecordWithoutMicrophone = useConfirmDialog({
    title: isNoDeviceConnected
      ? 'i18n.recordingWithNoMicrophoneConnected.title'
      : 'i18n.recordingWithoutMicrophone.title',
    content: isNoDeviceConnected
      ? 'i18n.recordingWithNoMicrophoneConnected.content'
      : 'i18n.recordingWithoutMicrophone.content',
    confirm: 'i18n.recordingWithoutMicrophone.confirm',
    variant: 'primary',
  });

  const onStart = useCallback(async () => {
    if (!startRecording) return;

    if (!selectedMicrophone || isNoDeviceConnected) {
      if (await confirmRecordWithoutMicrophone()) {
        startRecording();
      }
    } else {
      startRecording();
    }
  }, [selectedMicrophone, confirmRecordWithoutMicrophone, startRecording, isNoDeviceConnected]);

  return { onStart };
};
