import type { FC, PropsWithChildren } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  GridContainer,
  GridItem,
  Text,
  styled,
} from '@cofenster/web-components';

const StyledButton = styled(Button)(({ theme }) => ({
  '&:hover': {
    color: theme.palette.brand.grey600,
  },
}));

export type DeleteContributionDialogProps = {
  onDelete: () => unknown;
  isOpen: boolean;
  closeDialog: () => unknown;
};

export const DeleteContributionDialog: FC<PropsWithChildren<DeleteContributionDialogProps>> = ({
  onDelete,
  isOpen,
  closeDialog,
  ...rest
}) => {
  const onAction = async () => {
    onDelete();
    closeDialog();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      {...rest}
      data-testid="delete-contribution-dialog"
      title="i18n.DeleteContributionDialog.headline"
    >
      <DialogContent>
        <Text variant="l" color="grey600" component="p">
          i18n.DeleteContributionDialog.description
        </Text>
      </DialogContent>
      <DialogActions>
        <GridContainer spacing={1}>
          <GridItem xs={12} md={6}>
            <StyledButton variant="secondary" fullWidth onClick={closeDialog} data-testid="cancel-button">
              i18n.global.cancel
            </StyledButton>
          </GridItem>
          <GridItem xs={12} md={6} onClick={onAction}>
            <Button variant="destructive" fullWidth data-testid="delete-button">
              i18n.DeleteContributionDialog.button
            </Button>
          </GridItem>
        </GridContainer>
      </DialogActions>
    </Dialog>
  );
};
