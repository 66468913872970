import { Button as MuiButton, type ButtonProps as MuiButtonProps, styled } from '@mui/material';
import { type ComponentProps, type FC, forwardRef } from 'react';

// 1. It is important for a button to have a border in order to be perceptible
//    when running in Windows High-Contrast Mode (WHCM).
const StyledButton = styled(MuiButton)(({ theme }) => ({
  ...theme.typography['button-m'],
  border: '2px solid transparent', // 1
  padding: theme.spacing(1.5, 3),
  minHeight: 48,

  '&:focus-visible': theme.mixins.focusRing,
}));

export type BaseButtonProps = Omit<MuiButtonProps, 'size' | 'type'> & {
  component?: string | FC | unknown;
  htmlFor?: string;
  target?: '_blank';
  download?: ComponentProps<'a'>['download'];
  // This component applies `type="button"` by default which, while being a good
  // default for buttons, yields invalid HTML when used to render links. Passing
  // `type={undefined}` wouldn’t work since it would cause the default value to
  // be applied, which is why we need to accept `null` as a value, and skip
  // rendering the `type` attribute if set as such.
  type?: MuiButtonProps['type'] | null;
};

export const BaseButton = forwardRef<HTMLButtonElement, BaseButtonProps>(function BaseButton(
  { type = 'button', ...rest },
  ref
) {
  return <StyledButton disableRipple variant="contained" type={type ?? undefined} {...rest} ref={ref} />;
});
