import { useCallback } from 'react';

import type { VideoCaptureSource, VideoFit, VideoFormat } from '@cofenster/constants';

import { useFileUpload } from '@cofenster/web-components';
import { useAssetSizeLazyQuery } from '../../generated';
import { useInitVideoUpload } from './useInitVideoUpload';

export const useUploadVideoAsset = () => {
  const initVideoUpload = useInitVideoUpload();
  const uploadFile = useFileUpload();

  const [getAssetSize] = useAssetSizeLazyQuery({ fetchPolicy: 'no-cache' });

  return useCallback(
    async (
      file: File,
      uploadId: string,
      onProgress: (id: string, progress: number) => unknown,
      abortSignal: AbortController['signal'],
      options?: {
        videoFormat?: VideoFormat;
        videoFit?: VideoFit;
        videoCaptureSource?: VideoCaptureSource;
      }
    ) => {
      const { videoFormat, videoFit, videoCaptureSource } = options ?? {};
      const result = await initVideoUpload(videoFormat, videoFit, videoCaptureSource);

      if (!result.data?.initVideoUpload) throw new Error('Failed to initialize video upload');

      const { uploadUrl, videoAssetId } = result.data.initVideoUpload;

      const uploadingResult = await uploadFile(uploadUrl, file, uploadId, onProgress, abortSignal);

      if (!uploadingResult) {
        // Upload failed or was aborted
        return undefined;
      }

      const assetSizeResult = await getAssetSize({ variables: { id: videoAssetId } });
      const assetSize = assetSizeResult.data?.assetSize;

      if (!assetSize || assetSize.startsWith('0')) {
        throw new Error(
          'Upload Failed: The file cannot be uploaded because it is empty (0 bytes). Ensure the file contains data and try again.'
        );
      }

      return videoAssetId;
    },
    [initVideoUpload, uploadFile, getAssetSize]
  );
};
