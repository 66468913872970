import { styled } from '@mui/material';
import { type ComponentProps, type FC, type ReactNode, useCallback, useMemo, useState } from 'react';
import { type Accept, type DropzoneState, type ErrorCode, type FileRejection, useDropzone } from 'react-dropzone';

import { preventForwardProps } from '../../../../utilities/preventForwardProps';
import { Box, type BoxProps } from '../../../layout/Box';

export type DropzoneError = ErrorCode | 'upload-failed';

export type DropzoneErrorMessages = Record<DropzoneError, string>;

const StyledBox = styled(
  Box,
  preventForwardProps(['isDragActive', 'disabled'])
)<{ isDragActive: boolean; disabled?: boolean }>(({ theme, isDragActive, disabled }) => ({
  overflow: 'visible',
  cursor: disabled ? 'default' : 'pointer',
  transition: 'border-color 150ms ease-out',
  border: `1px solid ${isDragActive ? theme.palette.brand.blue : theme.palette.brand.grey100}`,

  '&:hover': {
    borderColor: disabled ? undefined : theme.palette.brand.carbon,
  },

  '&:focus-visible': theme.mixins.focusRing,
}));

// The max size limit for AWS pre-signed URLs is 5Gb, so this seems like a
// reasonable upper bound for this component. Otherwise, uploading a file over
// 5Gb will fail silently because the pre-signing will error but the frontend
// will not prevent such a file from being uploaded.
// See: https://aws.amazon.com/s3/faqs/#:~:text=Individual%20Amazon%20S3%20objects%20can,using%20the%20multipart%20upload%20capability
export const DEFAULT_DROPZONE_MAX_SIZE = 1024 ** 3 * 5;

export const DEFAULT_UPLOAD_ERRORS: DropzoneErrorMessages = {
  'file-invalid-type': 'i18n.global.error.upload.file-invalid-type',
  'file-too-large': 'i18n.global.error.upload.file-too-large',
  'file-too-small': 'i18n.global.error.upload.file-too-small',
  'too-many-files': 'i18n.global.error.upload.too-many-files',
  'upload-failed': 'i18n.global.error.upload.upload-failed',
};

const useDropAccepted = (
  onFile: DropzoneProps['onFile'],
  onMultipleFiles: DropzoneProps['onMultipleFiles'],
  multiple: DropzoneProps['multiple']
) => {
  return useCallback(
    (acceptedFiles: File[]) => (multiple ? onMultipleFiles?.(acceptedFiles) : onFile?.(acceptedFiles[0] as File)),
    [onFile, onMultipleFiles, multiple]
  );
};

const useDropRejected = (onError: DropzoneProps['onError'], errorMessages: Partial<DropzoneErrorMessages>) => {
  return useCallback(
    (rejections: FileRejection[]) => {
      if (!onError) return;

      // We take the first rejection
      // If there is an error we don't proceed with the file upload
      const rejection = rejections[0];
      const errorCode = rejection?.errors[0]?.code as string | undefined;
      const error =
        errorMessages[errorCode as DropzoneError] ??
        errorMessages['upload-failed'] ??
        DEFAULT_UPLOAD_ERRORS['upload-failed'];
      const fileName = rejection?.file?.name ?? '';

      return onError(error, { fileName });
    },
    [errorMessages, onError]
  );
};

type SingleFileUpload = {
  multiple?: false;
  onFile: (file: File) => unknown;
  onMultipleFiles?: never;
};

type MultipleFileUpload = {
  multiple: true;
  onFile?: never;
  onMultipleFiles: (files: File[]) => unknown;
};

type FileUpload = SingleFileUpload | MultipleFileUpload;

export type DropzoneProps = Omit<BoxProps, 'onError' | 'children'> & {
  // Required props
  mime: Accept;
  children: (dropzoneState: {
    isDragActive: DropzoneState['isDragActive'];
    isInteractedWith: boolean;
    maxSize: number;
  }) => ReactNode;
  // Optional props
  disabled?: boolean;
  maxSize?: number;
  onError?: (message: string, options?: { fileName: string }) => unknown;
  errorMessages?: Partial<DropzoneErrorMessages>;
  inputProps?: ComponentProps<'input'>;
} & FileUpload;

export const Dropzone: FC<DropzoneProps> = ({
  children,
  mime,
  onFile,
  onMultipleFiles,
  onError,
  maxSize = DEFAULT_DROPZONE_MAX_SIZE,
  errorMessages = DEFAULT_UPLOAD_ERRORS,
  backgroundColor = 'linen50',
  inputProps,
  multiple = false,
  disabled = false,
  ...rest
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const onMouseEnter = useCallback(() => setIsHovered(true), []);
  const onMouseLeave = useCallback(() => setIsHovered(false), []);
  const onFocus = useCallback(() => setIsFocused(true), []);
  const onBlur = useCallback(() => setIsFocused(false), []);

  const onDropAccepted = useDropAccepted(onFile, onMultipleFiles, multiple);
  const onDropRejected = useDropRejected(onError, errorMessages);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    disabled,
    onDrop: (acceptedFiles, rejectedFiles) => {
      // Needed since in the bulk-upload we don't want to start the upload
      // if a file contains an error like a wrong type
      return rejectedFiles.length > 0 ? onDropRejected(rejectedFiles) : onDropAccepted(acceptedFiles);
    },
    multiple,
    accept: mime,
    // Ensure empty files cannot be uploaded
    // See: https://www.notion.so/cofenster/CoManager-Prevent-uploading-0-byte-files-982dc18690be408987220e81dade7e09?pvs=4
    minSize: 1,
    maxSize,
  });

  const isInteractedWith = useMemo(() => isHovered || isFocused || isDragActive, [isHovered, isFocused, isDragActive]);

  return (
    <StyledBox
      {...rest}
      {...getRootProps({ onMouseEnter, onMouseLeave, onFocus, onBlur })}
      backgroundColor={isDragActive ? 'blue100' : backgroundColor}
      isDragActive={isDragActive}
      disabled={disabled}
      fullHeight
    >
      <input {...(getInputProps({ disabled }) as ComponentProps<'input'>)} {...inputProps} />
      {children({ isInteractedWith, isDragActive, maxSize: maxSize ?? 0 })}
    </StyledBox>
  );
};
