import { useFormikContext } from 'formik';
import type { FC } from 'react';

import { Icon } from '../../assets/icons/Icon';
import { Alert, type AlertProps } from '../../display/Alert';
import { Spacing } from '../../layout/Spacing';

export const FormError: FC<Partial<AlertProps>> = ({ ...rest }) => {
  const { status } = useFormikContext();

  return status?.error ? (
    <Spacing top={2}>
      <Alert icon={<Icon size="s" type="WarningCircleIcon" />} {...rest} severity="error">
        {status.error}
      </Alert>
    </Spacing>
  ) : null;
};
