import { styled } from '@mui/material';
import type { FC } from 'react';

import { Icon } from '../../assets/icons/Icon';
import { Typography } from '../../typography/Typography';

import { HiddenInput } from '../HiddenInput';
import type { SelectableBoxInputProps } from './types';

const ContentContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  gap: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.brand.grey200}`,
  transition: '250ms border-color',
}));

const CheckedIcon = styled(Icon)(() => ({ marginLeft: 'auto', flexShrink: 0 }));

const Label = styled('label')(({ theme }) => ({
  display: 'block',
  position: 'relative',
  margin: theme.spacing(1, 0),
}));

export const SelectableBoxInput: FC<SelectableBoxInputProps> = ({
  id,
  checkedIcon = 'CheckIcon' as const,
  title,
  description,
  mainIcon,
  secondaryIcon,
  titleProps,
  ...inputProps
}) => {
  return (
    <>
      <Label htmlFor={id}>
        <HiddenInput id={id} {...inputProps} />
        <ContentContainer>
          {mainIcon}
          <div>
            <Typography variant="h6" component="p" {...titleProps}>
              {title}
            </Typography>
            {!!description && <Typography component="p">{description}</Typography>}
          </div>
          <CheckedIcon type={checkedIcon} style={{ visibility: inputProps.checked ? 'visible' : 'hidden' }} />
        </ContentContainer>
      </Label>
    </>
  );
};
