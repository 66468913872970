import type { FC, ReactNode } from 'react';

import { Box, Button, type ButtonProps, styled } from '@cofenster/web-components';

const StyledButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  textAlign: 'center',
  gap: theme.spacing(1),
  minHeight: theme.spacing(9),
  width: '100%',
  borderRadius: theme.shape['borderRadius-l'],

  '&:focus-visible': theme.mixins.focusRing,
})) as FC<ButtonProps>;

const WrappingBox = styled(Box)(() => ({
  overflow: 'visible',
}));

export const InfoBanner: FC<{ children: (Button: FC<ButtonProps>) => ReactNode }> = ({ children }) => {
  return <WrappingBox backgroundColor="white">{children(StyledButton)}</WrappingBox>;
};

InfoBanner.displayName = 'InfoBanner';
