import { useEffect } from 'react';

export const useTimeout = (callback: (() => unknown) | null, timeout = 1000) => {
  useEffect(() => {
    if (callback && timeout > 0) {
      const handle = setTimeout(callback, timeout);
      return () => {
        clearTimeout(handle);
      };
    }
  }, [callback, timeout]);
};
