import { useCallback } from 'react';

import {
  type InitVideoUploadMutationResult,
  type InitVideoUploadMutationVariables,
  useInitVideoUploadMutation,
} from '../../generated';

export type VideoAssetUpload = NonNullable<InitVideoUploadMutationResult['data']>['initVideoUpload'];
export type UploadPost = VideoAssetUpload['uploadPost'];

export const useInitVideoUpload = () => {
  const [initVideoUpload] = useInitVideoUploadMutation();
  return useCallback(
    (
      videoFormat?: InitVideoUploadMutationVariables['videoFormat'],
      videoFit?: InitVideoUploadMutationVariables['videoFit'],
      videoCaptureSource?: InitVideoUploadMutationVariables['videoCaptureSource']
    ) => initVideoUpload({ variables: { videoFormat, videoFit, videoCaptureSource } }),
    [initVideoUpload]
  );
};
