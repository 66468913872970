import type { FC } from 'react';

import { SvgIcon, type SvgIconProps } from '../SvgIcon';

export const FolderEmptyClosed: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 32 32" {...props}>
    <path
      d="M6.66699 24.1666V8.6929C6.66699 8.46491 6.75639 8.24625 6.91551 8.08504C7.07463 7.92382 7.29044 7.83325 7.51548 7.83325H13.172C13.3556 7.83325 13.5343 7.89358 13.6811 8.00518L16.6225 10.2403C16.7694 10.3519 16.9481 10.4122 17.1316 10.4122H24.4852C24.7102 10.4122 24.926 10.5028 25.0851 10.664C25.2443 10.8252 25.3337 11.0439 25.3337 11.2718V13.8508"
      fill="#D3A530"
    />
    <path
      d="M6.66699 24.1667L8.73114 13.0806C8.78868 12.9116 8.89908 12.7645 9.0467 12.6603C9.19433 12.5561 9.37168 12.5 9.55365 12.5H26.8C26.9374 12.5 27.0728 12.532 27.1951 12.5933C27.3173 12.6546 27.423 12.7435 27.5033 12.8526C27.5837 12.9618 27.6364 13.0881 27.6571 13.2211C27.6778 13.3541 27.6659 13.49 27.6225 13.6177L25.7411 24.1667H6.66699Z"
      fill="#F4B922"
    />
  </SvgIcon>
);
