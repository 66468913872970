import { useEffect } from 'react';

type AxeObserver = {
  observe: (target: Document) => void;
  disconnect: VoidFunction;
};

// See: https://github.com/dequelabs/axe-core/blob/develop/axe.d.ts
type Result = {
  id: string;
  nodes: NodeResult[];
};

type NodeResult = {
  target: string[];
};

const filterViolations = (violation: Result) => {
  if (violation.id === 'region') {
    violation.nodes = violation.nodes.filter((node: NodeResult) => {
      // The recommendation is to have a node right after the opening <body>
      // tag to help with announcing page changes to screan-readers. It makes
      // axe return a violation, that we can safely ignore.
      if (node.target.includes('#a11y-document-title')) return false;

      return true;
    });
  } else if (violation.id === 'aria-allowed-role') {
    violation.nodes = violation.nodes.filter((node: NodeResult) => {
      // The Intercom chat widget contains an ARIA violation but we cannot
      // address it so might as well ignore it to keep the logs clean.
      if (node.target.includes('.intercom-launcher-frame')) return false;

      return true;
    });
  }

  return violation.nodes.length > 0;
};

export const useAxe = (environment: string) => {
  useEffect(() => {
    if (!['local', 'development'].includes(environment)) return;
    if ('Cypress' in window) return;

    let observer: AxeObserver | null = null;

    // @ts-expect-error
    import(/* webpackIgnore: true */ 'https://unpkg.com/agnostic-axe@3')
      .then(({ AxeObserver, logViolations }) => {
        observer = new AxeObserver((violations: Result[]) => {
          const filteredViolations = violations.filter(filterViolations);
          logViolations(filteredViolations);
        });
        if (observer) observer.observe(document);
      })
      .catch((error) => console.error(error));

    return () => {
      if (observer) observer.disconnect();
    };
  }, [environment]);
};
