import { Snackbar as MuiSnackbar, styled } from '@mui/material';
import type { FC, PropsWithChildren } from 'react';

import type { I18nParams } from '../../../services/i18n';
import { Alert } from '../../display/Alert';
import { Container } from '../../layout/Container';

const StyledAlert = styled(Alert)<{ severity: SnackbarVariant }>(({ theme, severity }) => {
  const variantStyles = {
    success: {
      backgroundColor: theme.palette.brand.positive,
      color: theme.palette.brand.carbon,
    },
    info: {
      backgroundColor: theme.palette.brand.carbon,
      color: theme.palette.brand.white,
    },
    warning: {
      backgroundColor: theme.palette.brand.warningDark,
      color: theme.palette.brand.carbon,
    },
    error: {
      backgroundColor: theme.palette.brand.negative,
      color: theme.palette.brand.white,
    },
  };

  if (severity in variantStyles) {
    return variantStyles[severity];
  }

  return {
    backgroundColor: theme.palette.brand.carbon,
    color: theme.palette.brand.white,
  };
});

type SnackbarVariant = 'success' | 'info' | 'warning' | 'error';

export type SnackbarProps = PropsWithChildren<{
  open: boolean;
  onClose: VoidFunction;
  variant?: SnackbarVariant;
  'data-testid'?: string;
  i18nParams?: I18nParams;
  doNotCloseOnClickOutside?: boolean;
}>;

export const Snackbar: FC<SnackbarProps> = ({
  open,
  onClose,
  variant = 'info',
  'data-testid': dataTestId,
  children,
  i18nParams,
  doNotCloseOnClickOutside,
}) => (
  <Container>
    <MuiSnackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      autoHideDuration={5000}
      onClose={onClose}
      ClickAwayListenerProps={doNotCloseOnClickOutside ? { mouseEvent: false, touchEvent: false } : undefined}
      data-testid={dataTestId}
    >
      <div>
        <StyledAlert onClose={onClose} severity={variant} i18nParams={i18nParams}>
          {children}
        </StyledAlert>
      </div>
    </MuiSnackbar>
  </Container>
);
