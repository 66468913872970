import { useContext, useMemo } from 'react';

import type { ContributionRequestList } from '../../api/hooks/contributionRequestList/useContributionRequestListByActor';
import { TasksContext, TasksContributionsContext } from './TaskProgressProvider';

export const useTasksProgress = (list: ContributionRequestList) => {
  const taskIds = useContext(TasksContext);
  const [contributionsByTaskId] = useContext(TasksContributionsContext);

  return useMemo(() => {
    const totalCount = taskIds.length;
    const doneList = taskIds
      .filter((taskId) => contributionsByTaskId[taskId]?.length)
      .filter((taskId) => {
        const task = list.requests.find((request) => request.id === taskId);
        const contributedAssetIds = contributionsByTaskId[taskId];
        if (!task || !contributedAssetIds?.length) return false;

        const videoAssets = task.videoAssets.filter((asset) => contributedAssetIds.includes(asset.id));
        const imageAssets = task.imageAssets.filter((asset) => contributedAssetIds.includes(asset.id));

        return (
          videoAssets.every((asset) => asset.status !== 'Error') &&
          imageAssets.every((asset) => asset.status !== 'Error')
        );
      });
    const doneCount = doneList.length;
    return { totalCount, doneCount, doneList };
  }, [taskIds, contributionsByTaskId, list]);
};
