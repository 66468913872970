import { type FC, type PropsWithChildren, useEffect } from 'react';

import { GridContainer, GridItem, Icon, Typography, styled } from '@cofenster/web-components';

import { useOrientation } from '../../../hooks/useOrientation';

const OrientationMismatchOverlay = styled('div')(({ theme }) => ({
  position: 'fixed',
  inset: 0,
  zIndex: theme.zIndex.top,
  backgroundColor: theme.palette.brand.carbon,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.brand.white,
}));

export const ORIENTATION_REQUIREMENTS = {
  Horizontal: 'landscape',
  Vertical: 'portrait',
  SocialMedia: 'portrait',
  Square: null,
} as const;

export const OrientationRestriction: FC<
  PropsWithChildren<{
    needs: 'landscape' | 'portrait' | null;
    onOrientationMismatch?: () => unknown;
  }>
> = ({ children, needs, onOrientationMismatch }) => {
  const orientation = useOrientation();
  const isOrientationMismatch = needs && needs !== orientation;

  useEffect(() => {
    if (isOrientationMismatch && onOrientationMismatch) onOrientationMismatch();
  }, [isOrientationMismatch, onOrientationMismatch]);

  if (!needs || !isOrientationMismatch) return <>{children}</>;

  return (
    <OrientationMismatchOverlay>
      <GridContainer textAlign="center" padding={3} spacing={1}>
        <GridItem xs={12}>
          <Icon size="xxl" type="RotateMobileDeviceIcon" />
        </GridItem>
        <GridItem xs={12}>
          <Typography variant="h4" component="p">
            i18n.OrientationRestriction.title
          </Typography>
        </GridItem>

        <GridItem xs={12}>
          <Typography variant="l" component="p">
            {needs === 'portrait'
              ? 'i18n.OrientationRestriction.descriptionVertical'
              : 'i18n.OrientationRestriction.descriptionHorizontal'}
          </Typography>
        </GridItem>
      </GridContainer>
    </OrientationMismatchOverlay>
  );
};
